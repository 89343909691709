import { Button, Input, Select, Spin } from 'antd';
import Form from '@component/Form';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { API, fileUpload } from '../../config';

const AddNewContactModal = ({
  setAddNewContact,
  addNewContact,
  transactionIndex,
  showNewIndexs,
  handleNewContactNameSelection,
  newContactItem
}) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [contactType, setContactType] = useState('client');

  const toggleShowModal = () => {
    setAddNewContact(false);
  };

  const handleSubmit = async (values) => {
    if (contactType === 'client') {
      try {
        setLoading(true);
        const formData = new FormData();
        const url = API.CREATE_CUSTOMER;
        const reqObj = values;
        Object.keys(reqObj).forEach((key) => {
          if (reqObj[key]) {
            formData.append([key], reqObj[key]);
          }
        });
        const { data } = await fileUpload(url, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });

        toast.success(data.message);
        setLoading(false);

        if (data?.status === 200) {
          toggleShowModal();
          handleNewContactNameSelection(
            data?.data?.customer,
            contactType,
            newContactItem,
            transactionIndex,
            showNewIndexs
          );
        }
      } catch (e) {
        setLoading(false);
        const errors = e.response?.data?.errors;
        Object.keys(errors).forEach((key) => {
          toast.error(errors[key][0]);
        });
      }
    } else {
      try {
        setLoading(true);
        const formData = new FormData();
        const url = API.SUPPLIERS;
        const reqObj = values;
        Object.keys(reqObj).forEach((key) => {
          if (reqObj[key]) {
            formData.append([key], reqObj[key]);
          }
        });
        const { data } = await fileUpload(url, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        toast.success(data.message);
        setLoading(false);
        if (data?.status === 200) {
          toggleShowModal();
          handleNewContactNameSelection(
            data?.data?.suppliers,
            contactType,
            newContactItem,
            transactionIndex,
            showNewIndexs
          );
        }
      } catch (e) {
        setLoading(false);
        const errors = e.response?.data?.errors;
        Object.keys(errors).forEach((key) => {
          toast.error(errors[key][0]);
        });
      }
    }
  };

  return (
    <Modal
      className="small-modal"
      show={addNewContact}
      onHide={toggleShowModal}
      dialogClassName="modal-50w add-new-contact-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>Add New Contact</Modal.Header>
      {loading && <Spin />}
      <Modal.Body>
        <div className="content-details">
          <Form
            form={form}
            name="officeForm"
            initialValues={
              contactType === 'client'
                ? { first_name: '', last_name: '', tfn: '', email: '' }
                : { name: '', email: '' }
            }
            layout="vertical"
            onFinish={handleSubmit}
          >
            <div className="input-boxes">
              <Form.Item className="full-width" label="Select Contact">
                <Select
                  showSearch
                  onChange={(id) => {
                    setContactType(id);
                  }}
                  value={contactType}
                >
                  <Option key={1} value="client">
                    Client
                  </Option>
                  <Option key={2} value="supplier">
                    Supplier
                  </Option>
                </Select>
              </Form.Item>

              {contactType === 'client' && (
                <>
                  <Form.Item
                    className="full-width"
                    label="First Name"
                    name="first_name"
                    rules={[
                      {
                        max: 32,
                        message: 'Must be less than 32 character'
                      },
                      {
                        pattern: new RegExp(/^[a-zA-Z\s]*$/),
                        message: 'First name can contain letters & spaces.'
                      }
                    ]}
                  >
                    <Input placeholder="First Name" />
                  </Form.Item>
                  <Form.Item
                    className="full-width"
                    label="Last Name Or Trading Name"
                    name="last_name"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Last name'
                      },
                      {
                        max: 150,
                        message: 'Must be less than 150 character'
                      },
                      {
                        pattern: new RegExp(/^[^!@#^%$<>][a-zA-Z\s\d.,&()]*$/),
                        message:
                          `Last name can contain letters, numbers, spaces and some special characters like (& ( ) . , - ')`
                      }
                    ]}
                  >
                    <Input /*onChange={handleLastName}*/ placeholder="Last Name" />
                  </Form.Item>
                  <Form.Item
                    className="full-width"
                    label="TFN"
                    name="tfn"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter TFN'
                      },
                      {
                        pattern: new RegExp(/^([0-9]+$)([ .-]?)/),
                        message: 'Please enter valid TFN'
                      },
                      {
                        min: 8,
                        message: 'Minimum 8 digits are required in TFN'
                      },
                      {
                        max: 9,
                        message: 'Maximum 9 digits are allowed in TFN'
                      },
                      {
                        validator: (_, value) => {
                          if (/^\d{8}?$/.test(value) && /(\d)\1{7}/.test(value)) {
                            return Promise.reject(
                              'Please enter valid TFN Number. All digits of same number are not allowed.'
                            );
                          } else {
                            if (/^\d{9}?$/.test(value) && /(\d)\1{8}/.test(value)) {
                              return Promise.reject(
                                'Please enter valid TFN Number. All digits of same number are not allowed.'
                              );
                            }
                            return Promise.resolve();
                          }
                        }
                      }
                    ]}
                  >
                    <Input placeholder="123412345" value="" />
                  </Form.Item>
                  <Form.Item
                    className="full-width"
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Email'
                      },
                      {
                        type: 'email',
                        message: 'Please enter valid Email'
                      }
                    ]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                </>
              )}
              {contactType === 'supplier' && (
                <>
                  <Form.Item
                    className="full-width"
                    label="Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Supplier Name'
                      },
                      {
                        pattern: new RegExp(/^[^!@#^%$<>][a-zA-Z\s\d./,&-()]*$/),
                        message: `Name can contain letters, numbers, spaces and some special characters like (& ( ) . , - ')`
                      }
                    ]}
                  >
                    <Input placeholder="Name" name="name" />
                  </Form.Item>
                  <Form.Item
                    className="full-width"
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Supplier Email'
                      },
                      {
                        type: 'email',
                        message: 'The input is not valid E-mail!'
                      }
                    ]}
                  >
                    <Input placeholder="Email" name="email" />
                  </Form.Item>
                </>
              )}
            </div>
            <div className="action-box">
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </div>
          </Form>
        </div>
        <Button
          variant="secondary"
          className="reset add-new-contact-cancel"
          onClick={toggleShowModal}
        >
          Cancel
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewContactModal;
