import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { RemoveCookie, SetCookie } from '../APIConfig/Interceptor';

const useIdleTimeout = ({ onIdle, idleTime = 1 }) => {
  const idleTimeout = 1000 * 60 * idleTime;
  const [isIdle, setIdle] = useState(false);

  const handleIdle = () => {
    setIdle(true);
    localStorage.clear();
    SetCookie('_subscription', '');
    RemoveCookie('_subscription');
  };

  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    promptTimeout: idleTimeout / 2,
    crossTab: true,
    onPrompt: onIdle,
    onIdle: handleIdle,
    debounce: 5000,
    syncTimers: 200
  });
  return {
    isIdle,
    setIdle,
    idleTimer
  };
};

export default useIdleTimeout;
