import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Table, Select } from 'antd';
import SidebarNav from '../../components/Header/SidebarNav';
import Footer from '../../components/Footer/Index';
import BackButton from '../../components/Form/BackButton';
import activityList from './activityList';
import moment from 'moment';
import '../../App.css';
import '../../assets/css/style.css';
import './users.module.css';
import { PaginationItemRenderer } from '../../shared/PaginationItemRenderer';
import DialogModal from '../../components/Header/DialogModal';
import { helpCenterArray } from '../HelpCenter';

import { useState } from 'react';
import { defaultPaginationLimit } from '../../config';

export default function ActivityLogs() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const currentPage = query.get('page');
  const [searchUser, setsearchUser] = useState(query.get('user'));
  const [limit, setLimit] = useState(
    query.get('limit') ? query.get('limit') : defaultPaginationLimit
  );
  const {
    users,
    data,
    fetchUsers,
    isDataLoading,
    pagingData,
    onPageChange
  } = activityList(currentPage, searchUser, limit);

  const getTableColumns = () => {
    return [
      {
        title: 'Date',
        width: '10%',
        sorter: (a, b) => a.created_at.localeCompare(b.created_at),
        render: (obj) => {
          return obj?.created_at
            ? moment(obj.created_at, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY HH:mm:ss')
            : null;
        }
      },
      {
        title: 'Name',
        render: (obj) => {
          return obj?.staff?.first_name + ' ' + obj?.staff?.last_name;
        },
        width: '10%',
        sorter: (a, b) => {
          a.staff.first_name.localeCompare(b.staff.first_name);
        }
      },
      {
        title: 'Email',
        render: (obj) => {
          return obj?.staff?.email;
        },

        width: '10%',
        sorter: (a, b) => a.email - b.email
      },
      {
        title: 'Activity',
        width: '10%',
        render: (obj) => {
          return <span dangerouslySetInnerHTML={{ __html: obj.activity }}></span>;
        },
        sorter: (a, b) => a?.activity.localeCompare(b?.activity)
      }
    ];
  };

  const onChangeUser = (id) => {
    setsearchUser(id);
    fetchUsers(id, 1, limit);
    navigate(`/activity-logs?${id ? `user=${id}&` : ''}limit=${limit}&page=1`, {
      replace: true
    });
  };

  const handleChange = async (value) => {
    setLimit(value);
    navigate(
      `/activity-logs?${searchUser ? 'user=' + searchUser + '&' : ''}limit=${value}&page=1`,
      {
        replace: true
      }
    );
    fetchUsers(searchUser, 1, value);
  };

  const onClear = () => {
    navigate(`/activity-logs?limit=${limit}&page=1`, {
      replace: true
    });
  };

  const onSearch = () => {};

  return (
    <>
      <div className="cms-page">
        <div className="page-content-block">
          <SidebarNav />
          <div className="full-content-block">
            <h1 className="page-title">
              <BackButton />
              User Activity Logs{' '}
              <DialogModal
                title="Dashboard"
                p1={helpCenterArray[36].name}
                p2={helpCenterArray[36].text}
              />
            </h1>
            <h2>User Activity Logs</h2>
            <div className="content-details">
              <div className="table-top-btn">
                <div className="search-section">
                  <Select
                    onClear={onClear}
                    allowClear={true}
                    showSearch={true}
                    optionFilterProp="children"
                    onSearch={onSearch}
                    onChange={onChangeUser}
                    placeholder="Please select staff"
                    defaultValue={searchUser ? Number(searchUser) : 'Please select staff'}
                  >
                    {data.map((user) => (
                      <Select.Option key={user.id} value={user.id}>
                        {user.first_name} {user.last_name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="" style={{ width: '100%', height: '85%' }}>
                <Table
                  rowKey={(record) => record.id}
                  columns={getTableColumns()}
                  dataSource={users}
                  loading={isDataLoading}
                  pagination={{
                    hideOnSinglePage: true,
                    defaultPageSize: pagingData.defaultPageSize,
                    defaultCurrent: 1,
                    showQuickJumper: true,
                    onChange: (page) => onPageChange(page, searchUser, limit),
                    itemRender: PaginationItemRenderer,
                    ...pagingData,
                    pageSize: limit
                  }}
                />
                {users?.length > 0 && (
                  <div className="col-md-12">
                    Showing&nbsp;
                    <Select
                      defaultValue={`${defaultPaginationLimit}`}
                      value={query.get('limit') ? query.get('limit') : `${defaultPaginationLimit}`}
                      onChange={handleChange}
                      options={[
                        { value: '10', label: '10' },
                        { value: '25', label: '25' },
                        { value: '50', label: '50' },
                        { value: '100', label: '100' }
                      ]}
                    />
                    &nbsp;Records per page I Showing {pagingData?.from} to&nbsp;
                    {pagingData?.to} from {pagingData?.total} Records
                  </div>
                )}
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}
