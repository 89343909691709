import React, { useMemo, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Select } from 'antd';
import '../../App.css';
import '../../assets/css/style.css';
import './ato.module.css';
import { Button, Modal, DropdownButton, Dropdown } from 'react-bootstrap';

import SidebarNav from '../../components/Header/SidebarNav';
import Footer from '../../components/Footer/Index';
import BackButton from '../../components/Form/BackButton';
import { API, defaultPaginationLimit, fileUpload, get } from '../../config';
import AddATORefund from './AddATORefund';
import { ATOTableInnerColumns, tableColumns } from './ATOHelper';
import useATORefundList from './useATORefundList';
import UserDetailPopup from '../users/UserDetailPopup';
import ATORefundUploadFile from './ATORefundUploadFile';
import TableComponent from './TableComponent';
import DialogModal from '../../components/Header/DialogModal';
import { helpCenterArray } from '../HelpCenter';
import { useLocation, useNavigate } from 'react-router-dom';
import { PaginationItemRenderer } from '../../shared/PaginationItemRenderer';

const ATORefund = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const currentPage = query.get('page');
  const { atoRefundList, fetchATORefundList, isDataLoading, pagingData, onPageChange } =
    useATORefundList(currentPage);
  const [showImport, setShowImport] = useState(false);
  const [limit, setLimit] = useState(
    query.get('limit') ? query.get('limit') : defaultPaginationLimit
  );
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showView, setShowView] = useState(false);
  const [customerID, setCustomerID] = useState('');

  const handleImportClose = () => {
    setShowImport(false);
  };

  const handleDownload = () => {
    setIsLoadingDownload(true);
    get(API.DOWNLOAD_TEMP_ATOS)
      .then((result) => {
        if (result.status === 200) {
          const { data } = result.data;
          var a = document.createElement('a');
          a.href = data.file;
          a.download = 'atos.xls';
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove();
        }
        setIsLoadingDownload(false);
      })
      .catch((error) => {
        const { data } = error.response;
        setIsLoadingDownload(false);
        toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      });
  };

  const toggleShowModal = useCallback(
    (e, staffId) => {
      e.preventDefault();
      setCustomerID(staffId);
      if (showView) {
        setCustomerID(staffId);
      }
      setShowView(!showView);
    },
    [showView]
  );

  const handleClose = () => setShow(false);
  const mainTableColumns = useMemo(() => {
    return tableColumns(toggleShowModal, fetchATORefundList, currentPage);
  }, [toggleShowModal]);
  const innerTableColumns = useMemo(() => {
    return ATOTableInnerColumns(fetchATORefundList);
  }, []);

  const handleChange = async (value) => {
    setLimit(value);
    navigate(`/ato-refund?page=1&limit=${value}`, {
      replace: true
    });
    fetchATORefundList(1, value);
  };

  const handleSync = () => {
    setLoading(true);
    fileUpload(API.ATO_SYNC)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        const { data } = error.response;
        setLoading(false);
        toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      });
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <SidebarNav />
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton /> Refund as per the ATO EFT Reconciliation Report{' '}
            <DialogModal
              title="Dashboard"
              p1={helpCenterArray[8].name}
              p2={helpCenterArray[8].text}
              p3={helpCenterArray[9].name}
              p4={helpCenterArray[9].text}
            />
          </h1>
          <div className="content-details">
            <div className="table-top-btn">
              <div className="table-btn">
                <Button
                  variant="primary"
                  onClick={() => {
                    handleSync();
                  }}
                  disabled={loading}
                >
                  {loading ? 'Loading...' : 'Client Sync'}
                </Button>
                <Button variant="primary" onClick={() => setShow(true)}>
                  Add Manually
                </Button>
                <div className="border-btn">
                  <DropdownButton className="info-icon">
                    <Dropdown.Item>
                      <p>Tax Payer Name - Required</p>
                      <p>Surname - Required</p>
                      <p>CT - Optional</p>
                      <p>TFN - Required</p>
                      <p>Refund - Required</p>
                      <p>Lodgment Reference - Required, Max: 25 Characters, Unique</p>
                    </Dropdown.Item>
                  </DropdownButton>
                  <Button
                    variant="link"
                    disabled={isLoadingDownload}
                    onClick={() => handleDownload()}
                    className="download-template"
                  >
                    Download Template
                  </Button>
                  <Button variant="primary" onClick={() => setShowImport(true)}>
                    Import
                  </Button>
                </div>
              </div>
            </div>
            <div className="ato-refund ato-refund-react-table">
              <TableComponent
                columns={mainTableColumns}
                dataSource={atoRefundList}
                nestedTableColumns={innerTableColumns}
                nestedRowEndpoint={API.ATOS_RECORDS}
                isNested={true}
                loading={isDataLoading}
                onPageChange={(page) => onPageChange(page, limit)}
                pagingData={{
                  defaultPageSize: pagingData.pageSize,
                  defaultCurrent: 1,
                  showQuickJumper: true,
                  itemRender: PaginationItemRenderer,
                  onChange: (page) => onPageChange(page, limit),
                  ...pagingData,
                  pageSize: limit
                }}
                limit={limit}
              />
              <div>
                {atoRefundList?.length > 0 && (
                  <div className="col-md-12">
                    Showing&nbsp;
                    <Select
                      defaultValue={`${defaultPaginationLimit}`}
                      value={query.get('limit') ? query.get('limit') : `${defaultPaginationLimit}`}
                      onChange={handleChange}
                      options={[
                        { value: '10', label: '10' },
                        { value: '25', label: '25' },
                        { value: '50', label: '50' },
                        { value: '100', label: '100' }
                      ]}
                    />
                    &nbsp;Records per page I Showing {pagingData?.from} to&nbsp;
                    {pagingData?.to} from {pagingData?.total} Records
                  </div>
                )}
              </div>
            </div>
          </div>
          <Footer />
          <Modal
            show={show}
            onHide={handleClose}
            dialogClassName="modal-50w"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>ATO Refund</Modal.Header>
            <Modal.Body>
              <AddATORefund
                setShow={setShow}
                isDataLoading={isDataLoading}
                fetchATORefundList={fetchATORefundList}
                onClose={handleClose}
              />
              <Button variant="secondary" className="reset" onClick={handleClose}>
                Cancel
              </Button>
            </Modal.Body>
          </Modal>
          {showImport && (
            <ATORefundUploadFile
              handleImportClose={handleImportClose}
              fetchATORefundList={fetchATORefundList}
            ></ATORefundUploadFile>
          )}
          {showView && <UserDetailPopup userId={customerID} toggleShowModal={toggleShowModal} />}
        </div>
      </div>
    </div>
  );
};

export default ATORefund;
