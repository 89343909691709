import React, { useEffect, useCallback, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Table, Spin, Select } from 'antd';
import { debounce } from 'lodash';
import { Button, Form, FormControl, Modal } from 'react-bootstrap';
import SidebarNav from '../../components/Header/SidebarNav';
import Footer from '../../components/Footer/Index';
import BackButton from '../../components/Form/BackButton';
import { API, defaultPaginationLimit, fileUpload, get } from '../../config';
import '../../App.css';
import '../../assets/css/style.css';
import { PaginationItemRenderer } from '../../shared/PaginationItemRenderer';
import { deletedReportTableColumns } from './reportHelper';
import CustomerDetailPopup from '../customer/CustomerDetailPopup';
import faqicon from '../../assets/images/bulb-icon.jpg';
import ClientRestore from './ClientRestore';
import { toast } from 'react-toastify';

const DeletedClientReport = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const search = query.get('search');
  const [deletedReportData, setDeletedtReportData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const currentPage = query.get('page') ? parseInt(query.get('page')) : 1;
  const [limit, setLimit] = useState(
    query.get('limit') ? query.get('limit') : defaultPaginationLimit
  );
  const [customerId, setCustomerId] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [searchParam, setSearchParam] = useState(search || '');
  const [pagingData, setPagingData] = useState({ current: currentPage || 1 });

  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const [restoreClientId, setRestoreClientId] = useState();

  const [deletedItem, setDeletedItem] = useState('');
  const [showDelete, setShowDelete] = useState(false);
  const [showDeleteAll, setShowDeleteAll] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);

  const toggleShowModal = (record) => {
    setShowPreview(!showPreview);
    setCustomerId(record.id);
  };

  const handleUserDelete = async () => {
    if (deletedItem) {
      setIsDeleteLoading(true);
      const formData = new FormData();
      formData.append(`ids[0]`, deletedItem);
      await fileUpload(`${API.PERMANETALY_DELETE_ARCHIVED_CUSTOMER}`, formData)
        .then((response) => {
          setIsDeleteLoading(false);
          toast.success(response?.data?.message);
          if (response?.data?.errors?.myna_error) {
            toast.error(response?.data?.errors?.myna_error[0]);
          }
          if (response?.status === 200) {
            getDeletedReportData();
            setTimeout(() => {
              setDeletedItem('');
              setShowDelete(!showDelete);
            }, 700);
          }
        })
        .catch((e) => {
          setIsDeleteLoading(false);
          const { data } = e.response;
          toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
          toast.error(e?.data?.message);
        });
    }
  };

  const getDeletedReportData = useCallback(
    async (limit) => {
      try {
        setIsLoading(true);
        let tempLimit = defaultPaginationLimit;
        if (limit) {
          tempLimit = limit;
        } else {
          tempLimit = query.get('limit') ? query.get('limit') : defaultPaginationLimit;
        }
        const { data } = await get(
          `${API.DELETED_REPORT}?page=${pagingData.current}&search=${searchParam}&limit=${tempLimit}`
        );
        setIsLoading(false);
        const { total, current_page, per_page, from, to } = data.data;
        setDeletedtReportData(data.data);
        setPagingData({
          total,
          current: current_page,
          defaultPageSize: per_page,
          from: from,
          to: to
        });
      } catch (e) {
        setDeletedtReportData({});
        setIsLoading(false);
        setPagingData({ current: currentPage || 1 });
      }
    },
    [pagingData.current, searchParam]
  );

  const toggleRestoreModal = (record) => {
    setRestoreClientId(record?.id);
    setShowRestoreModal(!showRestoreModal);
  };

  const handleRestore = () => {
    getDeletedReportData();
  };

  useEffect(() => {
    getDeletedReportData();
  }, [getDeletedReportData]);

  const onPageChange = (page) => {
    setPagingData({ ...pagingData, current: page });
    navigate(`/deleted-client?page=${page}&limit=${limit}`, {
      replace: true
    });
  };

  const onSearchChange = (e) => {
    const value = e.target.value;
    setSearchParam(value);
    setPagingData({ ...pagingData, current: 1 });
    navigate(`/deleted-client?page=1&search=${value}`, {
      replace: true
    });
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRow(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys: selectedRow,
    onChange: onSelectChange,
    getCheckboxProps: (record) => {
      return {
        disabled: record.is_used === 1 // Column configuration not to be checked
      };
    }
  };

  const toggleDeleteAllModal = () => {
    setShowDeleteAll(!showDeleteAll);
  };

  const handleDeleteAll = async () => {
    const formData = new FormData();
    if (selectedRow?.length > 0) {
      for (let i = 0; i < selectedRow?.length; i++) {
        formData.append(`ids[${i}]`, selectedRow[i]);
      }
    }
    try {
      setIsDeleteLoading(true);
      const { data } = await fileUpload(`${API.PERMANETALY_DELETE_ARCHIVED_CUSTOMER}`, formData);
      setIsDeleteLoading(false);
      if (data) {
        setSelectedRow([]);
        toast.success(data?.message);
        toggleDeleteAllModal();
        getDeletedReportData();
      }
    } catch (error) {
      setIsDeleteLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setIsDeleteLoading(false);
      } else {
        toast.error(error?.message);
        setIsDeleteLoading(false);
      }
    }
  };

  const toggleDeleteModal = (id) => {
    setShowDelete(!showDelete);
    setDeletedItem(id);
  };

  const handleChange = async (value) => {
    setLimit(value);
    navigate(`/deleted-client?page=1&limit=${value}`, {
      replace: true
    });
    getDeletedReportData(value);
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <SidebarNav />
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            Archived Clients{' '}
            <Link to={{ pathname: '/help' }}>
              <img src={faqicon} />
            </Link>
          </h1>
          <div className="content-details archived-client">
            <Spin spinning={isLoading}>
              <div className="table-top-btn">
                <div className="search-section">
                  <Form>
                    <FormControl
                      onChange={(e) => debounce(onSearchChange(e), 300)}
                      type="text"
                      value={searchParam}
                      placeholder="Search Clients"
                    />
                    <Button className="fa fa-search">Search</Button>
                  </Form>
                </div>
                <Button
                  variant="primary"
                  onClick={() => {
                    toggleDeleteAllModal();
                  }}
                  className="archived-client-delete-btn"
                  disabled={selectedRow?.length === 0 ? true : false}
                >
                  Delete Selected
                </Button>
              </div>
              <Table
                className="deleted-clients"
                rowSelection={rowSelection}
                columns={deletedReportTableColumns(
                  toggleShowModal,
                  toggleRestoreModal,
                  toggleDeleteModal
                )}
                dataSource={deletedReportData?.data || []}
                pagination={{
                  hideOnSinglePage: true,
                  defaultPageSize: pagingData.pageSize,
                  defaultCurrent: 1,
                  showQuickJumper: true,
                  onChange: (page) => onPageChange(page),
                  itemRender: PaginationItemRenderer,
                  ...pagingData,
                  pageSize: limit
                }}
              />
              {deletedReportData?.data?.length > 0 && (
                <div className="mt-2">
                  <div className="col-md-12">
                    Showing&nbsp;
                    <Select
                      defaultValue={`${defaultPaginationLimit}`}
                      value={limit}
                      onChange={handleChange}
                      options={[
                        { value: '10', label: '10' },
                        { value: '25', label: '25' },
                        { value: '50', label: '50' },
                        { value: '100', label: '100' }
                      ]}
                    />
                    &nbsp;Records per page I Showing {pagingData?.from} to&nbsp;
                    {pagingData?.to} from {pagingData?.total} Records
                  </div>
                </div>
              )}
            </Spin>
          </div>
          <Footer />
          {showPreview && (
            <CustomerDetailPopup
              customerId={customerId}
              isDeletedReportPage={true}
              toggleShowModal={toggleShowModal}
            />
          )}
          {showRestoreModal && (
            <ClientRestore
              clientId={restoreClientId}
              toggleRestoreModal={toggleRestoreModal}
              handleRestore={handleRestore}
            />
          )}
          {showDeleteAll && (
            <Modal
              className="small-modal remove-popup"
              show={showDeleteAll}
              onHide={toggleDeleteAllModal}
              dialogClassName="modal-50w"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton={toggleDeleteAllModal}></Modal.Header>
              <Modal.Body>
                <div className="remove-content spin-body-relative">
                  <p>
                    Are you sure you want to delete {selectedRow?.length === 0 ? 'all' : 'selected'}{' '}
                    client permanently?
                  </p>
                  <div className="modal-action">
                    <Button className="cancel" onClick={toggleDeleteAllModal}>
                      Cancel
                    </Button>
                    <Button onClick={handleDeleteAll}>OK</Button>
                  </div>
                  {isDeleteLoading && (
                    <div className="top-modal-spin-center">
                      <Spin />
                    </div>
                  )}
                </div>
              </Modal.Body>
            </Modal>
          )}
          {
            <Modal
              show={showDelete && deletedItem}
              onHide={() => toggleDeleteModal('')}
              className="small-modal remove-popup"
              dialogClassName="modal-50w"
              arial-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton={toggleDeleteAllModal}></Modal.Header>
              <Modal.Body>
                <div className="remove-content spin-body-relative" style={{ textAlign: 'center' }}>
                  <p>Are your sure you want to delete this client permanently?</p>
                  <div className="modal-action">
                    <Button onClick={() => toggleDeleteModal('')}>Close</Button>
                    <Button onClick={handleUserDelete}>Delete</Button>
                  </div>
                  {isDeleteLoading && (
                    <div className="top-modal-spin-center">
                      <Spin />
                    </div>
                  )}
                </div>
              </Modal.Body>
            </Modal>
          }
        </div>
      </div>
    </div>
  );
};
export default DeletedClientReport;
