import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { API, defaultPaginationLimit, get } from '../../config';

const useOpeningBalanceList = (currentPage, recursion = true) => {
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const [openingBalanceList, setOpeningBalanceList] = useState([]);
  const [totalOpeningBalanceData, setTotalOpeningBalanceData] = useState();
  const [isOpeningBalanceLoading, setIsOpeningBalanceLoading] = useState(false);
  const [pagingData, setPagingData] = useState({ current: currentPage || 1 });

  const fetchOpeningBalance = useCallback(
    async (pageNo, limit) => {
      try {
        setIsOpeningBalanceLoading(true);
        let tempLimit = defaultPaginationLimit;
        if (limit) {
          tempLimit = limit;
        } else {
          tempLimit = query.get('limit') ? query.get('limit') : defaultPaginationLimit;
        }
        const { data } = await get(
          `${API.OPENING_BALANCE}?page=${pageNo || pagingData.current}&limit=${tempLimit}`
        );
        setIsOpeningBalanceLoading(false);

        const { total, current_page, per_page, from, to } = data.data.customers;
        const openingBalanceList = data.data.customers.data;
        setOpeningBalanceList(openingBalanceList);
        setTotalOpeningBalanceData(data.data);
        setPagingData({ total, current: current_page, pageSize: per_page, from: from, to: to });
        return data.data;
      } catch (e) {
        const { data } = e;
        setIsOpeningBalanceLoading(false);
        setPagingData({ current: 0 });
        setOpeningBalanceList([]);
        toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      }
    },
    [pagingData.current]
  );

  useEffect(() => {
    if (recursion) {
      fetchOpeningBalance();
    }
  }, [fetchOpeningBalance]);

  return {
    fetchOpeningBalance,
    openingBalanceList,
    isOpeningBalanceLoading,
    pagingData,
    setIsOpeningBalanceLoading,
    setPagingData,
    totalOpeningBalanceData,
    onPageChange: (page, limit) => {
      setPagingData({ ...pagingData, current: page });
      navigate(`${API.OPENING_BALANCE}?page=${page}&limit=${limit}`, {
        replace: true
      });
    }
  };
};

export default useOpeningBalanceList;
