import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Input, Select } from 'antd';
import Footer from '../../components/Footer/Index';
import {
  abaTransactionTableColumns,
  getAbaTransactionTableInnerColumns,
  getAbaTransactionTableInnerColumns1
} from './ABAHelper';
import useABATransactionList from './useABATransactionList';
import AbaTransactionPopup from './AbaTransactionPopup';
import { API, defaultPaginationLimit, fileUpload } from '../../config';
import '../../App.css';
import '../../assets/css/style.css';
import ABATableComponent from './ABATableComponent';
import { toast } from 'react-toastify';

const ABATransactions = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const currentPage = query.get('page') ? query.get('page') : 1;
  const selectedTab = query.get('tab');
  const search = query.get('search');
  const [limit, setLimit] = useState(
    query.get('limit') ? query.get('limit') : defaultPaginationLimit
  );
  const [preview, setPreview] = useState(null);
  const [showPreview, setShowPreview] = useState(false);

  const {
    ABATransactions,
    isDataLoading,
    onPageChange,
    pagingData,
    onChange,
    searchParam,
    fetchABATransactions,
    setIsDataLoading,
    handleSortChange
  } = useABATransactionList(currentPage, search, limit);

  useEffect(() => {
    setLimit(query.get('limit') ?? defaultPaginationLimit);
  }, [query.get('limit')]);

  useEffect(() => {
    if (selectedTab === 'aba-transactions') fetchABATransactions(limit ?? query.get('limit'));
  }, [selectedTab, fetchABATransactions]);

  const handleChange = async (value) => {
    setLimit(value);
    navigate(`/aba?page=1&tab=aba-transactions&limit=${value}`, {
      replace: true
    });
    fetchABATransactions(value, 1);
  };

  const handleRefreshButton = () => {
    fetchABATransactions(limit, currentPage);
  };

  const handleUploadedStatusABA = async (id, uploaded = 0) => {
    try {
      setIsDataLoading(true);
      const formData = new FormData();
      formData.append('uploaded', uploaded == 0 ? 1 : 0);
      formData.append('_method', 'PUT');
      const { data } = await fileUpload(`${API.ABA_FILE_LIST}/${id}`, formData);
      if (data) {
        toast.success(data.message);
      }
      setIsDataLoading(false);
      fetchABATransactions(limit, currentPage);
    } catch (e) {
      setIsDataLoading(false);
      const errors = e?.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  return (
    <div className="cms-page aba-transaction-page">
      <div className="table-top-btn">
        <div className="search-section">
          <Input placeholder="Search" onChange={onChange} value={searchParam} />
        </div>
      </div>
      <div className="aba-transaction-table">
        <ABATableComponent
          columns={abaTransactionTableColumns(handleRefreshButton, handleUploadedStatusABA)}
          dataSource={ABATransactions}
          nestedTableColumns={getAbaTransactionTableInnerColumns(setShowPreview, setPreview)}
          nestedTableColumns1={getAbaTransactionTableInnerColumns1()}
          nestedRowEndpoint={API.ABA_TRANSACTION_FILES}
          isNested={true}
          onPageChange={(page) => onPageChange(page, limit)}
          pagingData={pagingData}
          setPreview={setPreview}
          setShowPreview={setShowPreview}
          limit={limit}
          loading={isDataLoading}
          handleSortChange={handleSortChange}
        />
        {ABATransactions?.length > 0 && (
          <div className="mt-2">
            <div className="col-md-12">
              Showing&nbsp;
              <Select
                defaultValue={`${defaultPaginationLimit}`}
                value={limit}
                onChange={handleChange}
                options={[
                  { value: '10', label: '10' },
                  { value: '25', label: '25' },
                  { value: '50', label: '50' },
                  { value: '100', label: '100' }
                ]}
              />
              &nbsp;Records per page I Showing {pagingData?.from} to&nbsp;
              {pagingData?.to} from {pagingData?.total} Records
            </div>
          </div>
        )}
      </div>
      <Footer />
      {showPreview && (
        <AbaTransactionPopup
          preview={preview}
          setShowPreview={setShowPreview}
          showPreview={showPreview}
        />
      )}
    </div>
  );
};

export default ABATransactions;
