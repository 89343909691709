/* eslint-disable react/no-is-mounted */
import React from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { defaultPaginationLimit } from '../../config';

function padWithLeadingZeros(num, totalLength) {
  return String(num).padStart(totalLength, '0');
}
class HeaderNav extends React.Component {
  getMonthlyRecouncilationRedirectUrl = () => {
    const date = moment(Date.now()).format('MM-YYYY');
    return moment(date, 'MM-YYYY').toDate() >= moment().startOf('month').toDate() &&
      moment().format('DD-MM-YYYY') > moment().endOf('month').format('DD-MM-YYYY')
      ? moment().subtract(1, 'month').format('MM-YYYY')
      : moment(date, 'MM-YYYY').format('MM-YYYY');
  };

  getTransactionRedirectURL = (path) => {
    const previousYear =
      new Date().getMonth() - 1 >= 4 ? new Date().getFullYear() : new Date().getFullYear() - 1;
    const previousYearDate = `01-04-${previousYear}`;
    const currentTime = new Date();
    const month = currentTime.getMonth() + 1;
    const day = currentTime.getDate();
    const year = currentTime.getFullYear();
    const currentDate = `${padWithLeadingZeros(Number(day), 2)}-${padWithLeadingZeros(
      Number(month),
      2
    )}-${year}`;
    return `/${path}?page=1&from=${previousYearDate}&to=${currentDate}`;
  };

  getReportRedirectURL = (path) => {
    const currentTime = new Date();
    const month = currentTime.getMonth() + 1;
    const year = currentTime.getFullYear();
    const previousYearDate = `01-${year}`;
    const currentDate = `${month}-${year}`;
    return `/${path}?page=1&from=${previousYearDate}&to=${currentDate}`;
  };

  render() {
    return (
      <ul className="header-nav">
        <li className={location.pathname.includes('/dashboard') ? 'active' : ''}>
          <Link
            to={{ pathname: '/dashboard', search: '?field=name&type=asc&filter=weekly&info=true' }}
          >
            Home
          </Link>
        </li>
        <li>
          <DropdownButton id="dropdown-basic-button" title="Clients">
            <Dropdown.Item
              href={'/customer'}
              className={location.pathname.includes('/customer') ? 'active' : ''}
            >
              Clients
            </Dropdown.Item>
            <Dropdown.Item
              href={'/deleted-client?page=1'}
              className={location.pathname.includes('/deleted-client') ? 'active' : ''}
            >
              Archived Clients
            </Dropdown.Item>

            <Dropdown.Item
              href={'/client-required-update?page=1'}
              className={location.pathname.includes('/client-required-update') ? 'active' : ''}
            >
              Update Client&apos;s TFN
            </Dropdown.Item>
            <Dropdown.Item
              href={'/duplicate-customer'}
              className={location.pathname.includes('/duplicate-customer') ? 'active' : ''}
            >
              Duplicate Clients
            </Dropdown.Item>
          </DropdownButton>
        </li>
        <li>
          <DropdownButton id="dropdown-basic-button" title="I want to">
            <Dropdown.Item
              href="/ato-refund?page=1"
              className={location.pathname.includes('/ato-refund') ? 'active' : ''}
            >
              Import EFT Reconciliation Report
            </Dropdown.Item>
            <Dropdown.Item
              href="/process-refund"
              className={location.pathname === '/process-refund' ? 'active' : ''}
            >
              Process refund in bulk
              {/* Process Reconciliation */}
            </Dropdown.Item>
            <Dropdown.Item
              href="/process-refund-individual"
              className={location.pathname.includes('/process-refund-individual') ? 'active' : ''}
            >
              {/* Process Refund one by one */}
              Process Refund
            </Dropdown.Item>
            <Dropdown.Item
              href={`/aba?page=1&tab=generate-aba&limit=${defaultPaginationLimit}`}
              className={location.pathname.includes('/aba') ? 'active' : ''}
            >
              {/* Process Refund one by one */}
              Generate .ABA
            </Dropdown.Item>
          </DropdownButton>
        </li>
        <li>
          <DropdownButton id="dropdown-basic-button" title="Reports">
            <Dropdown.Item
              href={this.getTransactionRedirectURL('clients-report')}
              className={location.pathname.includes('/clients-report') ? 'active' : ''}
            >
              Client&apos;s Statement/GL
            </Dropdown.Item>
            <Dropdown.Item
              href={this.getTransactionRedirectURL('customer-transaction')}
              className={location.pathname.includes('/customer-transaction') ? 'active' : ''}
            >
              Cashbook/Client&apos;s Transactions
            </Dropdown.Item>
            <Dropdown.Item
              href={'/trial-balance'}
              className={location.pathname.includes('/trial-balance') ? 'active' : ''}
            >
              Trial Balance
            </Dropdown.Item>
            <Dropdown.Item
              className={
                location.search.includes('tab=monthly-bank-reconciliation') ? 'active' : ''
              }
              href={`/firm-bank-account?page=1&tab=monthly-bank-reconciliation&month=${this.getMonthlyRecouncilationRedirectUrl()}`}
            >
              Monthly Bank Reconciliation
            </Dropdown.Item>
          </DropdownButton>
        </li>
        <li>
          <DropdownButton id="dropdown-basic-button" title="Banking">
            <Dropdown.Item
              href={'/firm-bank-account?page=1&tab=reconcile'}
              className={location.search.includes('tab=reconcile') ? 'active' : ''}
            >
              Firm Bank Account
            </Dropdown.Item>
            <Dropdown.Item
              href="/payments"
              className={location.pathname.includes('/payments') ? 'active' : ''}
            >
              Enter Trust Payments
            </Dropdown.Item>
            <Dropdown.Item
              href="/receipts"
              className={location.pathname.includes('/receipts') ? 'active' : ''}
            >
              Enter Trust Receipts
            </Dropdown.Item>
          </DropdownButton>
        </li>
        <li>
          <DropdownButton id="dropdown-basic-button" title="Settings">
            <Dropdown.Item
              href="/settings?tab=firm-settings"
              className={location.search.includes('tab=firm-settings') ? 'active' : ''}
            >
              General Setting
            </Dropdown.Item>
            <Dropdown.Item
              href="/template-setting?tab=authority-letter"
              className={location.search.includes('tab=authority-letter') ? 'active' : ''}
            >
              Template Setting
            </Dropdown.Item>
            <Dropdown.Item
              href="/user-console?page=1"
              className={location.pathname.includes('/user-console') ? 'active' : ''}
            >
              User Console
            </Dropdown.Item>
            <Dropdown.Item
              href="/activity-logs?page=1"
              className={location.pathname.includes('/activity-logs') ? 'active' : ''}
            >
              User Activity Logs
            </Dropdown.Item>
            <Dropdown.Item
              href={`/opening-balance?page=1&limit=${defaultPaginationLimit}`}
              className={location.pathname.includes('/opening-balance') ? 'active' : ''}
            >
              Enter Opening Balance
            </Dropdown.Item>
          </DropdownButton>
        </li>
        <li>
          <DropdownButton id="dropdown-basic-button" title="Help">
            <a
              href="https://trusteasy.com.au/terms-conditions/"
              target="_blank"
              rel="noreferrer"
              style={{ width: '100%', display: 'block' }}
            >
              Terms of Use
            </a>
            <a
              href="https://trusteasy.com.au/privacy-policy/"
              target="_blank"
              rel="noreferrer"
              style={{ width: '100%', display: 'block' }}
            >
              Privacy
            </a>
            <Dropdown.Item
              href="/help"
              className={location.pathname.includes('/help') ? 'active' : ''}
            >
              Help Center
            </Dropdown.Item>
            <Dropdown.Item
              href="/support-tickets"
              className={location.pathname.includes('/support-tickets') ? 'active' : ''}
            >
              Support Tickets
            </Dropdown.Item>
          </DropdownButton>
        </li>
      </ul>
    );
  }
}

export default HeaderNav;
