import React, { useMemo, useState } from 'react';
import { Button, InputNumber, Input, Select } from 'antd';
import Form from '@component/Form';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { API, post } from '../../config.js';
import useFirmInfo from './useFirmInfo.js';
import { getCustomerDetail } from '../customer/CustomersHelper.js';
import UserSelect from '../../components/User/UserSelect';

const minOffset = 0;
const maxOffset = 20;

const AddTaxRefund = (props) => {
  const [form] = Form.useForm();

  const { firmInfo } = useFirmInfo();

  const [hasBankDetail, setHasBankDetail] = useState(true);
  const [isLoadingCustomerDetail, setIsLoadingCustomerDetail] = useState(false);
  const selectedCustomer = form.getFieldValue('customer_id');
  // const thisYear = new Date().getFullYear();
  const thisYear =
    new Date().getMonth() >= 6 // Months are zero-indexed, so 6 is July
      ? new Date().getFullYear()
      : new Date().getFullYear() - 1;

  const yearOptions = useMemo(() => {
    const options = [];
    for (let i = minOffset; i <= maxOffset; i++) {
      const year = thisYear - i;
      if (i < 5) {
        let yearOther = year + ' Other';
        options.push(<Select.Option value={yearOther}>{yearOther}</Select.Option>);
        let yearB = year + 'B';
        options.push(<Select.Option value={yearB}>{yearB}</Select.Option>);
        let yearA = year + 'A';
        options.push(<Select.Option value={yearA}>{yearA}</Select.Option>);
      }
      options.push(<Select.Option value={year}>{year}</Select.Option>);
    }
    return options;
  }, [thisYear]);


  const handleFinish = async (values) => {
    try {
      if (!hasBankDetail) {
        toast.error('Selected customer does not have bank details');
        return;
      }
      const { data } = await post(API.MANUAL_TS_HISTORY, {
        ...values,
        customer_id:
          values?.customer_id ||
          ('taxSoftInfo' in props && props.taxSoftInfo?.customer_id) ||
          ('customerDetails' in props && props?.customerDetails?.customerId),
        recon_id: props.accountId,
        is_update: props.taxSoftInfo?.id ? 1 : 0
      });
      props.onClose();
      toast.success(data.message);
    } catch (e) {
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const onCustomerSelectionChange = async (value) => {
    setHasBankDetail(true);
    setIsLoadingCustomerDetail(true);
    const data = await getCustomerDetail(value);
    const {
      customer_bank: { account_name, account_number, bsb }
    } = data;
    const hasDetail = !!account_name && !!account_number && !!bsb;
    setHasBankDetail(hasDetail);
    setIsLoadingCustomerDetail(false);
  };


  if (!firmInfo) return null;
  let initialValues = {
    ...props.taxSoftInfo,
    name: props.taxSoftInfo?.customer_id,
    fee: props.taxSoftInfo?.invoice_amount,
    ...(props.taxSoftInfo && { disbursement: props.taxSoftInfo?.disbursement })
  };
  if (!('taxSoftInfo' in props) || !Object.keys(props?.taxSoftInfo).length) {
    initialValues = {
      ...initialValues,
      disbursement: firmInfo?.disbursement_fee
    };
  }

  const customerNameRender = () => {
    if ('taxSoftInfo' in props && Object.keys(props.taxSoftInfo).length) {
      if (props.taxSoftInfo?.first_name || props.taxSoftInfo?.last_name)
        return `${props.taxSoftInfo?.first_name ?? ''} ${props.taxSoftInfo?.last_name ?? ''}`;
    } else if ('customerDetails' in props) {
      return `${props.customerDetails?.customerFirstName} ${props.customerDetails?.customerLastName}`;
    }
  };
  return (
    <div className="content-details">
      <Form
        form={form}
        name="addTaxRefundnForm"
        initialValues={initialValues}
        layout="vertical"
        onFinish={handleFinish}
      >
        <div className="entry-card full-card">
          <div className="form-box">
            {props.taxSoftInfo?.id ||
            (props.customerDetails && Object.keys(props.customerDetails).length) ? (
              <Form.Item label="Name" className="half-width">
                <Input disabled placeholder={customerNameRender()} />
              </Form.Item>
            ) : (
              <div className="half-width">
                <UserSelect
                  onCustomerSelectionChange={onCustomerSelectionChange}
                  isLoadingCustomerDetail={isLoadingCustomerDetail}
                  formProps={{
                    label: 'Name',
                    name: 'customer_id',
                    rules: [
                      {
                        required: true,
                        message: 'Please select client'
                      }
                    ],
                    style: {
                      width: '100%'
                    }
                  }}
                />
                {props.accountId ? null : (
                  <Link to={{ pathname: '/new-customers' }} className="action">
                    Add Client
                  </Link>
                )}
                {!hasBankDetail && (
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p className="text-danger">Selected customer does not have bank detail</p>
                    <Link to={{ pathname: `/customer/edit`, search: `?id=${selectedCustomer}` }}>
                      Update bank detail
                    </Link>
                  </div>
                )}
              </div>
            )}
            <Form.Item
              label="Refund"
              name="refund"
              rules={[
                {
                  required: true,
                  message: 'Please enter Refund Amount'
                },
                {
                  pattern: new RegExp(/^((?:[0-9]+(?:\.[0-9]+)?|\.[0-9]+)(?:[eE][0-9]+)?)$/),
                  message: `Invalid Refund Amount.`
                }
              ]}
              className="half-width"
            >
              <InputNumber
                placeholder="Refund"
                disabled={
                  isLoadingCustomerDetail
                }
              />
            </Form.Item>
            <Form.Item
              label="Fee"
              name="fee"
              rules={[
                {
                  required: true,
                  message: 'Please enter Fee'
                },
                {
                  pattern: new RegExp(/^((?:[0-9]+(?:\.[0-9]+)?|\.[0-9]+)(?:[eE][0-9]+)?)$/),
                  message: `Invalid Fee.`
                }
              ]}
              className="half-width"
            >
              <InputNumber min={0} placeholder="Fee" disabled={isLoadingCustomerDetail} />
            </Form.Item>
            <Form.Item
              label="Disbursement"
              name="disbursement"
              rules={[
                {
                  required: true,
                  message: 'Please enter Disbursement'
                },
                {
                  pattern: new RegExp(/^((?:[0-9]+(?:\.[0-9]+)?|\.[0-9]+)(?:[eE][0-9]+)?)$/),
                  message: `Invalid Disbursement.`
                }
              ]}
              className="half-width"
            >
              <InputNumber min={0} placeholder="Disbursement" disabled={isLoadingCustomerDetail} />
            </Form.Item>
            <Form.Item
              label="Year"
              name="year"
              className="half-width"
              rules={[
                {
                  required: true,
                  message: 'Please select year'
                }
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                placeholder="Select Year"
                disabled={isLoadingCustomerDetail}
              >
                {yearOptions}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className="action-box">
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddTaxRefund;
