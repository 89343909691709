import React from 'react';
import moment from 'moment';
import { Tooltip } from 'antd';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { formatNumber } from '../../APIConfig/Interceptor';
import { Button } from 'react-bootstrap';

export const getTableColumns = (toggleDeleteModal) => {
  return [
    {
      title: 'Date',
      dataIndex: 'date',
      width: '10%',
      className: 'centeralign',
      render: (date) => <span>{moment(date).format('DD-MM-YYYY')}</span>
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: '45%'
    },
    {
      title: 'Debit',
      width: '12%',
      className: 'centeralign',
      dataIndex: 'amount',
      render: (amount, data) => {
        return (
          data.transaction_type === 0 && <span className="rag-red">${formatNumber(amount)}</span>
        );
      }
    },
    {
      title: 'Credit',
      width: '12%',
      className: 'centeralign',
      dataIndex: 'amount',
      render: (amount, data) => {
        return (
          data.transaction_type === 1 && <span className="rag-green">${formatNumber(amount)}</span>
        );
      }
    },
    {
      title: 'Bank Transaction Source',
      className: 'centeralign',
      dataIndex: 'source',
      width: '20%'
    },
    {
      title: 'Action',
      dataIndex: 'id',
      className: 'centeralign',
      width: '10%',
      render: (id, record) =>
        (record.deleted_at !== null && record.deleted_at !== '') ||
        (record.bank_reconciliation?.length > 0 &&
          record.bank_reconciliation?.[0]?.status !== '0') ? (
          ''
        ) : (
          <span>
            <FontAwesomeIcon onClick={() => toggleDeleteModal(id)} icon={faTrash} size="1x" />
          </span>
        )
    },
    {
      title: '',
      render: (record) => {
        return (
          <span role="img">
            {record.deleted_at !== null && record.deleted_at !== '' ? (
              <Tooltip title="Archived" color={'red'} key={'red'}>
                <FontAwesomeIcon icon={faBan} size="1x" />
              </Tooltip>
            ) : (
              ''
            )}
          </span>
        );
      }
    }
  ];
};

// eslint-disable-next-line no-unused-vars
export const getReconciledStatementTableColumns = (handleUndo, undoLoading) => {
  return [
    {
      title: 'Date',
      width: '10%',
      className: 'centeralign',
      render: (record) => <span>{moment(record.date).format('DD-MM-YYYY')}</span>
    },
    {
      title: 'Description',
      width: '36%',
      render: (record) => (
        <span>
          {record?.description ? record?.description : record?.bank_statement?.description}
        </span>
      )
    },
    {
      title: 'Debit',
      className: 'centeralign',
      width: '12%',
      render: (record) => {
        return (
          record?.transaction_type === 0 && (
            <span className="rag-red">${formatNumber(record?.amount)}</span>
          )
        );
      }
    },
    {
      title: 'Credit',
      className: 'centeralign',
      width: '12%',
      render: (record) => {
        return (
          record?.transaction_type === 1 && (
            <span className="rag-green">${formatNumber(record?.amount)}</span>
          )
        );
      }
    },

    {
      title: 'Bank Transaction Source',
      className: 'centeralign',
      width: '20%',
      render: (record) => {
        return <span>{record?.source ? record?.source : ''}</span>;
      }
    },

    {
      title: 'Action',
      width: '5%',
      className: 'centeralign',
      render: (record) => (
        <>
          <Button className={`fetch-fontawesome-icon-btn ${undoLoading ? "disabled" : ""}`} onClick={() => handleUndo(record?.transaction_id)} disabled={undoLoading}>
            <FontAwesomeIcon title="Undo" icon={faUndo} size="1x" />
          </Button>
        </>
      )
    }
  ];
};

export const getReconciledStatementInnerTableColumns = () => {
  return [
    {
      title: 'Date',
      className: 'centeralign',
      render: (record) => {
        return <span>{moment(record.date).format('DD-MM-YYYY')}</span>;
      }
    },
    {
      title: 'Description',
      className: 'centeralign',
      render: (record) => (
        <span>
          {record?.description ? record?.description : record?.bank_statement?.description}
        </span>
      )
    },
    {
      title: 'Client Name',
      className: 'centeralign',
      render: (record) => (
        <span>
          {record?.customer
            ? (record?.customer?.first_name ? record?.customer?.first_name : '') +
              ' ' +
              record?.customer?.last_name
            : null}
        </span>
      )
    },
    {
      title: 'Debit',
      className: 'centeralign',
      render: (record) => {
        return (
          record?.transaction_type === 0 && (
            <span className="rag-red">${formatNumber(record?.amount)}</span>
          )
        );
      }
    },
    {
      title: 'Credit',
      className: 'centeralign',
      render: (record) => {
        return (
          record?.transaction_type === 1 && (
            <span className="rag-green">${formatNumber(record?.amount)}</span>
          )
        );
      }
    },
    {
      title: 'Chart of Account',
      className: 'centeralign',
      width: '200px',
      render: (record) => {
        return (
          <span>
            {record?.chart_account?.name +
              (record?.chart_account?.account_type?.name
                ? ' (' + record?.chart_account?.account_type?.name + ')'
                : '')}
          </span>
        );
      }
    }
  ];
};

export const auditStatementTableColumns = (toggleShowModal) => {
  return [
    {
      title: 'Date',
      dataIndex: 'date'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (description, record) => {
        return (
          <span
            onClick={(e) => {
              if (record.staff_id) {
                toggleShowModal(e, record.staff_id);
              }
              return false;
            }}
            dangerouslySetInnerHTML={{ __html: description }}
          ></span>
        );
      }
    },
    {
      title: 'Number Of Records',
      className: 'centeralign',
      dataIndex: 'total_records'
    }
  ];
};

export const auditDeleteTransactionTableColumns = (toggleDeleteModal) => {
  return [
    {
      title: 'Date',
      dataIndex: 'date',
      className: 'centeralign',
      render: (date) => <span>{moment(date).format('DD-MM-YYYY')}</span>
    },
    {
      title: 'Description',
      dataIndex: 'description'
    },
    {
      title: 'Debit',
      className: 'centeralign',
      dataIndex: 'amount',
      render: (amount, data) => {
        return (
          data.transaction_type === 0 && <span className="rag-red">${formatNumber(amount)}</span>
        );
      }
    },
    {
      title: 'Credit',
      className: 'centeralign',
      dataIndex: 'amount',
      render: (amount, data) => {
        return (
          data.transaction_type === 1 && <span className="rag-green">${formatNumber(amount)}</span>
        );
      }
    },
    {
      title: 'Archived By',
      className: 'centeralign',
      key: 'deleted_by',
      render: (record) => {
        return (
          (record.staff?.first_name ? record.staff?.first_name : '') +
          ' ' +
          (record.staff?.last_name ? record.staff?.last_name : '')
        );
      }
    },
    {
      title: 'Bank Transaction Source',
      className: 'centeralign',
      key: 'source',
      dataIndex: 'source'
    },
    {
      title: 'Action',
      dataIndex: 'id',
      className: 'centeralign',
      width: '10%',
      render: (id) => (
        <span>
          <FontAwesomeIcon onClick={() => toggleDeleteModal(id)} icon={faTrash} size="1x" />
        </span>
      )
    }
  ];
};

export const AuditStatementInnerColumns = () => {
  return [
    {
      title: 'Date',
      dataIndex: 'date',
      render: (date) => <span>{moment(date).format('DD-MM-YYYY')}</span>
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (description) => {
        return <span dangerouslySetInnerHTML={{ __html: description }}></span>;
      }
    },
    {
      title: 'Debit',
      width: '15%',
      className: 'centeralign',
      dataIndex: 'amount',
      render: (amount, data) => {
        return (
          data.transaction_type === 0 && <span className="rag-red">${formatNumber(amount)}</span>
        );
      }
    },
    {
      title: 'Credit',
      width: '15%',
      className: 'centeralign',
      dataIndex: 'amount',
      render: (amount, data) => {
        return (
          data.transaction_type === 1 && <span className="rag-green">${formatNumber(amount)}</span>
        );
      }
    },
    {
      title: 'Bank Transaction Source',
      className: 'centeralign',
      dataIndex: 'source'
    },
    {
      title: '',
      render: (record) => {
        return (
          <span role="img">
            {record.deleted_at !== null && record.deleted_at !== '' ? (
              <Tooltip title="Archived" color={'red'} key={'red'}>
                <FontAwesomeIcon icon={faBan} size="1x" />
              </Tooltip>
            ) : (
              ''
            )}
          </span>
        );
      }
    }
  ];
};

export const AuditDeleteTransactionInnerColumns = () => {
  return [
    {
      title: 'Date',
      dataIndex: 'date',
      render: (date) => <span>{moment(date).format('DD-MM-YYYY')}</span>
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (description) => {
        return <span dangerouslySetInnerHTML={{ __html: description }}></span>;
      }
    },
    {
      title: 'Debit',
      width: '15%',
      className: 'centeralign',
      dataIndex: 'amount',
      render: (amount, data) => {
        return (
          data.transaction_type === 0 && <span className="rag-red">${formatNumber(amount)}</span>
        );
      }
    },
    {
      title: 'Credit',
      width: '15%',
      className: 'centeralign',
      dataIndex: 'amount',
      render: (amount, data) => {
        return (
          data.transaction_type === 1 && <span className="rag-green">${formatNumber(amount)}</span>
        );
      }
    },
    {
      title: '',
      render: (record) => {
        return (
          <span role="img">
            {record.deleted_at !== null && record.deleted_at !== '' ? (
              <Tooltip title="Archived" color={'red'} key={'red'}>
                <FontAwesomeIcon icon={faBan} size="1x" />
              </Tooltip>
            ) : (
              ''
            )}
          </span>
        );
      }
    }
  ];
};
