/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import { Select, Table } from 'antd';

import { PaginationItemRenderer } from '../../shared/PaginationItemRenderer';
import '../../App.css';
import '../../assets/css/style.css';
import { capitalizeFirstLetter, trimString } from '../../helper/fullNameGetter';
import { useLocation } from 'react-router-dom';
import { API, defaultPaginationLimit, get } from '../../config';
import numbro from 'numbro';
import { Button, Modal } from 'react-bootstrap';
import AddEditSupplier from '../general-setting/Supplier/AddEditSupplier';

const SupplierBasedOnClient = ({
  clientId,
  onClose,
  itemBlockIndex,
  defaultAccount,
  setBlockData,
  blockData,
  itemJson
}) => {
  const location = useLocation();
  const { state } = useLocation();
  const query = new URLSearchParams(location.search);
  const currentPage = query.get('page') ? parseInt(query.get('page')) : 1;
  const [limit, setLimit] = useState(
    query.get('limit') ? query.get('limit') : defaultPaginationLimit
  );
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [show, setShow] = useState(false);

  const [suppliers, setSuppliers] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [pagingData, setPagingData] = useState({ current: currentPage || 1 });
  const fetchSuppliers = useCallback(
    async (limit, id) => {
      try {
        setIsDataLoading(true);
        let tempLimit = defaultPaginationLimit;
        if (limit) {
          tempLimit = limit;
        } else {
          tempLimit = query.get('limit') ? query.get('limit') : defaultPaginationLimit;
        }
        const { data } = await get(
          id
            ? `${API.SUPPLIERS}?customer_id=${id}&page=${pagingData.current}&limit=${tempLimit}`
            : `${API.SUPPLIERS}?page=${pagingData.current}&limit=${tempLimit}`
        );
        setIsDataLoading(false);
        const { total, current_page, per_page, from, to } = data.data;
        setSuppliers(data.data.data);
        setPagingData({
          total,
          current: current_page,
          defaultPageSize: per_page,
          from: from,
          to: to
        });
      } catch (e) {
        setSuppliers([]);
        setIsDataLoading(false);
        setPagingData({ current: 0 });
      }
    },
    [pagingData.current]
  );

  useEffect(() => {
    fetchSuppliers(limit, clientId);
  }, [clientId]);

  const supplierTableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '10%',
      className: 'centeralign',
      sorter: (a, b) => a.id - b.id
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: (record) => trimString(capitalizeFirstLetter(record), 32),
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email)
    },
    {
      title: 'Business Name',
      dataIndex: 'business_name',
      render: (record) => capitalizeFirstLetter(record),
      sorter: (a, b) => a.business_name.localeCompare(b.business_name)
    }
  ];

  const handleChange = async (value) => {
    setLimit(value);
    fetchSuppliers(value, clientId);
  };

  const rowSelection = {
    selectedRowKeys: selectedRowIds,
    onChange: (selectedRowKeys, record) => {
      setSelectedRowIds([...selectedRowKeys]);
      setSelectedRows([...record]);
    }
  };

  const onPageChange = (page) => {
    setPagingData({ ...pagingData, current: page });
  };

  const numberformat = (value) => {
    try {
      let format = numbro(value).format({
        mantissa: 2
      });
      return format;
    } catch {
      return '';
    }
  };

  const handleSupplier = () => {
    let newArr = blockData;
    let block = newArr;
    let clientObject = {};

    clientObject = block[itemBlockIndex]?.payments?.find(
      (item) =>
        item?.type === 'default' &&
        !item?.withdrawal_account_id &&
        item?.client_id === block[itemBlockIndex]?.id
    );

    let clientAccountData = [];
    for (let i = 0; i < selectedRows?.length; i++) {
      clientAccountData.push({
        chart_account_id: clientObject?.chart_account_id
          ? clientObject?.chart_account_id
          : defaultAccount?.id,

        first_name: selectedRows[i]?.first_name ? selectedRows[i]?.first_name : '',

        last_name: selectedRows[i]?.last_name
          ? selectedRows[i]?.last_name
          : selectedRows[i]?.name
          ? selectedRows[i]?.name
          : '',

        account_name: selectedRows[i]?.account_name ? selectedRows[i]?.account_name : '',

        client_type: 2,

        account_number: selectedRows[i]?.account_number
          ? selectedRows[i]?.account_number
          : '000000001',

        bsb: selectedRows[i]?.bsb ? selectedRows[i]?.bsb : '000-000',

        supplier_id: selectedRows[i]?.id,

        client_id: block[itemBlockIndex]?.clientData?.id,

        beneficiary_id: '',

        description: itemJson?.description
          ? itemJson?.description
          : state?.description
          ? state.description
          : clientObject?.description,

        amount: itemJson?.amount
          ? numberformat(itemJson?.amount)
          : state?.amount
          ? numberformat(state.amount)
          : clientObject?.amount,

        clientData: block[itemBlockIndex]?.clientData,

        type: 'default'
      });
    }

    if (clientAccountData?.length > 0) {
      block[itemBlockIndex].payments = [...clientAccountData];
      block[itemBlockIndex].supplier = 1;
    }
    setBlockData(block);
    onClose();
  };

  const handleClose = () => {
    setShow(false);
    fetchSuppliers(limit, clientId);
  };

  const handleNewSupplier = () => {
    setShow(true);
  };

  return (
    <div className="cms-page">
      <div className="content-details">
        <Table
          className="task-table"
          columns={supplierTableColumns}
          dataSource={suppliers}
          loading={isDataLoading}
          rowKey={(record) => record.id}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection
          }}
          pagination={{
            hideOnSinglePage: true,
            defaultCurrent: 1,
            showQuickJumper: true,
            defaultPageSize: pagingData.pageSize,
            onChange: (page) => onPageChange(page, limit),
            itemRender: PaginationItemRenderer,
            ...pagingData,
            pageSize: limit
          }}
        />
        {suppliers?.length > 0 && (
          <div className="mt-2">
            <div className="col-md-12">
              Showing&nbsp;
              <Select
                defaultValue={`${defaultPaginationLimit}`}
                value={limit}
                onChange={handleChange}
                options={[
                  { value: '10', label: '10' },
                  { value: '25', label: '25' },
                  { value: '50', label: '50' },
                  { value: '100', label: '100' }
                ]}
              />
              &nbsp;Records per page I Showing {pagingData?.from} to&nbsp;
              {pagingData?.to} from {pagingData?.total} Records
            </div>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            gap: '15px',
            marginTop: '10px'
          }}
        >
          <div className="table-btn">
            <Button
              variant="primary"
              style={{ minWidth: 'fit-content', padding: '4px 10px', fontSize: '14px' }}
              onClick={() => handleNewSupplier()}
            >
              Add New Supplier
            </Button>
          </div>
          <Button
            style={{
              minWidth: 'fit-content',
              padding: '4px 18px',
              fontSize: '14px'
            }}
            onClick={handleSupplier}
          >
            OK
          </Button>
        </div>
      </div>
      <Modal
        className="mid-modal supplier-modal"
        id="supplier_modal"
        show={show}
        onHide={handleClose}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>{'Add'} Supplier</Modal.Header>
        <Modal.Body>
          <AddEditSupplier onClose={handleClose} clientID={clientId} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SupplierBasedOnClient;
