import React, { useState, useEffect } from 'react';
import { Divider, Select, Space } from 'antd';
import MynaSelect from '../../components/Select';
import useClientsList from './useClientList';
import { clientNameGetter } from '../../helper/fullNameGetter';

const ClientSelect = (props) => {
  const {
    onCustomerSelectionChange,
    isLoadingCustomerDetail,
    customerId,
    formProps,
    formStyle,
    placeholder,
    disabled,
    className,
    onChange,
    handleAddNewContact,
    data,
    search
  } = props;

  const {
    customersList,
    isCustomerLoading,
    pagingData,
    setSearchParam,
    searchParam,
    fetchCustomers
  } = useClientsList(0, customerId, undefined, false);
  const [selectInput, setSelectInput] = useState([]);
  const [customers, setCustomers] = useState(customersList);
  const [value, setValue] = useState(customerId);

  useEffect(() => {
    setValue(customerId);
  }, [customerId, data]);
  const handleOnCLick = () => {
    search();
    if (!isCustomerLoading) {
      fetchCustomerData(pagingData.current, '');
      customerId && searchParam && fetchCustomerData();
    }
  };

  useEffect(() => {
    const response = customers.map((item) => (
      <Select.Option
        key={Number(item.id)}
        value={Number(item.id)}
        type={item.type}
        className="client-select"
      >
        {item?.name
          ? clientNameGetter(item?.name, item?.type)
          : clientNameGetter(item?.business_name, item?.type)}
      </Select.Option>
    ));
    if (isCustomerLoading) {
      response.push(
        <Select.Option key="loading">
          {'Loading'} {'...'}
        </Select.Option>
      );
    }

    setSelectInput([...response]);
  }, [customers, isCustomerLoading]);

  const onSearch = async (val) => {
    val?.length > 2 && (await fetchCustomerData(1, val));
    val?.length > 2 && setSearchParam(val);
  };

  const fetchCustomerData = async (page, searchKey) => {
    const data = await fetchCustomers(page, searchKey);

    if (data) {
      if (page === 1) {
        setCustomers([...data.data]);
      } else {
        setCustomers([...customers, ...data.data]);
      }
    }
  };

  const onScroll = async (event) => {
    var target = event.target;
    if (
      !isCustomerLoading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      (!pagingData?.pageSize || pagingData.pageSize * pagingData.current == customers.length)
    ) {
      if (pagingData.total > customers.length) {
        target.scrollTo(0, target.scrollHeight);
        await fetchCustomerData(pagingData.current + 1, '');
      }
    }
  };

  return (
    <MynaSelect
      dropdownMatchSelectWidth={false}
      onSearch={false}
      loading={isCustomerLoading}
      placeholder={placeholder ? placeholder : 'Select Client Name'}
      onPopupScroll={onScroll}
      formStyleWidth={formStyle}
      onSelect={(e) => {
        onSearch('');
        onCustomerSelectionChange(e);
      }}
      onBlur={() => onSearch('')}
      disabled={isLoadingCustomerDetail || disabled}
      selectValueList={selectInput}
      defaultValue={value ? value : ''}
      value={value ? value : ''}
      formProps={formProps}
      className={className ? className : ''}
      onClick={handleOnCLick}
      onChange={onChange}
      display={'none'}
      filterOption={(input, option) => {
        return (
          (option.props.children && option.props.children) ||
          (option.props.type && option.props.type)
        );
      }}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider
            style={{
              margin: '8px 0'
            }}
          />
          <Space
            style={{
              padding: '0 8px 4px'
            }}
          >
            <span
              type="text"
              onClick={() => {
                handleAddNewContact();
              }}
            >
              <strong>+ Add New Client</strong>
            </span>
          </Space>
        </>
      )}
    />
  );
};

export default ClientSelect;
