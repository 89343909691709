/* eslint-disable no-unsafe-optional-chaining */
import React, { useCallback, useEffect, useState } from 'react';
import '../../../App.css';
import '../../../assets/css/style.css';
import { Table } from 'antd';
import { toast } from 'react-toastify';
import { API, get } from '../../../config';
import { ExpandedRegisterFirmColumns } from './registeredFirmHelper';

const ExpandedRegisteredTable = ({ id }) => {
  const [registeredExpandList, setRegisteredExpandList] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const fetchRegisteredExpandList = useCallback(async () => {
    try {
      const { data } = await get(API.EXPAND_REGISTERD_FIRM_LIST + id);
      setRegisteredExpandList(data?.data?.account_firm?.subscription);
      setIsDataLoading(false);
    } catch (e) {
      setRegisteredExpandList([]);
      setIsDataLoading(false);
      const errorMessage = e.response?.data?.errors?.myna_error[0];
      if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  }, [registeredExpandList]);

  useEffect(() => {
    fetchRegisteredExpandList();
  }, []);


  return (
    <div className="cms-page">
      <div className="tax-refund admin-registered-expand-table">
        <Table
          loading={isDataLoading}
          columns={ExpandedRegisterFirmColumns(fetchRegisteredExpandList)}
          dataSource={registeredExpandList}
          pagination={false}
        ></Table>
      </div>
    </div>
  );
};

export default ExpandedRegisteredTable;
