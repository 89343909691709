import React, { useState, useEffect } from 'react';
import { Steps } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { API, post, get } from '@src/config.js';
import AddBackupEmail from '../../components/Authentication/AddBackupEmail';
import ConfirmBackupEmail from '../../components/Authentication/ConfirmBackupEmail';
import RegisterAuthentication from '../../components/Authentication/RegisterAuthentication';
import ConfirmAuthentication from '../../components/Authentication/ConfirmAuthentication';
import '../../components/Authentication/authentication.css';
import { SetCookie } from '../../APIConfig/Interceptor';

const { Step } = Steps;

const TwoFactorRegister = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [current, setCurrent] = useState(0);
  const [enterCode, setEnterCode] = useState(false);
  const [enterEmail, setEnterEmail] = useState(false);
  const [qrImage, setQrImage] = useState(null);
  const [ secretKey, setSecretKey ] = useState(null);

  const [backupEmail, setBackupEmail] = useState('');

  useEffect(() => {
    post(API.GOOGLE_2FA_REGISTER)
      .then((response) => {
        setQrImage(response.data.QR_Image);
        setSecretKey(response.data.secret)
      })
      .catch(() => {});
  }, []);

  const verifyAuthentication = () => {
    setCurrent(current + 1);
  };

  const submitEmail = () => {
    setEnterEmail(true);
  };

  const verifyEmail = async () => {
    localStorage.setItem('myna-auth-tfa', 2);
    if (!localStorage.getItem('Name')) {
      let userData = await get(API.GET_USER_PROFILE);
      if (userData) {
        const { first_name, profile_pic } = userData.data;
        localStorage.setItem('Name', first_name);
        localStorage.setItem('ProfilePhoto', profile_pic);
        localStorage.setItem('myna-auth-tfa', 2);
        try {
          const { data } = await get(API.GET_SUBSCRIPTION);
          if (data) {
            await SetCookie('_subscription', 1);
          }
        } catch (error) {
          SetCookie('_subscription', 0);
          window.location.href = '/settings?tab=subscription&childTab=more-packages';
          return;
        }
        window.location.href = '/dashboard?field=name&type=asc&filter=weekly&info=true';
      } else {
        var error = {};

        error['myna_error'] = 'There is some issue with request, please try after some time.';
      }
    } else {
      if (searchParams.get('page') === 'back') {
        localStorage.setItem('myna-auth-tfa', 2);
        navigate(-1);
      }
    }
  };

  const contentStepOne = () => {
    return (
      <>
        {!enterCode ? (
          <RegisterAuthentication qrImage={qrImage} setEnterCode={setEnterCode} secretKey={secretKey} />
        ) : (
          <>
            <ConfirmAuthentication
              submitButton={verifyAuthentication}
              verifyUrl={API.GOOGLE_2FA_VERIFY}
            />
            <a
              onClick={() => {
                setEnterEmail(false);
                setEnterCode(false);
              }}
            >
              I didn&apos;t receive a code please resend
            </a>
          </>
        )}
      </>
    );
  };

  const contentStepTwo = () => {
    return (
      <>
        {!enterEmail ? (
          <AddBackupEmail
            backupEmail={backupEmail}
            setBackupEmail={setBackupEmail}
            submitEmail={submitEmail}
          />
        ) : (
          <>
            <ConfirmBackupEmail
              backupEmail={backupEmail}
              submitButton={verifyEmail}
              verifyUrl={API.VERIFY_BACKUP_EMAIL}
            />
            <a
              onClick={() => {
                setEnterEmail(false);
              }}
            >
              I didn&apos;t receive a code please resend
            </a>
          </>
        )}
      </>
    );
  };

  const steps = [
    {
      title: 'Set up app',
      content: contentStepOne()
    },
    {
      title: 'Add a backup',
      content: contentStepTwo()
    }
  ];

  if (!qrImage) return null;
  return (
    <>
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[current].content}</div>
    </>
  );
};

export default TwoFactorRegister;
