import React, { useState } from 'react';
import { Button, Input, Form } from 'antd';
import { API, post } from '@src/config.js';
import './authentication.css';

const AddBackupEmail = (props) => {
  const { backupEmail, setBackupEmail, submitEmail } = props;
  const [loading, setLoading] = useState(false);

  const changeBackupEmail = (event) => {
    setBackupEmail(event.target.value);
  };

  const addBackupEmail = async () => {
    setLoading(true);
    post(API.ADD_BACKUP_EMAIL, { backup_email: backupEmail })
      .then(() => {
        submitEmail();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div className="AntContent">
      <Form
        name="basic"
        initialValues={{
          remember: true,
          email: backupEmail
        }}
        onFinish={addBackupEmail}
        autoComplete="off"
      >
        <h3>Add a backup email</h3>
        <p>
          This email is only used to log into your account if you&apos;re locked out or don&apos;t
          have your phone.
        </p>
        <p>It must be different to the email you log in with.</p>
        <Form.Item
          label=""
          name="email"
          rules={[
            { required: true, message: 'Please enter your Email!' },
            { required: true, message: 'Email is not valid', pattern: new RegExp(/\S+@\S+\.\S+/) }
          ]}
        >
          <Input
            autoFocus
            placeholder="Backup email address"
            defaultValue={backupEmail}
            value={backupEmail}
            onChange={changeBackupEmail}
          />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" loading={loading}>
            Confirm
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddBackupEmail;
