/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { CardElement, AuBankAccountElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { API, get, fileUpload } from '../../config.js';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Form } from 'antd';
import moment from 'moment';
import { formatNumber } from '../../APIConfig/Interceptor.js';

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

const StripePaymentModal = (props) => {
  let {
    showModal,
    handleModalClose,
    id,
    planDetail,
    promoCode,
    defaultPromocode,
    islogin,
    subscriptionDetail,
    supportPlan,
    supportProduct
  } = props;

  const [form] = Form.useForm();
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const bankActive = false;
  const cardActive = true;
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [selectedList, setSelectedPayment] = useState('');
  const [exist, setExist] = useState('');
  const [firmId, setFirmID] = useState();

  const [promocodebtn, setPromocodeBtn] = useState(
    defaultPromocode && planDetail?.metadata?.plan_type === 'professional' ? true : false
  );
  const [loading, setLoading] = useState(false);
  const [amontObj, setAmontObj] = useState(
    defaultPromocode && planDetail?.metadata?.plan_type === 'professional'
      ? {
          payableAmount:
            defaultPromocode === 'FIRST50' &&
            (planDetail.prices.data[0].tax_behavior === 'inclusive'
              ? Number(planDetail.prices.data[0].price)
              : Number(planDetail.prices.data[0].price) +
                Number(planDetail.prices.data[0].price) * 0.1) / 2,
          percent_off: defaultPromocode === 'FIRST50' && '50'
        }
      : {
          amount_off: 0,
          percent_off: 0
        }
  );
  const [defaultPromoCode, setDefaultPromoCode] = useState(
    defaultPromocode && planDetail?.metadata?.plan_type === 'professional' ? defaultPromocode : null
  );

  let priceData =
    planDetail.prices.data[0].tax_behavior === 'inclusive'
      ? Number(planDetail.prices.data[0].price)
      : Number(planDetail.prices.data[0].price) + Number(planDetail.prices.data[0].price) * 0.1;

  // Form Submit Button
  const handleFinish = async (values) => {
    if (selectedList) {
      let type = selectedList.includes('card_');
      if (type) {
        sendPayment({}, {}, selectedList, firmId);
      } else {
        sendPayment({}, {}, selectedList, firmId);
      }
    } else {
      const { error, paymentMethod } =
        cardActive === true
          ? await stripe.createPaymentMethod({
              type: 'card',
              card: elements.getElement(CardElement),
              billing_details: {
                name: name ? name : ''
              }
            })
          : await stripe.createPaymentMethod({
              type: 'au_becs_debit',
              au_becs_debit: elements.getElement(AuBankAccountElement),
              billing_details: {
                name: name ? name : '',
                email: email ? email : ''
              }
            });
      sendPayment(values, error, paymentMethod?.id ? paymentMethod?.id : null, firmId);
    }
  };
  const sendPayment = async (values, error, paymentMethodId = null, firmId = null) => {
    if (paymentMethodId) {
      setLoading(true);
      if (firmId) {
        values.accounting_firm_id = firmId;
      }
      values.id = id;
      values.price_id = planDetail.prices.data[0].id;
      values.payment_method_id = paymentMethodId;
      values.couponCode = defaultPromoCode && promocodebtn ? defaultPromoCode : '';

      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (planDetail.metadata.type === 'support_plan' && key === 'price_id') {
          formData.append('support_price_id', values[key]);
        } else {
          formData.append([key], values[key]);
        }
      });

      if (supportPlan) {
        formData.append('support_price_id', supportPlan?.prices?.data[0]?.id);
      }

      try {
        let response = await fileUpload(API.FIRM_SUBSCRIPTION, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        if (response.data.status == 200) {
          handleModalClose();
          setLoading(false);
          navigate('/congratulations', {
            state: { data: response.data.data, message: response.data.message, islogin: islogin }
          });
        }
      } catch (e) {
        toast.error(e.response?.data?.errors?.myna_error[0][0]);
        handleModalClose();
        setLoading(false);
      }
    } else {
      if (error?.param == 'billing_details[name]') {
        toast.error('Name is required.');
      } else if (error?.param == 'billing_details[email]') {
        toast.error('Email is required.');
      } else {
        toast.error(error.message);
      }
    }
  };
  const validatePromoCode = (couponCode, isDefault = false) => {
    let price =
      planDetail.prices.data[0].tax_behavior === 'inclusive'
        ? Number(planDetail.prices.data[0].price)
        : Number(planDetail.prices.data[0].price) + Number(planDetail.prices.data[0].price) * 0.1;
    for (let item of promoCode) {
      if (item.id == couponCode) {
        if (item.applies_to.products.find((item) => String(item) == String(planDetail.id))) {
          setDefaultPromoCode(item.id);
          setPromocodeBtn(true);
          if (item?.percent_off) {
            setAmontObj({
              ...amontObj,
              percent_off: item?.percent_off,
              amount_off: item?.amount_off,
              payableAmount: (price * (100 - item.percent_off)) / 100
            });
          } else {
            setAmontObj({
              ...amontObj,
              percent_off: 0,
              amount_off: item?.amount_off ? item?.amount_off / 100 : item?.amount_off,
              payableAmount: price - item.amount_off / 100
            });
          }
          if (!isDefault) toast.success('Coupon code applied successfully');

          return;
        }
      }
    }
    setAmontObj({
      ...amontObj,
      amount_off: 0,
      percent_off: 0,
      payableAmount: price
    });
    if (!isDefault) {
      setAmontObj({
        ...amontObj,
        amount_off: 0,
        percent_off: 0,
        payableAmount: price
      });
      setDefaultPromoCode('');
      toast.error('Coupon code is not valid');
    }
  };


  const handleCouponChange = (e) => {
    setPromocodeBtn(false);
    setDefaultPromoCode(e.target.value);
  };

  const handleApplyClick = () => {
    validatePromoCode(defaultPromoCode);
  };

  useEffect(() => {
    if (
      moment().isBefore(moment('16-06-2023', 'DD-MM-YYYY')) &&
      planDetail?.metadata?.type === 'year'
    ) {
      setDefaultPromoCode('FIRST50');
      if (!islogin) {
        validatePromoCode('FIRST50');
      }
    }
  }, []);

  const getpaymentMethodList = async () => {
    try {
      const { data } = await get(API.GET_PAYMENTMETHOD_LIST);
      if (data) {
        setPaymentMethodList(data.data);
      } else {
        setPaymentMethodList();
      }
    } catch (error) {
      const errors = error.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  useEffect(() => {
    if (islogin) {
      getpaymentMethodList();
    }
  }, []);

  const handleOnchange = (id) => {
    setSelectedPayment(id);
  };

  const onhandlePaymentMethod = (e) => {
    if (e.target.checked) {
      setExist(true);
    } else {
      setExist(false);
    }
  };

  useEffect(async () => {
    if (islogin) {
      const { data } = await get(API.GET_FIRM_INFO);
      if (data) {
        setFirmID(data.data.id);
      } else {
        setFirmID();
      }
    }
  }, [islogin]);
  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={handleModalClose}
      dialogClassName="modal-50w medium-popup review-popup small-review"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>Enter Your Card Details</Modal.Header>
      <Modal.Body>
        <div className="content-details">
          <Form
            form={form}
            initialValues={{ couponCode: defaultPromoCode }}
            name="stripeform"
            layout="vertical"
            onFinish={handleFinish}
          >
            <div className="entry-card full-card">
              <div className="form-box">
                {islogin && paymentMethodList?.length > 0 ? (
                  <Form.Item className="half-width">
                    <input
                      type="checkbox"
                      className="mr-5"
                      onClick={(e) => onhandlePaymentMethod(e)}
                    />{' '}
                    Use Existing Payments Method
                  </Form.Item>
                ) : null}

                {cardActive === true && !exist && (
                  <>
                    <Form.Item
                      label="Card Number"
                      className="half-width credit-card"
                    >
                      <CardElement options={CARD_ELEMENT_OPTIONS} />
                    </Form.Item>
                  </>
                )}

                {exist ? (
                  <Form.Item className="half-width">
                    <div className="row table-top-btn">
                      <div className="col-12">
                        <ul className="list-group">
                          {paymentMethodList &&
                            paymentMethodList.map((item) => {
                              let expiredclass = '';
                              if (item?.type == 'card') {
                                expiredclass = moment().isBefore(moment(item.expiry, 'MM/YYYY'))
                                  ? ''
                                  : 'expired';
                              }
                              return (
                                <>
                                  <li
                                    className={
                                      'list-group-item d-flex justify-content-between align-items-start stripe-radio-btn ' +
                                      expiredclass
                                    }
                                  >
                                    <input
                                      type="radio"
                                      className="mt-1 mr-1"
                                      name="card"
                                      id={item?.id}
                                      onClick={() => handleOnchange(item?.id)}
                                    />
                                    <div className="ms-0 me-auto">
                                      <p>
                                        XXXX XXXX XXXX {item?.last4}{' '}
                                        {item?.type == 'card' && (
                                          <span className="card-brand"> ({item.brand})</span>
                                        )}
                                      </p>

                                      {item.type == 'au_becs_debit' ? (
                                        <>
                                          <strong>BSB: </strong>
                                          {item?.bsb}
                                        </>
                                      ) : (
                                        <>
                                          <strong>Card Exp.: </strong>
                                          {item?.expiry}
                                        </>
                                      )}
                                    </div>
                                  </li>
                                </>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </Form.Item>
                ) : (
                  ''
                )}
                {bankActive === true && !exist && (
                  <div className="stripiframebox">
                    <p>
                      <label>Account Details</label>
                      <AuBankAccountElement />
                    </p>
                    <p className="stripfield">
                      <label>Name</label>
                      <input
                        type="text"
                        name="name"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        autoComplete="off"
                        onKeyPress={(e) => {
                          e.which === 13 && e.preventDefault();
                        }}
                      />
                    </p>
                    <p className="stripfield">
                      <label>Email</label>
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        autoComplete="off"
                        onKeyPress={(e) => {
                          e.which === 13 && e.preventDefault();
                        }}
                      />
                    </p>
                  </div>
                )}

                <div className="form-box" style={{ padding: 0 }}>
                  <div className="promo-field">
                    <Form.Item label="Coupon Code" name="couponCode" className="half-width">
                      <Input
                        placeholder="Coupon Code"
                        value={defaultPromoCode}
                        onChange={handleCouponChange}
                      />
                      <Button className="btn" disabled={promocodebtn} onClick={handleApplyClick}>
                        {promocodebtn ? 'Applied' : 'Apply'}
                      </Button>
                    </Form.Item>
                  </div>

                  <div className="promo-features" style={{ width: '100%' }}>
                    <p>
                      <strong>Frequency:</strong>{' '}
                      {planDetail.metadata.type === 'support_plan'
                        ? 'One Time'
                        : planDetail.metadata.type}
                    </p>
                    <p>
                      <strong>Subtotal:</strong> $
                      {planDetail.prices.data[0].tax_behavior === 'inclusive'
                        ? formatNumber(Number(planDetail.prices.data[0].price) / 1.1)
                        : formatNumber(planDetail.prices.data[0].price)}
                    </p>
                    <p>
                      <strong>GST 10%:</strong> $
                      {planDetail.prices.data[0].tax_behavior === 'inclusive'
                        ? formatNumber(
                            Number(
                              Number(planDetail.prices.data[0].price) -
                                Number(planDetail.prices.data[0].price) / 1.1
                            )
                          )
                        : formatNumber(Number(Number(planDetail.prices.data[0].price) * 0.1))}
                    </p>
                    <p>
                      <strong>
                        Discount{' '}
                        {supportProduct && moment().isBefore(moment('16-05-2024', 'DD-MM-YYYY'))
                          ? '100%'
                          : amontObj.percent_off !== 0 && `(${amontObj.percent_off + '%'})`}
                        :
                      </strong>
                      {supportProduct && moment().isBefore(moment('16-05-2024', 'DD-MM-YYYY'))
                        ? `$${formatNumber(Number(Number(planDetail.prices.data[0].price)))}`
                        : amontObj.percent_off
                        ? `$${formatNumber(Number(priceData) - Number(amontObj.payableAmount))}`
                        : amontObj?.amount_off
                        ? `$${formatNumber(Number(amontObj?.amount_off))}`
                        : '$0.00'}
                    </p>
                    {supportPlan && (
                      <p>
                        <strong>White Glove Onboarding Service:</strong>$
                        {moment().isBefore(moment('16-05-2024', 'DD-MM-YYYY'))
                          ? '0.00'
                          : supportPlan?.prices?.data[0]?.price}
                      </p>
                    )}
                    {supportProduct && moment().isBefore(moment('16-05-2024', 'DD-MM-YYYY')) ? (
                      <p className="apply-block">
                        <strong>Total Net amount:</strong>$0.00
                      </p>
                    ) : (
                      <p className="apply-block">
                        <strong>Total Net amount:</strong>$
                        {supportPlan
                          ? moment().isBefore(moment('16-05-2024', 'DD-MM-YYYY'))
                            ? amontObj?.payableAmount
                              ? formatNumber(Number(amontObj?.payableAmount))
                              : formatNumber(
                                  (planDetail.prices.data[0].tax_behavior === 'inclusive'
                                    ? Number(planDetail.prices.data[0].price) / 1.1
                                    : Number(planDetail.prices.data[0].price)) +
                                    (planDetail.prices.data[0].tax_behavior === 'inclusive'
                                      ? Number(
                                          Number(planDetail.prices.data[0].price) -
                                            Number(planDetail.prices.data[0].price) / 1.1
                                        )
                                      : Number(Number(planDetail.prices.data[0].price) * 0.1))
                                )
                            : amontObj?.payableAmount
                            ? formatNumber(
                                Number(amontObj?.payableAmount) +
                                  Number(supportPlan?.prices?.data[0]?.price)
                              )
                            : formatNumber(
                                (planDetail.prices.data[0].tax_behavior === 'inclusive'
                                  ? Number(planDetail.prices.data[0].price) / 1.1
                                  : Number(planDetail.prices.data[0].price)) +
                                  (planDetail.prices.data[0].tax_behavior === 'inclusive'
                                    ? Number(
                                        Number(planDetail.prices.data[0].price) -
                                          Number(planDetail.prices.data[0].price) / 1.1
                                      )
                                    : Number(Number(planDetail.prices.data[0].price) * 0.1)) +
                                  Number(supportPlan?.prices?.data[0]?.price)
                              )
                          : (amontObj?.payableAmount || amontObj?.payableAmount === 0)
                          ? formatNumber(Number(amontObj?.payableAmount))
                          : formatNumber(
                              (planDetail.prices.data[0].tax_behavior === 'inclusive'
                                ? Number(planDetail.prices.data[0].price) / 1.1
                                : Number(planDetail.prices.data[0].price)) +
                                (planDetail.prices.data[0].tax_behavior === 'inclusive'
                                  ? Number(
                                      Number(planDetail.prices.data[0].price) -
                                        Number(planDetail.prices.data[0].price) / 1.1
                                    )
                                  : Number(Number(planDetail.prices.data[0].price) * 0.1))
                            )}
                      </p>
                    )}
                    This change in plan will be actioned
                    {!islogin ? ' at the end of the current plan ' : ' Immediately.'}
                    {!islogin ??
                      moment(subscriptionDetail?.next_payment_date, 'DD/MM/YYYY').format(
                        'DD MMMM YYYY'
                      )}
                  </div>
                </div>
              </div>

              <div className="action-box">
                <Button className="btn" type="primary" htmlType="submit" loading={loading}>
                  Confirm
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default StripePaymentModal;
