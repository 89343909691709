import React, { useLayoutEffect, useState } from 'react';
import { Button, Input, Form } from 'antd';
import { API, post, get } from '@src/config.js';
import '../../components/Authentication/authentication.css';
import { toast } from 'react-toastify';

const ConfirmBackupEmail = (props) => {
  const { submitButton, verifyUrl, backupEmail } = props;
  const [validator, setValidator] = useState(false);
  const [email, setEmail] = useState('');
  const [emailVerifyCode, setEmailVerifyCode] = useState('');
  const [loading, setLoading] = useState(false);

  useLayoutEffect(() => {
    const test = async () => {
      const { data } = await get(API.GET_USER_PROFILE);
      if (data?.backup_email) setEmail(data.backup_email);
    };
    if (!backupEmail) {
      test();
    } else {
      setEmail(backupEmail);
    }
  }, []);

  const changeEmailCode = (event) => {
    if (validator) {
      setValidator(false);
    }
    setEmailVerifyCode(event.target.value);
  };

  const confirmEmail = async () => {
    setLoading(true);
    let params = { code: emailVerifyCode };
    if (verifyUrl === API.DELETE_2FA) {
      params.id = 2;
    }
    post(verifyUrl, { code: emailVerifyCode })
      .then(async () => {
        await submitButton();
        setValidator(false);
        setLoading(false);
      })
      .catch(() => {
        toast.error('Authentication code is invalid');
        setLoading(false);
        setValidator(true);
      });
  };

  return (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      onFinish={confirmEmail}
      autoComplete="off"
    >
      <h3>Check your backup email and enter the code</h3>
      <p>{`Enter the code sent to ${email} to confirm your email address`}</p>
      <Form.Item
        label=""
        name="authCode"
        rules={[
          { required: true, message: 'Please input your Email confirmation code!' },
          {
            required: true,
            message: 'confirmation code length should be 6',
            pattern: new RegExp(/^[0-9]{6}$/)
          },
          () => ({
            validator() {
              if (validator) {
                return Promise.reject('confirmation code is not valid');
              }
              return Promise.resolve();
            }
          })
        ]}
      >
        <Input autoFocus placeholder="Confirmation Code" onChange={changeEmailCode} />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" loading={loading}>
          Confirm
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ConfirmBackupEmail;
