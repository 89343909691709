import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { API, defaultPaginationLimit, get } from '../../config.js';

const preprocessStatementList = (data) => {
  return data.map((ele) => {
    return {
      ...ele,
      name: `${ele?.reconciliation?.customer?.first_name} ${ele?.reconciliation?.customer?.last_name}`
    };
  });
};

const useBankStatementList = (
  currentPage,
  fromDate,
  toDate,
  setBalanceInfo,
  selectedTab,
  search,
  sortField,
  sortOrder
) => {
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const [statements, setStatements] = useState([]);
  const [accountFetchOptions, setAccountFetchOptions] = useState();
  const [bankTransactionAPIData, setBankTransactionAPIData] = useState([]);

  const [isDataLoading, setIsDataLoading] = useState(false);
  const [searchParam, setSearchParam] = useState(search || '');
  const [pagingData, setPagingData] = useState({ current: currentPage || 1 });
  const fetchStatement = useCallback(
    async (page, limit) => {
      if (selectedTab === 'statement') {
        try {
          setIsDataLoading(true);
          let tempLimit = defaultPaginationLimit;
          if (limit) {
            tempLimit = limit;
          } else {
            tempLimit = query.get('limit') ? query.get('limit') : defaultPaginationLimit;
          }
          const { data } = await get(
            `${API.BANK_STATEMENT_LIST}?page=${
              page || pagingData.current
            }&start_date=${fromDate}&end_date=${toDate}&limit=${tempLimit}&sort_column=${
              sortField ? sortField : ''
            }&sort_order=${sortOrder ? sortOrder : ''}`
          );
          const { total, current_page, per_page, from, to } = data.data;
          const balanceDetails = {};
          balanceDetails['firm_bank'] = data.firm_bank;
          balanceDetails['firm_balance'] = data.firm_balance;
          balanceDetails['statement_balance'] = data.statement_balance;
          balanceDetails['date'] = data?.latest_statement?.date;
          setBalanceInfo(balanceDetails);

          setBankTransactionAPIData(data);
          let accountActiveList = [];
          if (data?.connectedAccounts) {
            accountActiveList = data?.connectedAccounts?.filter((i) => i?.accountStatus === 1);
          }
          const accountActiveStatusList =
            accountActiveList.length > 0 &&
            accountActiveList.map((item) => {
              const list = {
                label:
                  item?.yodlee_provider?.providerName +
                  (item?.accountNumber
                    ? '-' +
                      item?.accountNumber +
                      (item?.accountStatus === 0 ? '(Inactive)' : '(Active)')
                    : ''),
                value: item?.accountId,
                mfa: item?.yodlee_provider?.isRealTimeMFA,
                accountId: item?.yodlee_provider?.providerAccountId
              };
              return list;
            });
          setAccountFetchOptions(accountActiveStatusList);

          const StatementsData = preprocessStatementList(data?.data?.data);
          setIsDataLoading(false);
          setPagingData({
            total,
            current: current_page,
            defaultPageSize: per_page,
            from,
            to
          });
          setStatements(StatementsData);
        } catch (e) {
          setStatements([]);
          setIsDataLoading(false);
          setPagingData({ current: 0 });
        }
      }
    },
    [pagingData.current, searchParam, fromDate, toDate, selectedTab, sortOrder, sortField]
  );

  useEffect(() => {
    fetchStatement();
  }, [fetchStatement]);

  return {
    statements,
    isDataLoading,
    setIsDataLoading,
    fetchStatement,
    accountFetchOptions,
    bankTransactionAPIData,
    pagingData,
    onPageChange: (page, limit) => {
      setPagingData({ ...pagingData, current: page });
      navigate(`/firm-bank-account?page=${page}&tab=statement&&limit=${limit}`, {
        replace: true
      });
    },
    onChange: (e) => {
      const value = e.target.value;
      setSearchParam(value);
      setPagingData({ ...pagingData, current: 1 });
      navigate(`/firm-bank-account?page=1&tab=statement`, {
        replace: true
      });
    },
    searchParam,
    setPagingData
  };
};

export default useBankStatementList;
