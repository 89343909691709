import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../App.css';
import '../../assets/css/style.css';
import SidebarNav from '../../components/Header/SidebarNav';
import Footer from '../../components/Footer/Index';
import Header from '../../components/Header/Index';
import { Tabs } from 'antd';
import BackButton from '../../components/Form/BackButton';
import MyProfile from './MyProfile';
import ChangePassword from './ChangePassword';
import Sitelogo from '../../components/Header/Logo';
import HeaderNav from '../../components/AdminHeader/HeaderNav';
import UserProfile from '../../components/AdminHeader/UserProfile';
import { checkRole } from '../../helper/utils';

const tabsKey = {
  myprofile: 1,
  'change-password': 2
};

const { TabPane } = Tabs;
const UserSettings = () => {
  const location = useLocation();
  const roleType = localStorage.getItem('role_type');
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const selectedTab = query.get('tab');
  const defaultActiveKey = tabsKey[selectedTab];
  const onTabClick = (activeKey) => {
    const tabName = Object.keys(tabsKey).find((key) => tabsKey[key] === parseInt(activeKey));
    if (tabName) {
      navigate(`/user-settings?tab=${tabName}`, {
        replace: true
      });
    }
  };
  return (
    <>
      <div className="cms-page">
        <div className="page-content-block">
          { checkRole(roleType) ? (
            <div className="header-blocks">
              <header>
                <Sitelogo />
                <HeaderNav />
                <UserProfile />
              </header>
              <div className="header-bottom-block">
              </div>
            </div>
          ) : (
            <>
              <Header />
              <SidebarNav />
            </>
          )}

          <div className="full-content-block">
            <h1 className="page-title">
              <BackButton />
              User Settings
            </h1>

            <Tabs
              defaultActiveKey={defaultActiveKey.toString()}
              size="large"
              style={{ marginBottom: 32 }}
              onTabClick={onTabClick}
            >
              <TabPane tab="My Profile" key="1">
                <MyProfile />
              </TabPane>
              <TabPane tab="Change Password" key="2">
                <ChangePassword />
              </TabPane>
            </Tabs>

            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserSettings;
