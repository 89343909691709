import React, { useState, useEffect, useCallback } from "react";

import { API, get } from "../../config.js";
import { Modal } from "antd";

import "../users/users.module.css";

const AuthorityLetterPopup = ({ toggleShowModal }) => {

  const [consentData, setconsentData] = useState(null);

  const getConsentData = useCallback(async () => {
    try {
      const { data } = await get(API.GET_CONSENT);
      const consentObj = data;
      if (consentObj) {
        const initialObj = {
          ...consentObj,
        };
        setconsentData(initialObj);
      }
    } catch (e) {
      setconsentData({});
    }
  }, []);

  useEffect(() => {
    getConsentData();
  }, [getConsentData]);


  function createMarkup() {
    return { __html: consentData.data.consent };
  }

  if (!consentData) return null;
  return (
    <Modal
      title=""
      centered
      open
      footer={null}
      width={1000}
      closable
      className="modal-body"
      onCancel={toggleShowModal}
    >
      <div className="authority-letter">
        <div className="auth-head">
          <h2>Authority to Deduct Fees From Refunds</h2>
          <div className="auth-logo">
            {consentData.data.logo == ""
              ? consentData.data.business_name
              : consentData.data.logo}
          </div>
          <p>
            Complete and return this form to {consentData.data.business_name} at
            email or address.
          </p>
        </div>
        <div className="auth-client-details">
          <h4>Section A: Clients Details</h4>
          <div className="client-detail-table">
            <p>Please complete the following section</p>
            <p>
              <label>Full Legal Name</label>
            </p>
            <p>
              <label>Residential Address</label>
            </p>
            <div>
              <p>
                <label>Suburb</label>
              </p>
              <p>
                <label>State</label>
              </p>
              <p>
                <label>Postcode</label>
              </p>
            </div>
            <p>
              <label>Bank Account Name</label>
            </p>
            <p>
              <label>Financial Institution</label>
            </p>
            <div className="account-div">
              <p>
                <label>BSB</label>
              </p>
              <p>
                <label>Account Number</label>
              </p>
            </div>
            <p>
              <label>Amount to be Deducted to cover Accounting fees</label>
            </p>
          </div>
          <p>
            <strong>
              Warning: Please ensure all details are correct. We cannot check
              the account names matches the BSB or account number. An incorrect
              BSB or accouunt number will result in your money being paid to the
              wrong account and may result in loss of your funds.
            </strong>
          </p>
        </div>
        <div dangerouslySetInnerHTML={createMarkup()} />
        <div className="signature-section">
          <div className="signature-block signature">
            <i>Signature</i>
          </div>
          <div className="signature-block print-name">
            <i>Print Name</i>
          </div>
          <div className="signature-block date">
            <i>Date</i>
          </div>
          <p>
            The authority is strictly limited to the engagement referred to
            above.
          </p>
        </div>
        <div className="office-use-only">
          <div className="client-detail-table">
            <p>Office Use Only</p>
            <div>
              <p>
                <label>Transferred Amount</label>
              </p>
              <p>
                <label>Date Transferred</label>
              </p>
              <p>
                <label>Staff Signature</label>
              </p>
            </div>
          </div>
        </div>
        <div className="authority-footer">
          <div>{consentData.data.business_name}</div>
          <div>{consentData.data.business_name}</div>
          <div>{consentData.data.business_name}</div>
          <div>{consentData.data.business_name}</div>
          <p>
            Liability limited by a scheme approved under Professional Standard
            Legislation
          </p>
        </div>
      </div>
    </Modal>
  );
};
export default AuthorityLetterPopup;
