/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useMemo } from 'react';
import { Empty, Image, Modal, Spin } from 'antd';
import { getUserDetail } from './UserConsoleHelper';
import { imageDefaultPrefixPath } from '../../config';
import moment from 'moment';
import { maskPostalCode } from '../../helper/fullNameGetter';

const UserDetailPopup = ({ userId, toggleShowModal }) => {
  const [userDetail, setUserDetail] = useState(null);
  const [userActivity, setUserActivity] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchData() {
      const userDetailData = await getUserDetail(userId, true);
      setUserDetail(userDetailData.data);
      setUserActivity(userDetailData.activity);
      setLoading(false);
    }
    fetchData();
  }, [userId]);

  const personalAddress = useMemo(() => {
    if (!userDetail?.user_wise_staff?.staff_wise_office?.suburb) return null;
    const addressObj = userDetail?.user_wise_staff?.staff_wise_office;
    return `${addressObj?.street}, ${addressObj?.suburb?.name} ${addressObj?.state?.short_name} ${
      addressObj?.postal_code ? maskPostalCode(Number(addressObj?.postal_code)) : ''
    }`;
  }, [userDetail]);

  return (
    <div>
      <Modal
        title=""
        centered
        open
        footer={null}
        width={1000}
        closable
        className="modal-body"
        onCancel={toggleShowModal}
      >
        {loading ? (
          <div className="centeralign spin-padding">
            <Spin />
          </div>
        ) : userDetail ? (
          <>
            <div className="modal-content-details justify-center">
              <div className="profile full">
                {userDetail.profile_pic == null || userDetail.profile_pic == '' ? (
                  <span>{userDetail.first_name.charAt(0)}</span>
                ) : (
                  <Image src={`${imageDefaultPrefixPath}${userDetail.profile_pic}`} />
                )}
                <h5>{userDetail.first_name + ' ' + userDetail.last_name}</h5>
              </div>
            </div>
            <div className="modal-content">
              <h3>Personal Details</h3>
              <div className="modal-content-details">
                <div className="modal-content-left">
                  <p>
                    <strong>First Name:</strong>{' '}
                    {userDetail.first_name ? userDetail.first_name : ''}
                  </p>
                  <p>
                    <strong>Phone Number:</strong> {userDetail.phone ? userDetail.phone : ''}
                  </p>
                  {userDetail?.user_wise_staff &&
                    userDetail?.user_wise_staff?.staff_wise_office?.suburb && (
                      <p>
                        <strong>Address:</strong> {personalAddress}
                      </p>
                    )}
                </div>
                <div className="modal-content-right">
                  <p>
                    <strong>Last Name:</strong> {userDetail.last_name ? userDetail.last_name : ''}
                  </p>
                  <p>
                    <strong>Email Address:</strong> {userDetail.email ? userDetail.email : ''}
                  </p>
                  <p>
                    <strong>Role:</strong> {userDetail.role.name ? userDetail.role.name : ''}
                  </p>
                </div>
              </div>

              {userActivity && userActivity.length > 0 && (
                <>
                  <h3>Recent Activity</h3>
                  <div className="modal-content-details">
                    <ul>
                      {userActivity.slice(0, 5).map((activity) => (
                        <li key={activity.id}>
                          <span className="date">
                            {moment(activity?.created_at, 'DD-MM-YYYY HH:mm:ss').format(
                              'DD-MM-YYYY HH:mm:ss'
                            )}
                          </span>
                          <span
                            style={{ marginLeft: '10px' }}
                            dangerouslySetInnerHTML={{ __html: activity.activity }}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Modal>
    </div>
  );
};

export default UserDetailPopup;
