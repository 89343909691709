import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Form from '@component/Form';
import { Dropdown, DropdownButton, Modal } from 'react-bootstrap';
import { API, fileUpload, get, imageDefaultPrefixPath } from '../../../config.js';
import { Input, Button, Select, Upload, InputNumber, DatePicker, Spin } from 'antd';
import { toast } from 'react-toastify';

import '../../users/users.module.css';
import InputMask from 'react-input-mask';
import { useStateList, useSuburbList } from '../Office/OfficeHelper';
import moment from 'moment';
import { SetCookie } from '../../../APIConfig/Interceptor.js';
import { maskPostalCode } from '../../../helper/fullNameGetter.js';

const { Option } = Select;

const getSrcFromFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.originFileObj);
    reader.onload = () => resolve(reader.result);
  });
};
const FirmSetting = ({ office }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [cancelSubscriptionLoading, setCancelSubscriptionLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [firmData, setFirmData] = useState(null);
  const [bankOptions, setBankOptions] = useState([]);
  const [bankSearch, setBankSearch] = useState('');
  const { stateLoading, stateList = [] } = useStateList();
  const [subscriptionDetail, setsubscriptionDetail] = useState(null);
  const [receiptDisable, setReceiptDisable] = useState(false);

  const {
    suburbLoading,
    suburbList = [],
    handleStateSelect,
    selectedState,
    setSuburbList
  } = useSuburbList(office?.state_id);


  const getFirmData = useCallback(async () => {
    try {
      const { data } = await get(API.GET_FIRM_INFO);
      const firmObj = data.data;
      if (firmObj) {
        const initialObj = {
          ...firmObj,
          receipt_start_number:
            firmObj?.receipt_start_number === 1 || 0
              ? (setReceiptDisable(false), firmObj?.receipt_start_number)
              : (setReceiptDisable(true), firmObj?.receipt_start_number),
          disbursement: firmObj?.disbursement_fee,
          account_name: firmObj?.firm_bank?.account_name,
          account_number: firmObj?.firm_bank?.account_number,
          bank_name: firmObj?.firm_bank?.bank_name,
          opening_balance: firmObj?.opening_balance,
          account_type: firmObj?.firm_bank?.account_type,
          bsb: firmObj?.firm_bank?.bsb,
          apca_id: firmObj?.firm_bank?.apca_id,
          postal_code: firmObj?.postal_code ? maskPostalCode(Number(firmObj?.postal_code)) : '',
          tax_agent_number: firmObj?.tax_agent_number,
          transaction_start_date: firmObj?.transaction_start_date
            ? moment(firmObj?.transaction_start_date, 'YYYY-MM-DD')
            : null
        };
        if (firmObj.logo) {
          setFileList([
            {
              url: `${imageDefaultPrefixPath}${firmObj.logo}`,
              status: 'done'
            }
          ]);
        }
        setFirmData(initialObj);
      }
    } catch (e) {
      setFirmData({});
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  }, []);

  const getsubscription = async () => {
    try {
      const { data } = await get(API.GET_SUBSCRIPTION);
      if (data) {
        SetCookie('_subscription', 1);
      }
      setsubscriptionDetail(data.data.customer);
    } catch (error) {
      const errors = error.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  useEffect(() => {
    getsubscription();
  }, []);

  useEffect(() => {
    getFirmData();
    if (suburbList.length && selectedState) {
      form.setFieldsValue({ suburb_id: suburbList[0].id });
    }
  }, [getFirmData, form, selectedState, suburbList]);

  useEffect(async () => {
    async function suburb(stateId) {
      try {
        const { data } = await get(`${API.GET_SUBURB_LIST}/${stateId}`);
        setSuburbList(data.data);
      } catch (error) {
        const errors = error.response?.data?.errors;
        Object.keys(errors).forEach((key) => {
          toast.error(errors[key][0]);
        });
      }
    }
    if (firmData?.state_id) suburb(firmData?.state_id);
  }, [firmData?.state_id]);

  const getBankList = async (searchValue) => {
    try {
      const { data } = await get(`${API.BANK_ABBREVIATION}?search=${searchValue}`);
      setBankOptions(data.data);
    } catch (error) {
      const errors = error.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  useEffect(async () => {
    getBankList(bankSearch);
  }, [bankSearch]);

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };

  const dummyRequest = ({ file, onSuccess }) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setFileList([
        {
          uid: new Date().getMilliseconds(),
          name: file.name,
          status: 'done',
          url: reader.result,
          file: file
        }
      ]);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const handleSubmit = async (values) => {
    values.transaction_start_date = values?.transaction_start_date
      ? moment(values?.transaction_start_date).format('YYYY-MM-DD')
      : '';
    values.abn = values?.abn ? `${values?.abn}`.replace(/ /g, '') : '';
    try {
      const formData = new FormData();
      if (fileList[0]?.file) formData.append('logo', fileList[0]?.file);
      Object.keys(values).forEach((key) => {
        formData.append([key], values[key]);
      });

      const { data } = await fileUpload(API.UPDATE_FIRM_SETTING, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      toast.success(data.message);
    } catch (e) {
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const handleBeforeUpload = (value) => {
    if (value?.type.split('/').length > 0 && value?.type.split('/')[0] == 'image') {
      return true;
    }
    toast.error('Image file format is invalid');
    return false;
  };

  const handleBankSearch = (value) => {
    setBankSearch(value);
  };

  const cancelSubscription = async () => {
    setCancelSubscriptionLoading(true);
    const formData = new FormData();
    formData.append(['subscription_id'], subscriptionDetail.subscription_id);

    await fileUpload(API.CANCEL_SUBSCRIPTION, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(() => {
        setCancelSubscriptionLoading(false);
        navigate('/');
      })
      .catch(() => {
        setCancelSubscriptionLoading(false);
        toast.error('Something went wrong');
      });
  };

  function onSearch() {}
  const props = {
    name: 'file',
    multiple: false,
    fileList,
    listType: 'picture',
    showUploadList: {
      showRemoveIcon: false
    }
  };

  if (!firmData) return null;
  return (
    <div className="content-details">
      <div className="entry-card full-card">
        <Form
          form={form}
          name="firmForm"
          onFinish={handleSubmit}
          initialValues={firmData}
          layout="vertical"
        >
          <div className="form-box firm-form firm-setting-form">
            <div className="image-box">
              <label>Firm Logo</label>
              <Upload
                {...props}
                customRequest={(f) => dummyRequest(f)}
                onChange={onChange}
                onPreview={onPreview}
                beforeUpload={handleBeforeUpload}
                accept=".png, .jpg, .jpeg"
              >
                Edit
              </Upload>
            </div>
            <div className="input-boxes">
              <div className="address-box firm-address contact-firm-box">
                <h5>Firm Contact Details</h5>
                <Form.Item
                  label="Business Name"
                  name="business_name"
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Business Name'
                    },
                    { whitespace: false, message: 'required' },
                    {
                      pattern: new RegExp(/^[^!@#^%$<>][a-zA-Z\s\d.,&-()]*$/),
                      message: `Business name can contain letters, numbers, spaces and some special characters like (& ( ) . , - ')`
                    }
                  ]}
                >
                  <Input placeholder="Business Name" name="business_name" />
                </Form.Item>
                <Form.Item
                  label="Firm Email"
                  name="firm_email"
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Firm Email'
                    },
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!'
                    },
                    { whitespace: false, message: 'required' }
                  ]}
                >
                  <Input placeholder="Firm Email" name="firm_email" />
                </Form.Item>
                <Form.Item label="Phone Number" name="phone" validateTrigger={['onBlur']}>
                  <InputMask
                    mask="99 9999 9999"
                    name="phone"
                    placeholder="12 1234 1234"
                    className="ant-input"
                  />
                </Form.Item>
                <Form.Item
                  label="Street"
                  name="street"
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Street Address'
                    },
                    {
                      pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                      message: `Street can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphen(-), slash(/) & spaces.`
                    }
                  ]}
                >
                  <Input placeholder="Street" name="street" />
                </Form.Item>
                <Form.Item
                  label="Select State"
                  name="state_id"
                  rules={[
                    {
                      required: true,
                      message: 'Please select State'
                    }
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    onSearch={onSearch}
                    onChange={handleStateSelect}
                    loading={stateLoading}
                    placeholder="Please select State"
                  >
                    {stateList.map((item, i) => (
                      <Select.Option key={i} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Select Suburb"
                  name="suburb_id"
                  rules={[
                    {
                      required: true,
                      message: 'Please select Suburb'
                    }
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    onSearch={onSearch}
                    loading={suburbLoading}
                    placeholder="Please select Suburb"
                  >
                    {suburbList.map((item, i) => (
                      <Select.Option key={i} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Post Code"
                  name="postal_code"
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Post Code'
                    },
                    {
                      pattern: new RegExp(/^\d{4}$/),
                      message: 'Must be 4 digit number'
                    }
                  ]}
                >
                  <Input placeholder="Post Code" name="postal_code" />
                </Form.Item>
                <Form.Item
                  label="ABN"
                  name="abn"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter ABN'
                    }
                  ]}
                >
                  <InputMask
                    mask="99 999 999 999"
                    name="abn"
                    placeholder="12 123 123 123"
                    className="ant-input"
                  />
                </Form.Item>
                <Form.Item
                  label="Receipt Start Number"
                  name="receipt_start_number"
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      message: 'A value must be number and greater than 0',
                      pattern: new RegExp(/^0*?[1-9]\d*$/)
                    }
                  ]}
                >
                  <Input
                    disabled={receiptDisable}
                    placeholder="Receipt Start Number"
                    name="receipt_start_number"
                    maxLength={12}
                  />
                </Form.Item>
              </div>
              <div className="address-box firm-address trust-firm-box">
                <h5>Trust Account Details</h5>
                <Form.Item
                  label="Bank Account Name"
                  name="account_name"
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Account Name.'
                    },
                    {
                      max: 32,
                      message: 'Must be less than 32 character.'
                    },
                    {
                      message: `The word 'Trust' or 'Client' is mandatory in account name.`,
                      validator: (_, value) => {
                        if (
                          value.length > 1 &&
                          (value.toLowerCase().includes('Trust'.toLowerCase()) ||
                            value.toLowerCase().includes('Client'.toLowerCase()))
                        ) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            `The word 'Trust' or 'Client' is mandatory in account name.`
                          );
                        }
                      }
                    },
                    {
                      pattern: new RegExp(/^[^!@#^%$<>][a-zA-Z\s\d.,&-()]*$/),
                      message: `Bank account name can contain letters, numbers, spaces and some special characters like (& ( ) . , - ')`
                    }
                  ]}
                >
                  <Input placeholder="The word 'Trust' is mandatory." name="account_name" />
                </Form.Item>
                <Form.Item label="BSB" name="bsb" validateTrigger={['onBlur']}>
                  <InputMask
                    mask="999-999"
                    name="bsb"
                    placeholder="123-456"
                    className="ant-input"
                  />
                </Form.Item>
                <Form.Item
                  label="Account Number"
                  name="account_number"
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Account Number'
                    },
                    {
                      pattern: new RegExp(/^\d{0,9}$/),
                      message: 'Must be less than 10 digit number'
                    },
                    {
                      validator: (_, value) => {
                        if (
                          (/^\d{9}?$/.test(value) && /(\d)\1{8}/.test(value)) ||
                          (/^\d{8}?$/.test(value) && /(\d)\1{7}/.test(value)) ||
                          (/^\d{7}?$/.test(value) && /(\d)\1{6}/.test(value)) ||
                          (/^\d{6}?$/.test(value) && /(\d)\1{5}/.test(value)) ||
                          (/^\d{5}?$/.test(value) && /(\d)\1{4}/.test(value)) ||
                          (/^\d{4}?$/.test(value) && /(\d)\1{3}/.test(value)) ||
                          (/^\d{3}?$/.test(value) && /(\d)\1{2}/.test(value)) ||
                          (/^\d{2}?$/.test(value) && /(\d)\1{1}/.test(value))
                        ) {
                          return Promise.reject(
                            'Please enter valid Account Number. All digits of same number are not allowed.'
                          );
                        } else {
                          return Promise.resolve();
                        }
                      }
                    }
                  ]}
                >
                  <Input placeholder="Account Number" name="account_name" />
                </Form.Item>
                <Form.Item
                  label="Bank Name"
                  name="bank_name"
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      required: true,
                      message: 'Please select Bank Name'
                    }
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    onSearch={handleBankSearch}
                    placeholder="Please select Bank"
                  >
                    {bankOptions.map((item, i) => (
                      <Select.Option key={i} value={item.abbreviation}>
                        {item.abbreviation} - {item.bank_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={
                    <div className="apca-label">
                      <span>APCA ID</span>
                      <div className="apca-info trust-firm-setting-apaca">
                        <DropdownButton className="info-icon">
                          <Dropdown.Item>
                            <p>ANZ - 000000</p>
                            <p>Westpac - 000000</p>
                            <p>NAB - 000000</p>
                            <p>CBA - 301500</p>
                            <p>BBL - 651966</p>
                          </Dropdown.Item>
                        </DropdownButton>
                      </div>
                    </div>
                  }
                  name="apca_id"
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter 6 digits APCA ID'
                    }
                  ]}
                >
                  <InputMask
                    mask="999999"
                    name="apca_id"
                    placeholder="APCA ID"
                    className="ant-input"
                  />
                </Form.Item>
                <Form.Item
                  label="Practice Management/Tax software"
                  name="tax_software"
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      required: true,
                      message: 'Please select Tax Software'
                    }
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    onSearch={onSearch}
                    placeholder="Select Tax Software"
                    allowClear
                  >
                    <Option value="Xero">Xero</Option>
                    <Option value="HandiTax">HandiTax</Option>
                    <Option value="MYOB">MYOB</Option>
                    <Option value="CCH iFirm">CCH iFirm</Option>
                    <Option value="Tax Software">Tax Software</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Accounting Body"
                  name="accounting_body"
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      required: true,
                      message: 'Please select Accounting Body'
                    }
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    onSearch={onSearch}
                    placeholder="Select Accounting Body"
                    allowClear
                  >
                    <Option value="CA">CA</Option>
                    <Option value="CPA">CPA</Option>
                    <Option value="IPA">IPA</Option>
                    <Option value="Other">Other</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Disbursement Fee"
                  name="disbursement"
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Disbursement Fee'
                    },
                    {
                      pattern: new RegExp(/^((?:[0-9]+(?:\.[0-9]+)?|\.[0-9]+)(?:[eE][0-9]+)?)$/),
                      message: 'Invalid Disbursement Fee.'
                    }
                  ]}
                >
                  <Input placeholder="Disbursement Fee" name="disbursement" />
                </Form.Item>
                <Form.Item
                  label="Tax Agent Number"
                  name="tax_agent_number"
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Tax Agent Number'
                    },
                    {
                      pattern: new RegExp(/^[0-9]+$/),
                      message: 'Tax Agent Number can contain numbers.'
                    },
                    {
                      max: 8,
                      message: 'Must be 8 digit number'
                    }
                  ]}
                >
                  <Input
                    placeholder="Tax Agent Number"
                    name="tax_agent_number"
                    onBlur={(e) => console.log(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  label="Firm Opening Balance"
                  name="opening_balance"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Firm Opening Balance'
                    },
                    {
                      pattern: new RegExp(/^((?:[0-9]+(?:\.[0-9]+)?|\.[0-9]+)(?:[eE][0-9]+)?)$/),
                      message: 'Invalid Firm Opening Balance.'
                    }
                  ]}
                >
                  <InputNumber placeholder="0" />
                </Form.Item>
                <Form.Item
                  label="Transaction Start Date"
                  name="transaction_start_date"
                >
                  <DatePicker
                    format={['DD-MM-YYYY', 'DD/MM/YYYY']}
                    defaultValue={form.getFieldValue('transaction_start_date') && moment()}
                  />
                </Form.Item>
              </div>
              {subscriptionDetail?.status === undefined ? (
                <></>
              ) : (
                <>
                  <div className="address-box firm-address subscription-firm-box">
                    <h5 className="subscription-firm-title">
                      Subscription Details
                      <span className="subscription-firm-status">
                        Status:{' '}
                        <i className="subscription-firm-status-text">
                          {subscriptionDetail?.status === 'trialing'
                            ? 'Free Trial'
                            : subscriptionDetail?.status}
                        </i>
                      </span>
                    </h5>
                    <div className="row col-md-12">
                      {subscriptionDetail?.status === 'canceled' && (
                        <>
                          <div className="col-md-4">
                            <p>
                              <label>Last day to access:</label>{' '}
                              {subscriptionDetail?.next_payment_date}
                            </p>
                          </div>
                          <div className="col-md-4">
                            <Button
                              type="primary"
                              className="reset"
                            >
                              Restart Subscription
                            </Button>
                          </div>
                        </>
                      )}
                      {subscriptionDetail?.status === 'trialing' && (
                        <>
                          <div className="col-md-4 ml-5">
                            <p>
                              <label>Plan: </label> {subscriptionDetail?.plan_type}
                            </p>
                            <p style={{ margin: '0' }}>
                              <label>Amount: </label> $
                              {subscriptionDetail?.current_plan_price ||
                                subscriptionDetail?.currency}{' '}
                              {subscriptionDetail?.plan_time != ''
                                ? 'per ' + subscriptionDetail?.plan_time
                                : ''}
                            </p>
                          </div>
                          <div className="col-md-4 ml-5">
                            <h6>Subscription Period</h6>
                            <p>
                              <label>Start Date: </label> {subscriptionDetail?.trial_start}
                            </p>
                            <p style={{ margin: '0' }}>
                              <label>End Date: </label> {subscriptionDetail?.trial_end}
                            </p>
                          </div>
                          <div className="col-md-4">
                            <p>
                              <label>Subscription Renewal Date:</label>{' '}
                              {subscriptionDetail?.next_payment_date}
                            </p>
                            <Button
                              type="primary"
                              className="reset"
                              loading={cancelSubscriptionLoading}
                              onClick={() => setShowModal(true)}
                            >
                              Cancel Plan
                            </Button>
                          </div>
                        </>
                      )}
                      {subscriptionDetail?.status === 'active' && (
                        <>
                          <div className="col-md-4 ml-5">
                            <p>
                              <label>Plan: </label> {subscriptionDetail?.plan_type}
                            </p>
                            <p style={{ margin: '0' }}>
                              <label>Amount: </label> $
                              {subscriptionDetail?.current_plan_price ||
                                subscriptionDetail?.currency}{' '}
                              {subscriptionDetail?.plan_time != ''
                                ? 'per ' + subscriptionDetail?.plan_time
                                : ''}
                            </p>
                          </div>
                          <div className="col-md-4">
                            <p>
                              <label>Next Subscription Date:</label>{' '}
                              {subscriptionDetail?.next_payment_date}
                            </p>
                            <Button
                              type="primary"
                              className="reset"
                              loading={cancelSubscriptionLoading}
                              onClick={() => setShowModal(true)}
                            >
                              Cancel Subscription
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="action-box">
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </div>
        </Form>
      </div>
      {showModal && (
        <Modal
          size="lg"
          show={showModal}
          onHide={() => setShowModal(false)}
          dialogClassName="modal-50w medium-popup review-popup"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton style={{ marginBottom: '20px' }}>
            Cancel Subscription
          </Modal.Header>
          {cancelSubscriptionLoading && <Spin />}
          <Modal.Body style={{ textAlign: 'center', paddingBottom: '20px' }}>
            <p>Are you sure you want to cancel subscription?</p>
            <Button className="btn" onClick={() => setShowModal(false)}>
              No
            </Button>
            <Button className="btn" onClick={cancelSubscription}>
              Yes
            </Button>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};
export default FirmSetting;
