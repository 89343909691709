import React, { forwardRef, useState } from 'react';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faChevronCircleRight, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { getAssignedTo, getFirstChar } from './DashboardHelper';
import { formatNumber } from '../../APIConfig/Interceptor';
import AssignModal from './AssignModal';
import { useNavigate } from 'react-router-dom';
import { API, fileUpload } from '../../config';
import { toast } from 'react-toastify';
import { Spin } from 'antd';

function UnderReviewElement(props, ref) {
  const { item, onAssign } = props;
  const [isAssignModalVisible, setIsAssignModalVisible] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);

  const navigate = useNavigate();

  const handleSelect = (value, item) => {
    if (value === 'assign') {
      setSelectedCardId(item?.id);
      setIsAssignModalVisible(true);
    } else if (value === 'sendForReview' || value === 'reviewOnce') {
      navigate('/process-refund-individual/' + item?.customer?.id);
    } else if (value === 'processRefund') {
      setShowCommentModal(true);
    }
  };

  const handleCloseCommentModal = () => {
    setShowCommentModal(!showCommentModal);
  };

  const processRefund = () => {
    setIsSyncing(true);
    const formData = new FormData();
    formData.append('_method', 'PUT');
    formData.append('process_refund', '1');
    formData.append('description', item?.description);
    fileUpload(API.MATCH_UNMATCH_REVIEW + item.id, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then((response) => {
        if (response.data.status == '200') {
          handleCloseCommentModal();
          onAssign();
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        const { data } = error.response;
        toast.error(data?.message);
        toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      })
      .finally(() => {
        setIsSyncing(false);
        setShowCommentModal(false);
      });
  };
  return (
    <>
      <li className="card-box" ref={ref}>
        <p className="crn">
          CRN:{' '}
          {item.tax_software?.crn == null || item.tax_software?.crn == ''
            ? 'ID' + item?.customer?.id
            : item?.tax_software?.crn}
        </p>
        <p className="username">
          <FontAwesomeIcon icon={faUser} size="1x" /> {item?.tax_software?.first_name}{' '}
          {item?.tax_software?.last_name}
        </p>
        <p>
          {item?.tax_software?.account_number?.toString()}
          {item.year !== undefined && <span style={{ marginLeft: 'auto' }}>ITR {item.year}</span>}
        </p>
        <p className="refund-p">
          ATO Refund <span>${formatNumber(item?.ato?.refund) || 0}</span>
        </p>
        <p className="refund-p">
          Refund as per{' '}
          {item?.tax_software?.tax_software ? item?.tax_software?.tax_software : 'Xero'}{' '}
          <span>${formatNumber(item?.tax_software?.refund) || 0}</span>
        </p>
        <p className="comments">Comments: {item?.comment}</p>
        <div className="card-footer">
          <FontAwesomeIcon icon={faChevronCircleRight} /> Assigned to {getAssignedTo(item) || 'N/A'}
          {getFirstChar(item) ? <span className="firstchar">{getFirstChar(item)}</span> : null}
          <Dropdown onSelect={(value) => handleSelect(value, item)}>
            <Dropdown.Toggle id="dropdown-basic">
              <FontAwesomeIcon icon={faEllipsisV} size="1x" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item eventKey="assign">Reassign</Dropdown.Item>
              <Dropdown.Item eventKey="reviewOnce">Review Once</Dropdown.Item>
              <Dropdown.Item eventKey="processRefund">Process Refund</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </li>
      {isAssignModalVisible && (
        <AssignModal
          cardId={selectedCardId}
          onClose={() => setIsAssignModalVisible(false)}
          onAssign={onAssign}
        />
      )}

      <Modal
        show={showCommentModal}
        onHide={handleCloseCommentModal}
        dialogClassName="modal-50w small-popup"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        footer={null}
      >
        <Modal.Header>Confirmation!</Modal.Header>
        <Modal.Body>
          <div className="remove-content">
            <p>Are your sure you want to Process Refund and move it to Processed/Completed?</p>
            <div className="modal-action">
              <Button className="cancel" disabled={isSyncing} onClick={handleCloseCommentModal}>
                <Spin spinning={isSyncing} />
                Cancel
              </Button>
              <Button disabled={isSyncing} onClick={processRefund}>
                <Spin spinning={isSyncing} />
                OK
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default forwardRef(UnderReviewElement);
