/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import { Select } from 'antd';
import MynaSelect from '../../components/Select';
import useCustomerList from '../../pages/customer/useCustomerList';
import { debounce } from 'lodash';

const UserSelect = (props) => {
  const {
    onCustomerSelectionChange,
    isLoadingCustomerDetail,
    customerId,
    formProps,
    formStyle,
    placeholder,
    clearable = true,
    clientData,
    disabled
  } = props;

  const {
    customersList,
    isCustomerLoading,
    pagingData,
    setSearchParam,
    searchParam,
    fetchCustomers
  } = useCustomerList(0, customerId, undefined, false);
  const [selectInput, setSelectInput] = useState([]);
  const [customers, setCustomers] = useState(customersList);

  useEffect(() => {
    if (!isCustomerLoading) {
      fetchCustomerData(pagingData.current, customerId ? customerId : searchParam);
      customerId && searchParam && fetchCustomerData();
    }
  }, []);

  useEffect(() => {
    const response = customers.map((item) => (
      <Select.Option key={Number(item.id)} value={Number(item.id)} email={item.email}>
        {item.first_name} {item.last_name} {item?.crn ? '('+ item.crn +")" : ''}
      </Select.Option>
    ));
    if (isCustomerLoading) {
      response.push(
        <Select.Option key="loading">
          {'Loading'} {'...'}
        </Select.Option>
      );
    }

    setSelectInput([...response]);
  }, [customers, isCustomerLoading]);

  const handleSearchChange = (e) => {
    debouncedSave(e);
  };

  const debouncedSave = useCallback(
    debounce((nextValue) => {
      onSearch(nextValue);
    }, 400),
    []
  );

  const onSearch = async (val) => {
    await fetchCustomerData(1, val);
    setSearchParam(val);
  };

  const fetchCustomerData = async (page, searchKey) => {
    const data = await fetchCustomers(page, searchKey);
    if (data && data?.data.length > 0) {
      if (page == 1) {
        setCustomers(data.data);
      } else {
        setCustomers([...customers, ...data.data]);
      }
    }
  };

  const onScroll = async (event) => {
    var target = event.target;
    if (
      !isCustomerLoading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      (!pagingData?.pageSize || pagingData.pageSize * pagingData.current == customers.length)
    ) {
      if (pagingData.total > customers.length) {
        target.scrollTo(0, target.scrollHeight);
        await fetchCustomerData(pagingData.current + 1, searchParam);
      }
    }
  };

  return (
    <MynaSelect
      onSearch={(e) => handleSearchChange(e)}
      loading={isCustomerLoading}
      placeholder={placeholder ? placeholder : 'Select Client Name'}
      onPopupScroll={onScroll}
      formStyleWidth={formStyle}
      onChange={(e) => {
        onSearch('');
        onCustomerSelectionChange(e);
      }}
      onBlur={() => onSearch('')}
      disabled={isLoadingCustomerDetail || disabled}
      selectValueList={selectInput}
      value={
        clientData
          ? {
              value: clientData?.id,
              label: `${
                clientData.first_name +
                ' ' +
                clientData.last_name +
                (clientData?.crn ? '(' + clientData.crn + ')' : '')
              }`
            }
          : customerId
      }
      defaultValue={
        clientData
          ? {
              value: clientData?.id,
              label:
                clientData.first_name + ' ' + clientData.last_name + clientData?.crn
                  ? '(' + clientData.crn + ')'
                  : ''
            }
          : customerId
      }
      formProps={formProps}
      clearable={clearable}
      filterOption={(input, option) => {
        return (
          (option.props.children &&
            option.props.children.join('').toLowerCase().indexOf(String(input).toLowerCase()) >=
              0) ||
          (option.props.email && option.props.email.toLowerCase().indexOf(input.toLowerCase()) >= 0)
        );
      }}
    />
  );
};

export default UserSelect;
