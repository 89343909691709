import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal as AntdModal, Button as AntdButton } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faRefresh, faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { Card, Button, Spin } from 'antd';
import '../../users/users.module.css';
import Salesforce from '../../../assets/images/salesforce-logo.png';
import BasiqLogo from '../../../assets/images/Bank-Feeds.svg';
import XeroLogo from '../../../assets/images/xero-logo.png';
import {
  disconnectSalesforce,
  fetchAccountConnectionData,
  updateSalesforceAuthCode,
  disconnectXero,
  getYodleeAccessToken
} from './IntegrationHelper.js';
import { Dropdown, DropdownButton, Modal } from 'react-bootstrap';
// import { /* API, */ yodleeConfigName, yodleeFastLink, yodleeobAppName } from '../../../config';
import { /* API, */ API, fileUpload, get, yodleeConfigName, yodleeFastLink } from '../../../config';
import { YodleeConnectModal } from './components/YodleeConnectModal';
import DisconnectBank from './components/DisconnectBank';

const Integration = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const code = query.get('code');
  const error = query.get('error');
  const [integrationData, setIntegrationData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [xeroData, setXeroData] = useState(null);
  const isConnected = integrationData?.connection_status;
  const [openYodlee, setOpenYodlee] = useState(false);
  const [openYodleeLoading, setOpenYodleeLoading] = useState(false);
  const [fastLinkData, setFastLinkData] = useState({});
  const [disconnectModal, setDisconnectModal] = useState(false);
  const [isYodleeConnect, setIsYodleeConnect] = useState(false);
  const [yodleeAccounts, setYodleeAccounts] = useState([]);
  const [yodleeAccountId, setYodleeAccountId] = useState(false);

  const [providerID, setProviderID] = useState();
  const [yodleeLogo, setYodleeLogo] = useState();
  const [show, setShow] = useState(false);

  const [showBankDiscount, setShowBankDiscount] = useState(false);

  const toggleXeroDisconnectModal = () => {
    setShow(!show);
  };

  const fetchSalesForceConnectionData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await fetchAccountConnectionData();
      setXeroData(response?.xero_integration_data ? response?.xero_integration_data : '');
      setIntegrationData(response.sf_integration_data);
      const yodleeStatus = response?.yodlee_integration_data?.connection_status;
      yodleeStatus === 1 && setYodleeLogo(response?.yodlee_integration_data?.favicon);
      setProviderID(response?.yodlee_integration_data?.providerAccountId);
      yodleeStatus === 1 && (await getYodleeConnectedAccount());
      setIsYodleeConnect(yodleeStatus);
      setIsLoading(false);
    } catch (e) {
      setIntegrationData(null);
      setIsLoading(false);
    }
  }, []);

  const fetchFirmInfo = useCallback(async () => {
    try {
      // const firmObj = await fetchFirmInfoData();
      // setFirmInfo(firmObj);
    } catch (e) {
      // setFirmInfo({});
      const errorMessage = e.response?.data?.errors?.myna_error[0];
      if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  }, []);

  useEffect(() => {
    fetchFirmInfo();
  }, [fetchFirmInfo]);

  useEffect(() => {
    fetchSalesForceConnectionData();
  }, [fetchSalesForceConnectionData]);

  const updateCode = useCallback(async () => {
    try {
      if (code) {
        await updateSalesforceAuthCode(code);
        toast.success('Salesforce connected successfully');
        fetchSalesForceConnectionData();
        navigate('/settings?tab=integrations');
      }
      if (error) {
        toast.error(error);
        navigate('/settings?tab=integrations');
      }
    } catch (e) {
      console.log('e updateCode :: ', e);
    }
  }, [code, error, fetchSalesForceConnectionData, navigate]);

  useEffect(() => {
    updateCode();
  }, [updateCode]);

  const handleConnection = async () => {
    if (isConnected) {
      await disconnectSalesforce();
      fetchSalesForceConnectionData();
      toast.success('Salesforce disconnected successfully');
      navigate('/settings?tab=integrations');
    } else {
      if (integrationData) {
        window.location.href = integrationData.oauth_url;
      }
    }
  };

  const handleXeroDisconnect = async () => {
    const xeroDisconnect = await disconnectXero(toggleXeroDisconnectModal);
    fetchSalesForceConnectionData();
    xeroDisconnect?.message && toast.success(xeroDisconnect?.message);
    navigate('/settings?tab=integrations');
  };

  const handleXeroConnection = async () => {
    if (xeroData?.connection_status === 0 || xeroData?.tenant == null) {
      // const xeroDisconnect = await disconnectXero();
      // fetchSalesForceConnectionData();
      // xeroDisconnect?.message && toast.success(xeroDisconnect?.message);
      // navigate('/settings?tab=integrations');
      if (xeroData?.oauth_url) {
        window.location.href = xeroData?.oauth_url;
      }
    } else {
      toggleXeroDisconnectModal();
    }
  };

  const config = {
    // params: { configName: '', obAppName: '' }
    params: { configName: '' }
  };

  const handleYodleeControl = async () => {
    try {
      setOpenYodleeLoading(true);
      const yodleeAccessToken = await getYodleeAccessToken();
      let accessToken = yodleeAccessToken?.token?.accessToken;
      config.fastLinkURL = yodleeFastLink;
      config.accessToken = `Bearer ${accessToken}`;
      config.params.configName = yodleeConfigName;
      // config.params.obAppName = yodleeobAppName;
      setFastLinkData(config);
      setOpenYodlee(true);
      setOpenYodleeLoading(false);
      return;
    } catch (error) {
      setOpenYodleeLoading(false);
    }
  };

  const handleYodleeEditControl = async () => {
    try {
      setOpenYodleeLoading(true);
      const yodleeAccessToken = await getYodleeAccessToken();
      let accessToken = yodleeAccessToken?.token?.accessToken;
      config.fastLinkURL = yodleeFastLink;
      config.accessToken = `Bearer ${accessToken}`;
      config.params = {
        flow: 'edit',
        providerAccountId: providerID,
        configName: yodleeConfigName
        // obAppName: yodleeobAppName
      };
      setFastLinkData(config);
      setOpenYodlee(true);
      setOpenYodleeLoading(false);
      return;
    } catch (error) {
      setOpenYodleeLoading(false);
    }
  };

  const handleYodleeRefreshControl = async () => {
    try {
      setOpenYodleeLoading(true);
      const yodleeAccessToken = await getYodleeAccessToken();
      let accessToken = yodleeAccessToken?.token?.accessToken;
      config.fastLinkURL = yodleeFastLink;
      config.accessToken = `Bearer ${accessToken}`;
      config.params = {
        flow: 'refresh',
        providerAccountId: providerID,
        configName: yodleeConfigName
        // obAppName: yodleeobAppName
      };
      setFastLinkData(config);
      setOpenYodlee(true);
      setOpenYodleeLoading(false);
      return;
    } catch (error) {
      setOpenYodleeLoading(false);
    }
  };

  const handleYodleeModal = () => {
    setOpenYodlee(false);
  };

  const handleProviderBank = () => {
    setDisconnectModal(false);
  };

  const toggleDisconnectModal = () => {
    setDisconnectModal(false);
  };

  const toggleBankAccountDisconnectModal = (id) => {
    if (id) {
      setYodleeAccountId(id);
    }
    setShowBankDiscount(!showBankDiscount);
  };

  const getYodleeConnectedAccount = async () => {
    try {
      const { data } = await get(`${API.YODLEE_BANK_ACCOUNT_CONNECTED}`);
      setYodleeAccounts(data?.data);
    } catch (e) {
      setYodleeAccounts([]);
      return null;
    }
  };

  const handleYodleeDisconnectBankAccount = async () => {
    try {
      const formData = new FormData();
      formData.append('yodlee_account_id', yodleeAccountId);
      const { data } = await fileUpload(API.YODLEE_BANK_ACCOUNT_DISCONNECT, formData);
      fetchSalesForceConnectionData();
      toggleBankAccountDisconnectModal();
      setYodleeAccountId();
      data?.message && toast.success(data?.message);
      navigate('/settings?tab=integrations');
      return data;
    } catch (e) {
      return null;
    }
  };

  return (
    <div className="integration-card">
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <h3>CRM &amp; Other Systems</h3>
          <Card title="" bordered={false}>
            <div className="crm-img">
              <img src={Salesforce} />
            </div>
            {/* <p>A Complete CRM System Build Around Your Needs</p> */}
            <p>Access Practice Manager built on Salesforce</p>
            <Button onClick={handleConnection} className="btn">
              {isConnected ? 'Disconnect' : 'Connect'}
            </Button>
          </Card>
          <Card title="" bordered={false}>
            <div className="crm-img">
              <img src={XeroLogo} />
            </div>
            <p>A powerful online accounting software solution.</p>
            {xeroData?.connection_status == 1 && xeroData?.tenant ? (
              <p className="xero-org">
                <b>ORG</b>: {xeroData?.tenant}
              </p>
            ) : (
              <></>
            )}
            <Button
              className="btn"
              onClick={() => {
                handleXeroConnection();
              }}
            >
              {xeroData?.connection_status === 0 || xeroData?.tenant == null
                ? 'Connect'
                : 'disconnect'}
            </Button>
          </Card>

          <h3>Automatic Bank Feeds into TrustEasy</h3>
          <Card title="" bordered={false} className="integration-bank-feed-card">
            <div className="crm-img">
              <img src={isYodleeConnect ? yodleeLogo : BasiqLogo} />
            </div>
            <div className="integration-card-info">
              <DropdownButton className="info-icon">
                <Dropdown.Item>
                  <p style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                    You will receive a similar SMS and Email upon successful connection
                  </p>
                  <p>Hi Account Name,</p>
                  <p>
                    Your Bank Name banking data access by Yodlee MYNA AUSTRALIA PTY LTD (TrustEasy)
                    was shared. If you have a joint account, the other account holder(s) may have
                    shared this access.
                  </p>
                  <p>
                    If you aren&apos;t aware of this data sharing or amendment, please contact us
                    urgently on xx xx xx.
                  </p>
                  <p>
                    Thanks,
                    <br />
                    Bank Name
                  </p>
                </Dropdown.Item>
              </DropdownButton>
            </div>

            {openYodleeLoading && <Spin />}
            {window.fastlink.close()}
            {/* <p>Accelerate access to Open Banking</p> */}
            <p>Bank Feeds</p>
            {yodleeAccounts?.length > 0 && (
              <div className="yodlee-bank-account-list">
                <h6>Connected Bank Accounts</h6>
                <ul>
                  {yodleeAccounts?.map((item, index) => (
                    <li key={index}>
                      <span>{item?.providerName + ` - ${item?.accountName}`}</span>
                      {yodleeAccounts?.length > 1 &&<FontAwesomeIcon
                        icon={faTrash}
                        onClick={() => toggleBankAccountDisconnectModal(item?.id)}
                      />}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {isYodleeConnect ? (
              <div className="mb-2 yodlee card-icons">
                <FontAwesomeIcon icon={faEdit} className="ml-2" onClick={handleYodleeEditControl} />
                <FontAwesomeIcon icon={faRefresh} onClick={handleYodleeRefreshControl} />
              </div>
            ) : (
              ''
            )}
            <Button
              id="btn-fastlink"
              onClick={() => {
                isYodleeConnect ? setDisconnectModal(true) : handleYodleeControl();
              }}
              className="btn"
            >
              {isYodleeConnect ? 'Disconnect' : 'Connect'}
            </Button>
          </Card>
        </>
      )}
      <Modal
        size="lg"
        show={openYodlee}
        onHide={handleYodleeModal}
        dialogClassName="modal-50w medium-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Auto Bank Feeds Account Integration
        </Modal.Header>
        <Modal.Body>
          <YodleeConnectModal
            handleYodleeModal={handleYodleeModal}
            fastLinkData={fastLinkData}
            fetchSalesForceConnectionData={fetchSalesForceConnectionData}
            handleProviderBank={handleProviderBank}
            setOpenYodleeLoading={setIsLoading}
          />
        </Modal.Body>
      </Modal>
      {disconnectModal && (
        <DisconnectBank
          toggleDisconnectModal={toggleDisconnectModal}
          handleDisconnect={handleProviderBank}
          setProviderBankLoading={isLoading}
          disconnectModal={disconnectModal}
          fetchSalesForceConnectionData={fetchSalesForceConnectionData}
        />
      )}
      {show && (
        <AntdModal
          show={show}
          className="remove-popup"
          width={500}
          closable
          centered
          open
          footer={null}
          onCancel={toggleXeroDisconnectModal}
        >
          <div className="remove-content">
            {/* {loading && <Spin />} */}
            <p>
              Do you want to disconnect <b>{xeroData?.tenant}</b>?
            </p>
            <div className="modal-action">
              <AntdButton className="cancel" onClick={toggleXeroDisconnectModal}>
                Cancel
              </AntdButton>
              <AntdButton onClick={handleXeroDisconnect}>OK</AntdButton>
            </div>
            {/* {errorMessage && <p className="text-danger">{errorMessage}</p>} */}
          </div>
        </AntdModal>
      )}

      {showBankDiscount && (
        <AntdModal
          show={showBankDiscount}
          className="remove-popup"
          width={500}
          closable
          centered
          open
          footer={null}
          onCancel={() => {
            toggleBankAccountDisconnectModal();
            setYodleeAccountId();
          }}
        >
          <div className="remove-content">
            {/* {loading && <Spin />} */}
            <p>Do you want to disconnect this bank account?</p>
            <div className="modal-action">
              <AntdButton
                className="cancel"
                onClick={() => {
                  toggleBankAccountDisconnectModal();
                  setYodleeAccountId();
                }}
              >
                Cancel
              </AntdButton>
              <AntdButton onClick={() => handleYodleeDisconnectBankAccount()}>OK</AntdButton>
            </div>
            {/* {errorMessage && <p className="text-danger">{errorMessage}</p>} */}
          </div>
        </AntdModal>
      )}
    </div>
  );
};
export default Integration;
