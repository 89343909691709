/* eslint-disable no-nested-ternary */

import moment from 'moment';
import { formatNumber } from '../../../APIConfig/Interceptor';

/* eslint-disable react/react-in-jsx-scope */
export const getTableColumnsClient = () => {
  return [
    {
      title: 'Date',
      width: '10%',
      className: 'centeralign',
      render: (record) => <span>{moment(record.date).format('DD-MM-YYYY')}</span>
    },
    {
      title: 'Name',
      render: (record) => <>{record?.customer_name ? record?.customer_name : ''}</>
    },
    {
      title: 'CRN',
      dataIndex: 'crn',
      render: (record) => <>{record ? record : '-'}</>
    },
    {
      title: 'Description',
      width: '36%',
      render: (record) => (
        <span>
          {record?.description ? record?.description : record?.bank_statement?.description}
        </span>
      )
    },
    {
      title: 'Amount($)',
      dataIndex: 'amount',
      className: 'rightalign',
      render: (record) => <>{record ? `${formatNumber(record)}` : '0.00'}</>
    }
  ];
};

export const getTableColumnsBank = () => {
  return [
    {
      title: 'Date',
      width: '10%',
      className: 'centeralign',
      render: (record) => <span>{moment(record.date).format('DD-MM-YYYY')}</span>
    },
    {
      title: 'Description',
      width: '36%',
      render: (record) => (
        <span>
          {record?.description ? record?.description : record?.bank_statement?.description}
        </span>
      )
    },
    {
      title: 'Credit($)',
      className: 'centeralign',
      width: '12%',
      render: (record) => {
        return (
          record?.transaction_type === 1 && (
            <span className="rag-green">{formatNumber(record?.amount)}</span>
          )
        );
      }
    },
    {
      title: 'Debit($)',
      className: 'centeralign',
      width: '12%',
      render: (record) => {
        return (
          record?.transaction_type === 0 && (
            <span className="rag-red">{formatNumber(record?.amount)}</span>
          )
        );
      }
    },

    {
      title: 'Bank Transaction Source',
      className: 'centeralign',
      width: '20%',
      render: (record) => {
        return <span>{record?.source ? record?.source : ''}</span>;
      }
    }
  ];
};
