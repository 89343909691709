/* eslint-disable no-nested-ternary */
import React from 'react';
import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FullNameGetter, phoneAndMobile, trimString } from '../../helper/fullNameGetter';
import { API, get, maskTFN } from '../../config';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import NotInmportedDelete from './NotInmportedDelete';

export const getImportLogsTableColumns = (handleDetails, selectedTab, handleDelete, notImported) => {
  return [
    {
      title: 'Name',
      key: 'name',
      className: 'fullname',
      render: (record) => {
        if (
          (record?.first_name !== null &&
            record?.first_name !== undefined &&
            record?.first_name !== '' &&
            record?.first_name) ||
          (record?.last_name !== null &&
            record?.last_name !== undefined &&
            record?.last_name !== '' &&
            record?.last_name)
        ) {
          return trimString(FullNameGetter(record), 32);
        } else {
          return '';
        }
      },
      width: '15%'
    },
    {
      title: 'Import From',
      render: (record) => <span>{record.import_from ? record.import_from : ''}</span>,
      width: '10%'
    },
    {
      title: 'Business Name',
      render: (record) => <span>{record.bussiness_name ? record.bussiness_name : ''}</span>,
      width: '14%'
    },
    {
      title: 'Email',
      render: (record) => <span>{record.email ? record.email : ''}</span>,
      width: '16%'
    },
    {
      title: 'Phone / Mobile',
      render: (record) => <span>{phoneAndMobile(record)}</span>,
      width: '15%'
    },
    {
      title: `${notImported ? 'Reason' : 'Description'}`,
      render: (record) => (
        <span style={{ wordBreak: 'break-word' }}>
          {record.description ? record.description : ''}
        </span>
      ),
      width: '12%'
    },
    {
      title: 'TFN',
      render: (record) => <span>{record.tfn ? maskTFN(record.tfn) : ''}</span>,
      width: '7%'
    },
    {
      title: 'Import Date',
      render: (record) => (
        <span>
          {record.updated_at
            ? moment(record.updated_at, 'DD-MM-YYYY HH:mm:SS').format('DD-MM-YYYY')
            : record.created_at
            ? moment(record.created_at, 'DD-MM-YYYY HH:mm:SS').format('DD-MM-YYYY')
            : ''}
        </span>
      ),
      width: '9%'
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'action',
      className: 'centeralign',
      render: (id) => (
        <>
          <FontAwesomeIcon icon={faEye} size="1x" onClick={() => handleDetails(id)} />
          {selectedTab === 'not-imported' && (
            <NotInmportedDelete handleDelete={handleDelete} id={id} />
          )}
          {selectedTab === 'not-imported' && (
            <>
              <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
              <Link to={{ pathname: `/customer-import-logs/edit`, search: `?id=${id}` }}>
                <FontAwesomeIcon icon={faEdit} size="1x" />
              </Link>
            </>
          )}
        </>
      ),
      width: '127px'
    }
  ];
};

export const getImportLogDetail = async (importLogId) => {
  try {
    const { data } = await get(`${API.IMPORT_LOGS}/` + importLogId);
    return data.data;
  } catch (e) {
    const errors = e.response?.data?.errors;
    Object.keys(errors).forEach((key) => {
      toast.error(errors[key][0]);
    });
    return null;
  }
};

export const useStateList = () => {
  const [stateList, setStateList] = useState([]);
  const [stateLoading, setStateLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setStateLoading(true);
      const { data } = await get(API.GET_STATE_LIST);
      setStateLoading(false);
      setStateList(data.data);
    } catch (e) {
      setStateList([]);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    stateList,
    stateLoading
  };
};

export const useSuburbList = () => {
  const [suburbList, setSuburbList] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [suburbLoading, setSuburbLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      if (selectedState) {
        setSuburbLoading(true);
        const { data } = await get(`${API.GET_SUBURB_LIST}/${selectedState}`);
        setSuburbLoading(false);
        setSuburbList(data.data);
      }
    } catch (e) {
      setSuburbList([]);
    }
  }, [selectedState]);

  useEffect(() => {
    init();
  }, [init]);

  return {
    suburbList,
    handleStateSelect: (value) => {
      setSuburbList([]);
      setSelectedState(value);
    },
    suburbLoading,
    setSuburbList,
    selectedState
  };
};

export const useSuburbList1 = () => {
  const [suburbList1, setSuburbList1] = useState([]);
  const [selectedState1, setSelectedState1] = useState('');
  const [suburbLoading1, setSuburbLoading1] = useState(false);
  const init = useCallback(async () => {
    try {
      if (selectedState1) {
        setSuburbLoading1(true);
        const { data } = await get(`${API.GET_SUBURB_LIST}/${selectedState1}`);
        setSuburbLoading1(false);
        setSuburbList1(data.data);
      }
    } catch (e) {
      setSuburbList1([]);
    }
  }, [selectedState1]);

  useEffect(() => {
    init();
  }, [init]);

  return {
    suburbList1,
    handleStateSelect1: (value) => {
      setSuburbList1([]);
      setSelectedState1(value);
    },
    suburbLoading1,
    setSuburbList1,
    selectedState1
  };
};
