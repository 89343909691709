import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { NavDropdown } from 'react-bootstrap';

const DialogModal = ({ title, p1, p2, p3, p4, p5, p6, p7 }) => {
  useEffect(() => {});
  return (
    <>
      <NavDropdown title="" id="nav-dropdown" className="d-flex align-items-center icon-info dialog-modal">
        <NavDropdown.Item className="dropdown-item information">
          <h4 className="mb-2 mt-1">{title}</h4>
          <a href="#" className="close">
            <FontAwesomeIcon icon={faTimes} />
          </a>
          <p>{p1}</p>
          <br />
          <p>{p2}</p>
          <br />
          <p>{p3}</p>
          <br />
          <p>{p4}</p>
          <br />
          <p>{p5}</p>
          <br />
          <p>{p6}</p>
          <br />
          <p>{p7}</p>
        </NavDropdown.Item>
      </NavDropdown>
    </>
  );
};

export default DialogModal;
