/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import { Select } from 'antd';
import MynaSelect from '../../components/Select';
import useAllClientList from '../../pages/customer/useAllClientList';
import { debounce } from 'lodash';

const AllClientsSelect = (props) => {
  const {
    onCustomerSelectionChange,
    isLoadingCustomerDetail,
    customerId,
    formProps,
    formStyle,
    placeholder,
    clearable = true,
    disabled
  } = props;

  const {
    customersList,
    isCustomerLoading,
    pagingData,
    setSearchParam,
    searchParam,
    fetchCustomers
  } = useAllClientList(0, customerId, undefined, false);
  const [selectInput, setSelectInput] = useState([]);
  const [customers, setCustomers] = useState(customersList);

  useEffect(() => {
    if (!isCustomerLoading) {
      fetchCustomerData(pagingData.current, customerId ? customerId : searchParam);
      customerId && searchParam && fetchCustomerData();
    }
  }, []);

  const handleSearchChange = (e) => {
    debouncedSave(e);
  };

  const debouncedSave = useCallback(
    debounce((nextValue) => {
      onSearch(nextValue);
    }, 400),
    []
  );

  const onSearch = async (val) => {
    await fetchCustomerData(1, val);
    setSearchParam(val);
  };

  const fetchCustomerData = async (page, searchKey) => {
    const data = await fetchCustomers(page, searchKey);

    if (data && data?.data.length > 0) {
      if (page == 1) {
        setCustomers(data.data);
      } else {
        setCustomers([...customers, ...data.data]);
      }
    }
  };

  const onScroll = async (event) => {
    var target = event.target;
    if (
      !isCustomerLoading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      (!pagingData?.pageSize || pagingData.pageSize * pagingData.current == customers.length)
    ) {
      if (pagingData.total > customers.length) {
        target.scrollTo(0, target.scrollHeight);
        await fetchCustomerData(pagingData.current + 1, searchParam);
      }
    }
  };

  useEffect(() => {
    const response = customers.map((item) => (
      <Select.Option key={Number(item.id)} value={Number(item.id)} email={item?.email}>
        <div className="all-client-name">
          {
            'C' +
              `${
                item?.first_name
                  ? item?.first_name +
                    ' ' +
                    item?.last_name +
                    `${item?.crn ? ' (' + item.crn + ')' : ''}` +
                    `${item?.withdrawal_account === 1 ? ' (Withdrawal)' : ''}`
                  : item?.bussiness_name
                  ? item?.bussiness_name +
                    `${item?.crn ? ' (' + item.crn + ')' : ''}` +
                    `${item?.withdrawal_account === 1 ? ' (Withdrawal)' : ''}`
                  : item?.last_name
                  ? item?.last_name +
                    `${item?.crn ? ' (' + item.crn + ')' : ''}` +
                    `${item?.withdrawal_account === 1 ? ' (Withdrawal)' : ''}`
                  : ''
              }`
          }
        </div>
      </Select.Option>
    ));
    if (isCustomerLoading) {
      response.push(
        <Select.Option key="loading">
          {'Loading'} {'...'}
        </Select.Option>
      );
    }
    setSelectInput([...response]);
  }, [customers, isCustomerLoading]);

  return (
    <MynaSelect
      onSearch={(e) => handleSearchChange(e)}
      loading={isCustomerLoading}
      placeholder={placeholder ? placeholder : 'Select Client Name'}
      onPopupScroll={onScroll}
      formStyleWidth={formStyle}
      onChange={(e) => {
        onCustomerSelectionChange(e, customers);
      }}
      disabled={isLoadingCustomerDetail || disabled}
      selectValueList={
        isCustomerLoading
          ? [
              <Select.Option key="loading">
                {'Loading'} {'...'}
              </Select.Option>
            ]
          : selectInput
      }
      value={customerId}
      defaultValue={customerId}
      formProps={formProps}
      clearable={clearable}
      filterOption={(input, option) => {
        if (isCustomerLoading) {
          return option?.value;
        }
        return (
          (option?.children?.props?.children &&
            option?.children?.props?.children?.includes(String(input)?.toLowerCase()) >= 0) ||
          (option?.email && option?.email?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0)
        );
      }}
    />
  );
};

export default AllClientsSelect;
