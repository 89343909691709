/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import { Button, DatePicker, Input, InputNumber, Select, Spin } from 'antd';
import Form from '@component/Form';
import { API, fileUpload } from '../../config';
import { toast } from 'react-toastify';
import { useState } from 'react';
import UserSelect from '../../components/User/UserSelect';
import moment from 'moment';

const EditAtoRefund = (props) => {
  const { onClose, editAtoData, getProcessData, id } = props;
  const [form] = Form.useForm();
  const [isLoadingCustomerDetail, setIsLoadingCustomerDetail] = useState(false);
  const [customerID, setCustomerId] = useState(
    id ? id : editAtoData?.customer_id ? editAtoData?.customer_id : null
  );

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

  const handleFinish = async (values) => {
    try {
      setIsLoadingCustomerDetail(true);
      values.refund_date = moment(values.refund_date).format('DD-MM-YYYY');

      const formData = new FormData();
      formData.append('customer_id', customerID ? customerID : '0');
      formData.append('refund', values?.amount ? values?.amount : '0');
      formData.append(
        'lodgement_reference',
        values?.lodgement_reference ? values?.lodgement_reference : ''
      );

      formData.append('refund_date', values?.refund_date ? values?.refund_date : '');
      formData.append('year', values?.year ? values?.year : '');
      formData.append('note', values?.note ? values?.note : '');
      formData.append('_method', 'PUT');

      const response = await fileUpload(`${API.UPDATE_ATO}/${editAtoData?.ato?.id}`, formData, {});
      if (response?.status === 200) {
        response?.data?.message && toast.success(response?.data?.message);
        setIsLoadingCustomerDetail(false);
        onClose();
        id ? getProcessData(id) : getProcessData();
      }
      setIsLoadingCustomerDetail(false);
    } catch (error) {
      setIsLoadingCustomerDetail(false);
      const { data } = error.response;
      if (data) {
        toast.error(data?.message);
        toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  // const thisYear = new Date().getFullYear();
  const thisYear =
    new Date().getMonth() >= 6 // Months are zero-indexed, so 6 is July
      ? new Date().getFullYear()
      : new Date().getFullYear() - 1;
  const minOffset = 0;
  const maxOffset = 20;

  const yearOptions = useMemo(() => {
    const options = [];
    for (let i = minOffset; i <= maxOffset; i++) {
      const year = thisYear - i;
      if (i < 5) {
        let yearOther = year + ' Other';
        options.push(<Select.Option value={yearOther}>{yearOther}</Select.Option>);
        let yearB = year + 'B';
        options.push(<Select.Option value={yearB}>{yearB}</Select.Option>);
        let yearA = year + 'A';
        options.push(<Select.Option value={yearA}>{yearA}</Select.Option>);
      }
      options.push(<Select.Option value={year}>{year}</Select.Option>);
    }
    return options;
  }, [thisYear]);

  const handleSearchCustomerValue = async (customerId) => {
    setCustomerId(customerId);
  };

  const onSelect = (value) => {
    handleSearchCustomerValue(value);
  };

  return (
    <div className="content-details">
      <Spin spinning={isLoadingCustomerDetail}>
        <Form
          form={form}
          name="addTaxRefundnForm"
          initialValues={{
            name: editAtoData?.ato?.name,
            tfn: editAtoData?.ato?.tfn,
            year: editAtoData?.ato?.year,
            amount: editAtoData?.ato?.refund,
            lodgement_reference: editAtoData?.ato?.lodgement_reference,
            refund_date: editAtoData?.ato?.batch
              ? editAtoData?.ato?.batch?.import_date
                ? moment(new Date(editAtoData?.ato?.batch?.import_date), 'DD-MM-YYYY')
                : moment(editAtoData?.ato?.created_at, 'DD-MM-YYYY')
              : moment(editAtoData?.ato?.created_at, 'DD-MM-YYYY'),
            note: editAtoData?.ato?.note ? editAtoData?.ato?.note : ''
          }}
          layout="vertical"
          onFinish={handleFinish}
        >
          <div className="entry-card full-card">
            <div className="form-box edit-ato-form">
              <Form.Item label="Name" name="name" className="half-width">
                <UserSelect
                  clearable={false}
                  customerId={Number(customerID)}
                  formStyle={{ width: 252 }}
                  onCustomerSelectionChange={onSelect}
                  disabled={true}
                />
              </Form.Item>
              <Form.Item label="TFN" name="tfn" className="half-width">
                <Input placeholder="TFN" disabled />
              </Form.Item>
              <Form.Item
                label="Year"
                name="year"
                className="half-width"
                rules={[
                  {
                    required: true,
                    message: 'Please select year'
                  }
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="Select Year"
                  onChange={(e) => {
                    form.setFieldsValue({ year: e });
                    if (e?.includes('Other')) {
                      form.setFieldsValue({ note: 'Interest, BAS, HELP or Other' });
                    } else {
                      form.setFieldsValue({
                        note: editAtoData?.ato?.note ? editAtoData?.ato?.note : ''
                      });
                    }
                  }}
                >
                  {yearOptions}
                </Select>
              </Form.Item>
              <Form.Item
                label="ATO Refund Amount"
                name="amount"
                className="half-width"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Amount'
                  }
                ]}
              >
                <InputNumber
                  placeholder="ATO Refund Amount"
                />
              </Form.Item>
              <Form.Item
                label="Lodgement Reference Number"
                name="lodgement_reference"
                className="half-width"
              >
                <Input placeholder="Lodgement Reference Number" />
              </Form.Item>
              <Form.Item
                className="half-width"
                label="Refund Date"
                name="refund_date"
              >
                <DatePicker
                  style={{ border: '1px solid #d9d9d9', borderRadius: '30px', width: '100%' }}
                  format={['DD-MM-YYYY', 'DD/MM/YYYY']}
                  placeholder="DD-MM-YYYY"
                  disabledDate={disabledDate}
                  allowClear={false}
                  disabled={true}
                />
              </Form.Item>
              <Form.Item
                label="Note"
                name="note"
                className="half-width"
                rules={[
                  {
                    pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                    message:
                      'Note can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.'
                  }
                ]}
              >
                <Input placeholder="Note" />
              </Form.Item>
            </div>
          </div>
          <div className="action-box">
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default EditAtoRefund;
