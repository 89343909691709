import React, { useState } from 'react';
import { Select } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Table, Input } from 'antd';
import Footer from '../../../components/Footer/Index.js';
import BackButton from '../../../components/Form/BackButton.js';
import useAdminUserList from './useAdminUserList.js';
import { getTableColumns } from './AdminUserConsoleHelper.js';
import '../../../App.css';
import '../../../assets/css/style.css';
import './AdminUsers.module.css';
import { PaginationItemRenderer } from '../../../shared/PaginationItemRenderer.js';
import DialogModal from '../../../components/Header/DialogModal.js';
import { helpCenterArray } from '../../HelpCenter.js';
import { defaultPaginationLimit } from '../../../config.js';
import { SearchOutlined } from '@ant-design/icons';

const AdminUserConsole = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const search = query.get('search');
  const currentPage = query.get('page');
  const [limit, setLimit] = useState(
    query.get('limit') ? query.get('limit') : defaultPaginationLimit
  );
  const {
    adminUsers,
    fetchAdminUsers,
    onChange,
    searchParam,
    isDataLoading,
    pagingData,
    onPageChange
  } = useAdminUserList(currentPage, search);

  const handleDelete = () => {
    fetchAdminUsers();
  };

  const handleChange = async (value) => {
    setLimit(value);
    navigate(`/admin/users?page=1&tab=statement&limit=${value}`, {
      replace: true
    });
    fetchAdminUsers(1, value);
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block admin-list-table">
          <h1 className="page-title">
            <BackButton />
            Users{' '}
            <DialogModal
              title="Dashboard"
              p1={helpCenterArray[36].name}
              p2={helpCenterArray[36].text}
            />
          </h1>
          <div className="content-details">
            <div className="table-top-btn">
              <div className="">
                <Input
                  style={{ width: '200%' }}
                  onChange={(e) => onChange(e)}
                  suffix={<SearchOutlined />}
                  value={searchParam}
                  placeholder="Search User"
                  allowClear
                />
              </div>
              <div className="table-btn">
                <Link to={{ pathname: '/admin/users/add' }} className="action button">
                  New
                </Link>
              </div>
            </div>
            <div className="user-list" style={{ width: '100%', height: '85%' }}>
              <Table
                rowKey={(record) => record.id}
                columns={getTableColumns(handleDelete)}
                dataSource={adminUsers}
                loading={isDataLoading}
                pagination={{
                  hideOnSinglePage: true,
                  defaultCurrent: 1,
                  showQuickJumper: true,
                  onChange: (page) => onPageChange(page, limit),
                  itemRender: PaginationItemRenderer,
                  ...pagingData,
                  pageSize: limit
                }}
              />
              {adminUsers?.length > 0 && (
                <div className="col-md-12">
                  Showing&nbsp;
                  <Select
                    defaultValue={`${defaultPaginationLimit}`}
                    value={query.get('limit') ? query.get('limit') : `${defaultPaginationLimit}`}
                    onChange={handleChange}
                    options={[
                      { value: '10', label: '10' },
                      { value: '25', label: '25' },
                      { value: '50', label: '50' },
                      { value: '100', label: '100' }
                    ]}
                  />
                  &nbsp;Records per page I Showing {pagingData?.from} to&nbsp;
                  {pagingData?.to} from {pagingData?.total} Records
                </div>
              )}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default AdminUserConsole;
