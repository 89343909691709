import React, { useState } from 'react';
import { Button, Col, Container, Row, Modal } from 'react-bootstrap';
import AddATORefund from '../ato/AddATORefund';
import AddTaxRefund from '../tax/AddTaxRefund';

const EmptyProcessRefundCard = ({
  customerData,
  getProcessData,
  setCustomerDetails,
  customerDetails
}) => {
  const [show, setShow] = useState(false);
  const [showTaxPopup, setShowTaxPopup] = useState(false);

  const handleClose = () => setShow(false);
  const handleTaxPopupClose = () => {
    setShowTaxPopup(false);
    getProcessData(customerData?.id);
  };
  return (
    <div className="refund-process-box">
      <Container>
        <Row className="refund-top-details">
          <Col xs={6} md={2} className="refund-checkbox"></Col>
          <Col xs={6} md={2}>
            <strong>Account Name</strong> {customerData?.first_name} {customerData?.last_name}
          </Col>
          <Col xs={6} md={2}>
            <strong>CRN</strong>
            {customerData?.crn ? customerData?.crn : ''}
          </Col>
          <Col xs={6} md={2}>
            <strong>BSB</strong>
            {customerData?.customer_bank?.bsb}
          </Col>
          <Col xs={6} md={2}>
            <strong>Account Number</strong>{' '}
            {customerData?.customer_bank?.account_number
              ? customerData?.customer_bank?.account_number?.toString()
              : ''}
          </Col>
        </Row>
        <Container>
          <Row className="refund-card-box">
            <Col xs={6} md={5}>
              <div className="refund-card">
                <label>ATO Refund </label>
                <div className="refund-card-content">
                  <span>
                    No ATO Refund entry available for{' '}{customerData?.first_name}{' '}
                    {customerData?.last_name}{' '}
                    <a style={{ color: '#0077C7', fontSize: '14px' }} onClick={() => setShow(true)}>
                      Click here
                    </a>{' '}
                    to Create new ATO Refund entery manually
                  </span>
                </div>
              </div>
            </Col>
            <Col xs={6} md={2} className="matching-btn-section">
              <Button
                variant="secondary"
                className="matching-btn"
                size="sm"
                disabled
                onClick={() => {}}
              >
                Match
              </Button>
            </Col>
            <Col xs={6} md={5}>
              <div className="refund-card">
                <label>Tax Software/Invoice Entry</label>
                <div className="refund-card-content">
                  <span>
                    No Tax Refund entry available&nbsp;{customerData?.first_name}{' '}
                    {customerData?.last_name}{' '}
                  </span>
                  <span>
                    <a
                      style={{ color: '#0077C7' }}
                      onClick={() => {
                        if (
                          customerData &&
                          customerData !== null &&
                          Object.keys(customerData).length
                        ) {
                          setCustomerDetails({
                            customerId: customerData?.id,
                            customerFirstName: customerData?.first_name,
                            customerLastName: customerData?.last_name
                          });
                        }
                        setShowTaxPopup(true);
                      }}
                    >
                    </a>{' '}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>ATO Refund</Modal.Header>
        <Modal.Body>
          <AddATORefund
            fetchATORefundList={() => getProcessData(customerData?.id)}
            setShow={setShow}
            onClose={handleClose}
            customerId={customerData?.id}
          />
          <Button variant="secondary" className="reset" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Body>
      </Modal>
      <Modal
        show={showTaxPopup}
        onHide={handleTaxPopupClose}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>Tax Software</Modal.Header>
        <Modal.Body>
          <AddTaxRefund
            customerId={customerData?.id}
            customerDetails={customerDetails}
            onClose={handleTaxPopupClose}
          />
          <Button variant="secondary" className="reset" onClick={handleTaxPopupClose}>
            Cancel
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EmptyProcessRefundCard;
