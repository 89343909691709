import React, { useState } from 'react';
import ImgCrop from 'antd-img-crop';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Input, Select, Upload } from 'antd';
import { toast } from 'react-toastify';
import Form from '@component/Form';
import Footer from '../../components/Footer/Index';
import CancelButton from '../../components/Form/CancelButton';
import BackButton from '../../components/Form/BackButton';
import SidebarNav from '../../components/Header/SidebarNav';
import { API, fileUpload } from '../../config.js';
import './users.module.css';
import { useOfficeList, useRoleList } from './UserConsoleHelper';
import InputMask from 'react-input-mask';
import faqicon from '../../assets/images/bulb-icon.jpg';

const getSrcFromFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.originFileObj);
    reader.onload = () => resolve(reader.result);
  });
};

const UserAdd = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [fileList, setFileList] = useState([]);
  const { officeDataLoading, officeList = [] } = useOfficeList();
  const { roleDataLoading, roleList = [] } = useRoleList();


  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };

  const dummyRequest = ({ file, onSuccess }) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setFileList([
        {
          uid: new Date().getMilliseconds(),
          name: file.name,
          status: 'done',
          url: reader.result,
          file: file
        }
      ]);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const handleFinish = async (values) => {
    try {
      const formData = new FormData();
      !values?.phone && delete values?.phone;
      if (fileList[0]?.file) formData.append('profile_pic', fileList[0]?.file);
      Object.keys(values).forEach((key) => {
        formData.append([key], values[key]);
      });

      const { data } = await fileUpload(API.CREATE_USER, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      toast.success(data.message);
      navigate(-1);
    } catch (e) {
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const props = {
    name: 'file',
    multiple: false,
    fileList,
    listType: 'picture',
    showUploadList: {
      showRemoveIcon: false
    }
  };

  function onSearch() {}

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <SidebarNav />
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            New User{' '}
            <Link to={{ pathname: '/help' }}>
              <img src={faqicon} />
            </Link>
          </h1>
          <div className="content-details">
            <div className="entry-section">
              <Form form={form} name="loginForm" onFinish={handleFinish} layout="vertical">
                <div className="entry-card full-card">
                  <h3>Personal Details</h3>
                  <div className="form-box">
                    <div className="image-box">
                      <ImgCrop grid>
                        <Upload
                          {...props}
                          customRequest={(f) => dummyRequest(f)}
                          onChange={onChange}
                          onPreview={onPreview}
                        >
                          Edit
                        </Upload>
                      </ImgCrop>
                    </div>
                    <div className="input-boxes">
                      <Form.Item
                        label="First Name"
                        name="first_name"
                        validateTrigger={['onBlur']}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter First name'
                          },
                          {
                            max: 32,
                            message: 'Must be less than 32 character'
                          },
                          {
                            pattern: new RegExp(/^[a-zA-Z\s]*$/),
                            message: 'First name can contain letters & spaces.'
                          }
                        ]}
                      >
                        <Input placeholder="First Name" />
                      </Form.Item>
                      <Form.Item
                        label="Last Name"
                        name="last_name"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter Last name'
                          },
                          {
                            pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,&']*$/),
                            message:
                              "Last name can contain letters, numbers, ampersand(&), dot(.), comma(,), Apostrophe(') & spaces."
                          },
                          {
                            max: 32,
                            message: 'Must be less than 32 character'
                          }
                        ]}
                      >
                        <Input placeholder="Last Name" />
                      </Form.Item>
                      <Form.Item
                        label="Phone Number"
                        name="phone"
                        validateTrigger={['onBlur']}
                      >
                        <InputMask
                          mask="99 9999 9999"
                          name="phone"
                          placeholder="12 1234 1234"
                          className="ant-input"
                        />
                      </Form.Item>
                      <Form.Item
                        label="Email"
                        name="email"
                        validateTrigger={['onBlur']}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter Email'
                          },
                          {
                            type: 'email',
                            message: 'The input is not valid E-mail!'
                          }
                        ]}
                      >
                        <Input placeholder="Email" />
                      </Form.Item>
                      <Form.Item
                        label="Office"
                        name="office_id"
                        rules={[
                          {
                            required: true,
                            message: 'Please select Office'
                          }
                        ]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          onSearch={onSearch}
                          loading={officeDataLoading}
                          placeholder="Please select Office"
                        >
                          {officeList.map((ofc) => (
                            <Select.Option key={ofc.id} value={ofc.id}>
                              {ofc.street}, {ofc.office_suburb}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="Role"
                        name="role_id"
                        rules={[
                          {
                            required: true,
                            message: 'Please select Role'
                          }
                        ]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          loading={roleDataLoading}
                          placeholder="Please select Role"
                        >
                          {roleList.map((role) => (
                            <Select.Option key={role.id} value={role.id}>
                              {role.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="action-box">
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                  <CancelButton />
                </div>
              </Form>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default UserAdd;
