import React, { useEffect, useState } from 'react';
import { Select, Spin, Input } from 'antd';
import '../../App.css';
import '../../assets/css/style.css';
import './customer.module.css';
import { Button, Form, Modal, DropdownButton, Dropdown } from 'react-bootstrap';
import { SearchOutlined } from '@ant-design/icons';
import SidebarNav from '../../components/Header/SidebarNav';
import BackButton from '../../components/Form/BackButton';
import Footer from '../../components/Footer/Index';
import DialogModel from '../../components/Header/DialogModal';
import { useNavigate, useLocation } from 'react-router-dom';
import NestedTable from '../../components/NestedTable';
import useOnlyCustomerList from './useOnlyCustomerList';
import { expandedRowColumns, mainTableColumns, processNestedTableData } from './CustomersHelper';
import { API, defaultPaginationLimit, fileUpload, get } from '../../config';
import { toast } from 'react-toastify';
import { PaginationItemRenderer } from '../../shared/PaginationItemRenderer';
import UploadFile from '../../components/UploadFile';
import { fetchSalesForceData } from '../general-setting/Integration/IntegrationHelper';
import { helpCenterArray } from '../HelpCenter';
import Salesforce from '../../assets/images/salesforce-logo.png';
import XeroLogo from '../../assets/images/xero-logo.png';

const CustomerList = () => {
  const location = useLocation();
  const [initialExpandedData, setInitialExpandedData] = useState([]);
  const [isImportLoading, setIsImportLoading] = useState(false);
  const [initialExpandedRows, setInitialExpandedRows] = useState([]);
  const [files, setFiles] = useState([]);
  const selectedId = localStorage.getItem('selectedId');
  const selectedMore = localStorage.getItem('selectedMore');
  const isFromSame = localStorage.getItem('isFromSame');
  const query = new URLSearchParams(location.search);
  const [limit, setLimit] = useState(
    query.get('limit') ? query.get('limit') : defaultPaginationLimit
  );
  const search = query.get('search');
  const navigate = useNavigate();
  const sortField = query.get('field') || 'date';
  const sortType = query.get('type') || 'desc';
  const currentPage = query.get('page') ? parseInt(query.get('page')) : 1;
  const [sortValue, setSortValue] = useState(
    sortField && sortType ? `${sortField},${sortType}` : 'date,desc'
  );
  const {
    customersList,
    fetchCustomers,
    onSearchChange,
    searchParam,
    isCustomerLoading,
    pagingData,
    onPageChange,
    setSearchParam,
    setIsCustomerLoading
  } = useOnlyCustomerList(currentPage, search, selectedId, limit, sortValue);
  const [showImport, setShowImport] = useState(false);
  const [expandedTotalData, setExpandedTotalData] = useState();
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [expandedCurrentPage, setExpandedCurrentPage] = useState();
  const [expandedPageSize, setExpandedPageSize] = useState();
  const [salesforceData, setSalesforceData] = useState(null);
  const [xeroData, setXeroData] = useState(null);
  const [isImportFromSF, setIsImportFromSF] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [XPMLoading, setXPMLoading] = useState(false);

  const [isImportFromXero, setIsImportFromXero] = useState(false);
  const [isTenantData, setIsTenantData] = useState(false);
  const [tenantData, setTenantData] = useState([]);
  const [tenantId, setTenantId] = useState();
  const xeroCode = query.get('code');

  function onSearch() {}

  const handleTenantModal = () => {
    setTenantId();
    setIsTenantData(!isTenantData);
  };

  useEffect(() => {
    async function fetchData() {
      if (xeroCode) {
        try {
          const formData = new FormData();
          formData.append('code', xeroCode ? xeroCode : '');
          const { data } = await fileUpload(`${API.XERO_OAUTHREDIRECT}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          });
          if (data?.status === 200) {
            navigate(`${API.GET_CUSTOMERLIST}?search=&page=1`, {
              replace: true
            });
            try {
              const response = await fileUpload(`${API.XERO_REFRESH}`, {});
              if (response?.data?.status === 200) {
                return null;
              }
            } catch (e) {
              try {
                const response = await fileUpload(`${API.XERO_REFRESH}`, {});
                if (response?.data?.status === 200) {
                  return null;
                }
              } catch (error) {
                toast.error('Something went wrong');
              }
            }
          }
        } catch (e) {
          const { data } = e.response;
          if (data) {
            toast.error(data?.message);
            toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
          }
        }
      }
    }
    fetchData();
  }, [xeroCode]);

  const handleDelete = () => {
    fetchCustomers();
  };

  const getCustomerRefundHistory = async (id) => {
    try {
      const { data } = await get(`${API.CUSTOMER_REFUND_HISTORY}/` + id);
      if (data?.data?.data) {
        setExpandedCurrentPage(data.data?.current_page);
        setExpandedTotalData(data.data?.total);
        setExpandedPageSize(data.data?.per_page);
        return processNestedTableData(data?.data?.data);
      }
      return [];
    } catch (error) {
      const { data } = error.response;
      toast.error(error?.response?.message);
      if (data) {
        toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      } else {
        toast.error('Something went wrong.');
      }
    }
  };

  const handleUpload = async () => {
    if (files && files.length) {
      setIsImportLoading(true);
      const formData = new FormData();
      files.forEach((file) => {
        if (file !== undefined) {
          formData.append('customer_file', file.originFileObj);
        }
      });
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      fileUpload(API.IMPORT_CUSTOMER, formData, config)
        .then((response) => {
          if (response.status === 200) {
            setIsImportLoading(false);
            toast.success(response.data.message);
            fetchCustomers();
            handleImportClose();
          }
        })
        .catch((err) => {
          const { data } = err.response;
          setIsImportLoading(false);
          toast.error(data?.message);
          toast.error(data.errors && data.errors.customer_file && data.errors.customer_file[0]);
          toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
          var error = {};
          error['myna_error'] = 'There is some issue with request, please try after some time.';
        });
    }
  };

  const handleImportClose = () => {
    setShowImport(false);
    setIsImportLoading(false);
    setFiles([]);
  };

  const handleFileChange = (info) => {
    setFiles(info.fileList);
  };

  const handleDownload = () => {
    setIsLoadingDownload(true);
    get(API.DOWNLOAD_CUSTOMER)
      .then((result) => {
        if (result.status === 200) {
          const { data } = result.data;
          var a = document.createElement('a');
          a.href = data.file;
          a.download = 'customer.xls';
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove();
        }
        setIsLoadingDownload(false);
      })
      .catch((error) => {
        const { data } = error.response;
        setIsLoadingDownload(false);
        toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      });
  };
  useEffect(() => {
    if (selectedMore) {
      localStorage.removeItem('selectedMore');
      setSearchParam(search);
    }
    if (isFromSame) {
      localStorage.removeItem('isFromSame');
      fetchCustomers(pagingData.current, '', limit);
    }
    if (selectedId && !initialExpandedRows.length) {
      if (location?.state?.path?.includes('customer')) {
        fetchCustomers(pagingData.current, selectedId, limit);
      }
      handleInitialTable(selectedId);
    }
  }, [search, selectedId, selectedMore]);

  const handleInitialTable = async (id) => {
    const data = await getCustomerRefundHistory(id);
    setInitialExpandedData(data);
    const parsedId = parseInt(selectedId);
    setInitialExpandedRows([parsedId]);
    localStorage.removeItem('selectedId');
  };

  const handleImportModal = async () => {
    try {
      setIsCustomerLoading(true);
      const response = await fetchSalesForceData();
      setIsCustomerLoading(false);
      setSalesforceData(response?.sf_integration_data);
      setXeroData(response?.xero_integration_data);
    } catch (e) {
      setIsCustomerLoading(false);
    } finally {
      setIsCustomerLoading(false);
      setShowImport(true);
    }
  };


  const handleSFModal = () => {
    setIsImportFromSF(!isImportFromSF);
  };

  const handleSFImport = async () => {
    try {
      setIsSyncing(true);
      const response = await get(`${API.SYNC_SF_CONTACTS}?domain=${window.location.origin}`, {
        timeout: 1000 * 10
      });
      if (response?.data?.message) {
        toast.success(response.data.message);
      }
      fetchCustomers();
    } catch (e) {
      toast.error(e.response?.data?.myna_error);
    } finally {
      setIsSyncing(false);
      handleSFModal();
      setShowImport(false);
    }
  };

  const handleXeroModal = () => {
    setIsImportFromXero(!isImportFromXero);
  };

  const handlexero = async () => {
    try {
      setIsCustomerLoading(true);
      const response = await fileUpload(`${API.XERO_REFRESH}`, {});
      setIsCustomerLoading(false);
      if (response?.data?.status === 200) {
        try {
          setIsCustomerLoading(true);
          const response = await get(`${API.XERO_TENANTS}`);
          setIsCustomerLoading(false);
          if (response?.data?.status === 200) {
            toast.success(response?.data?.message);
            setIsTenantData(true);
            setTenantData(response?.data?.data);
          }
        } catch (error) {
          setIsCustomerLoading(false);
          handleXeroModal();
          toast.error(error?.message);
          toast.error(error.errors && error.errors.myna_error && error.errors.myna_error[0]);
        }
      }
    } catch (e) {
      setIsCustomerLoading(false);
      try {
        setIsCustomerLoading(true);
        const response = await fileUpload(`${API.XERO_REFRESH}`, {});
        setIsCustomerLoading(false);
        if (response?.data?.status === 200) {
          try {
            setIsCustomerLoading(true);
            const response = await get(`${API.XERO_TENANTS}`);
            setIsCustomerLoading(false);
            if (response?.data?.status === 200) {
              setIsTenantData(true);
              setTenantData(response?.data?.data);
            }
          } catch (error) {
            setIsCustomerLoading(false);
            handleXeroModal();
            toast.error(error?.message);
            toast.error(error.errors && error.errors.myna_error && error.errors.myna_error[0]);
          }
        }
      } catch (error) {
        setIsCustomerLoading(false);
        handleXeroModal();
        toast.error('Something went wrong');
      }
    }
  };

  const handleSubmiTenantData = async (id) => {
    if (id) {
      try {
        setIsCustomerLoading(true);
        setXPMLoading(true);
        const headers = {
          'xero-tenant-id': id ? id : ''
        };
        const xeroClients = await get(`${API.XERO_CLIENTS}`, { headers: headers });
        setIsCustomerLoading(false);
        setXPMLoading(false);
        if (xeroClients?.data?.status === 200) {
          toast.success(
            <>
              <p>{xeroClients?.data?.message}</p>
              <p>{xeroClients?.data?.data?.message}</p>
            </>
          );
          handleTenantModal();
          handleXeroModal();
          handleImportClose();
          fetchCustomers();
          navigate(`${API.GET_CUSTOMERLIST}?search=&page=1`, {
            replace: true
          });
        }
      } catch (e) {
        setIsCustomerLoading(false);
        handleTenantModal();
        handleXeroModal();
        handleImportClose();
        setXPMLoading(false);
        const errors = e.response?.data?.errors;
        Object.keys(errors).forEach((key) => {
          toast.error(errors[key][0]);
        });
      }
    }
  };

  const handleChange = async (value) => {
    setLimit(value);
    let sortValueData = sortValue.split(',');
    navigate(`/customer?page=1&limit=${value}&field=${sortValueData[0]}&type=${sortValueData[1]}`, {
      replace: true
    });
    fetchCustomers(1, '', value, sortValueData);
  };

  const sortOptions = [
    { title: 'Name (A - Z)', value: 'name,asc' },
    { title: 'Name (Z - A)', value: 'name,desc' },
    { title: 'Date (Oldest)', value: 'date,asc' },
    { title: 'Date (Latest)', value: 'date,desc' }
  ];

  const onSortChange = (value) => {
    setSortValue(value);
    const splitValue = value.split(',');
    navigate(`/customer?field=${splitValue[0]}&type=${splitValue[1]}&limit=${limit}&page=${1}`, {
      replace: true
    });
    fetchCustomers(1, '', limit, splitValue);
  };

  const isLoading = selectedId && !initialExpandedRows.length;
  return (
    <div className="cms-page">
      <div className="page-content-block">
        <SidebarNav />
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            Clients{' '}
            <DialogModel
              title="Clients"
              p1={helpCenterArray[2].name}
              p2={helpCenterArray[2].text}
              p3={helpCenterArray[3].name}
              p4={helpCenterArray[3].text}
              p5={helpCenterArray[4].name}
              p6={helpCenterArray[4].text}
            />
          </h1>
          <div className="content-details">
            <div className="table-top-btn">
              <div className="">
                <Form>
                  <Input
                    style={{ width: '200%' }}
                    onChange={(e) => onSearchChange(e)}
                    suffix={<SearchOutlined />}
                    value={searchParam}
                    placeholder="Search Client"
                    allowClear
                  />
                </Form>
              </div>
              <div className="table-btn">
                <div className="dashboard-pager">
                  <div style={{ marginRight: '20px' }}>
                    Sort By:
                    <Select
                      style={{ marginLeft: '10px' }}
                      defaultValue="name,asc"
                      onSelect={onSortChange}
                      value={sortValue}
                    >
                      {sortOptions.map((ele) => (
                        <Select.Option key={ele.title} value={ele.value}>
                          {ele.title}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <Button variant="primary" onClick={() => navigate('/new-customers')}>
                  New
                </Button>
                <div className="border-btn">
                  <DropdownButton className="info-icon">
                    <Dropdown.Item>
                      <p>First Name - Optional</p>
                      <p>Last Name - Required</p>
                      <p>Email Address - Required</p>
                      <p>TFN - Required</p>
                      <p>ABN - Optional, 11 Digit</p>
                      <p>BSB - Optional, 6 Digit</p>
                      <p>Account Number - Optional, 9 Digit</p>
                    </Dropdown.Item>
                  </DropdownButton>
                  <Button
                    variant="link"
                    disabled={isLoadingDownload}
                    onClick={() => handleDownload()}
                    className="download-template"
                  >
                    Download Template
                  </Button>
                  <Button variant="primary" onClick={handleImportModal}>
                    Import
                  </Button>
                </div>
                <Button
                  variant="primary"
                  onClick={() => navigate('/import-logs?tab=imported&search=')}
                >
                  Import Logs
                </Button>
              </div>
            </div>

            <div className="customer-table">
              {isLoading ? (
                <Spin />
              ) : (
                <>
                  <NestedTable
                    rowKey={(record) => record.id}
                    expandedRowColumns={expandedRowColumns}
                    mainTableColumns={mainTableColumns(handleDelete)}
                    getExpandedRowData={getCustomerRefundHistory}
                    loading={isCustomerLoading && !XPMLoading}
                    mainTableData={customersList}
                    expandedCurrentPage={expandedCurrentPage}
                    expandedTotalData={expandedTotalData}
                    expandedPageSize={expandedPageSize}
                    initialExpandedRows={initialExpandedRows}
                    initialExpandedData={initialExpandedData}
                    pagination={{
                      hideOnSinglePage: true,
                      defaultPageSize: pagingData.pageSize,
                      defaultCurrent: 1,
                      showQuickJumper: true,
                      itemRender: PaginationItemRenderer,
                      onChange: (page) => onPageChange(page, limit, sortValue),
                      ...pagingData
                    }}
                  />
                  <div>
                    {customersList?.length > 0 && (
                      <div className="col-md-12">
                        Showing&nbsp;
                        <Select
                          defaultValue={`${defaultPaginationLimit}`}
                          value={
                            query.get('limit') ? query.get('limit') : `${defaultPaginationLimit}`
                          }
                          onChange={handleChange}
                          options={[
                            { value: '10', label: '10' },
                            { value: '25', label: '25' },
                            { value: '50', label: '50' },
                            { value: '100', label: '100' }
                          ]}
                        />
                        &nbsp;Records per page I Showing {pagingData?.from} to&nbsp;
                        {pagingData?.to} from {pagingData?.total} Records
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          <Modal
            show={showImport}
            onHide={handleImportClose}
            dialogClassName="modal-50w small-popup"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>Import</Modal.Header>
            <Modal.Body>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexDirection: 'column'
                }}
              >
                <UploadFile
                  isMultiple={true}
                  isLoading={isImportLoading}
                  handleFileChange={handleFileChange}
                  handleUpload={handleUpload}
                  handleCancel={handleImportClose}
                />
                {salesforceData?.connection_status === 1 || xeroData?.connection_status === 1 ? (
                  <>
                    <p className="or">OR</p>
                    <h5>Import from : </h5>
                    <span className="salesforce-and-xero">
                      {salesforceData?.connection_status === 1 && (
                        <img src={Salesforce} onClick={handleSFModal} title="Salesforce"></img>
                      )}

                      {salesforceData?.connection_status === 1 &&
                        xeroData?.connection_status === 1 && <h5>OR</h5>}

                      {xeroData?.connection_status === 1 && (
                        <img src={XeroLogo} onClick={handleXeroModal} title="Xero"></img>
                      )}
                    </span>
                  </>
                ) : (
                  ''
                )}
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            show={isImportFromSF}
            onHide={handleSFModal}
            dialogClassName="modal-50w small-popup"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>Confirmation!</Modal.Header>
            <Modal.Body>
              <div className="remove-content confirmation-import">
                <p>Do you want to import/update client records from Saleforce?</p>
                <div className="modal-action">
                  <Button className="cancel" disabled={isSyncing} onClick={handleSFModal}>
                    <Spin spinning={isSyncing} />
                    Cancel
                  </Button>
                  <Button disabled={isSyncing} onClick={handleSFImport}>
                    <Spin spinning={isSyncing} />
                    OK
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            show={isImportFromXero}
            onHide={handleXeroModal}
            dialogClassName="modal-50w small-popup"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>Confirmation!</Modal.Header>
            <Modal.Body>
              <div className="remove-content confirmation-import">
                <p>Do you want to import/update client records from Xero?</p>
                <div className="modal-action">
                  <Button className="cancel" onClick={handleXeroModal}>
                    Cancel
                  </Button>
                  <Button onClick={handlexero}>OK</Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            show={isTenantData}
            onHide={handleTenantModal}
            dialogClassName="modal-50w small-popup"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="xpm-modal"
          >
            <Modal.Header></Modal.Header>
            <Modal.Body>
              <Spin
                spinning={XPMLoading}
                tip={
                  <span>
                    <p>Please wait...</p>
                    <p>We are importing clients data from XPM.</p>
                  </span>
                }
              >
                <div className="remove-content tenant" style={{ textAlign: 'center' }}>
                  <p>Please select business</p>
                  <Select
                    showSearch={false}
                    optionFilterProp="children"
                    onSearch={onSearch}
                    onChange={(e) => {
                      setTenantId(e);
                    }}
                    placeholder="Please select Business"
                  >
                    {tenantData &&
                      tenantData?.length > 0 &&
                      tenantData.map((type) => (
                        <Select.Option key={type.tenantId} value={type.tenantId}>
                          {type.tenantName}
                        </Select.Option>
                      ))}
                  </Select>
                  <div className="modal-action">
                    <Button className="cancel" onClick={handleTenantModal}>
                      Cancel
                    </Button>
                    <Button
                      disabled={tenantId ? false : true}
                      onClick={() => {
                        handleSubmiTenantData(tenantId);
                      }}
                    >
                      OK
                    </Button>
                  </div>
                </div>
              </Spin>
            </Modal.Body>
          </Modal>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default CustomerList;
