import React, { useCallback } from 'react';
import { Input, Button, Select } from 'antd';
import { toast } from 'react-toastify';
import Form from '@component/Form';
import { post } from '../../../config.js';

const { TextArea } = Input;
const { Option } = Select;

const AddEditChartAccount = ({ activeTab, onClose, chart }) => {
  const [form] = Form.useForm();
  const isEdit = chart?.id;

  const accountTypes = [
    { title: 'Expenses', value: 1 },
    { title: 'Revenue', value: 2 },
    { title: 'Current Assets', value: 3 },
    { title: 'Non-Current Assets', value: 4 },
    { title: 'Current Liabilities', value: 5 },
    { title: 'Non Current Liabilities', value: 6 },
    { title: 'Equity', value: 7 }
  ];

  const handleError = (errors) => {
    Object.keys(errors).forEach((key) => {
      errors[key].forEach((error) => {
        toast.error(error);
      });
    });
  };

  const handleSubmit = async (values) => {
    try {
      const url = isEdit ? `/chart-account/${chart.id}` : `/chart-account/store`;
      const reqObj = isEdit ? { ...values, _method: 'PUT' } : { ...values };
      const { data } = await post(url, reqObj);
      if (data.status === 200) {
        onClose();
        toast.success(data.message);
      } else {
        handleError(data.errors);
      }
    } catch (e) {
      const errors = e.response?.data?.errors;
      handleError(errors);
    }
  };

  function onSearch() {}

  const getChartType = useCallback(() => {
    if (chart) {
      if (chart.type) return parseInt(chart.type);
      else return 0;
    } else {
      if (['2', '7', '6', '5'].indexOf(activeTab) === -1) return 0;
      else return 1;
    }
  }, [chart?.type, activeTab]);

  const getAccountType = useCallback(() => {
    if (chart) {
      console.log('chart.type', chart.account_type_id);
    } else {
      console.log('activeTab', activeTab);
    }
  }, [chart?.account_type_id, activeTab]);

  return (
    <div className="content-details">
      <Form
        form={form}
        initialValues={{
          account_type_id: getAccountType(),
          type: getChartType(),
          tax: chart?.tax ? parseInt(chart.tax) : 0,
          ...chart
        }}
        name="chartForm"
        onFinish={handleSubmit}
        layout="vertical"
      >
        <div className="input-boxes">
          <Form.Item
            className="full-width"
            label="Account Type"
            name="account_type_id"
            rules={[
              {
                required: true,
                message: 'Please select Account Type'
              }
            ]}
          >
            <Select
              optionFilterProp="children"
              placeholder="Please select Account Type"
            >
              {accountTypes.map((ele, idx) => (
                <Select.Option key={idx} value={ele.value}>
                  {ele.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className="full-width"
            label="Type"
            name="type"
            validateTrigger={['onBlur']}
            rules={[
              {
                required: true,
                message: 'Please select type'
              }
            ]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              onSearch={onSearch}
              placeholder="Select Type"
              allowClear
            >
              <Option value={0}>Debit</Option>
              <Option value={1}>Credit</Option>
            </Select>
          </Form.Item>

          <Form.Item
            className="full-width"
            label="Tax"
            name="tax"
            validateTrigger={['onBlur']}
            rules={[
              {
                required: true,
                message: 'Please select tax type'
              }
            ]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              onSearch={onSearch}
              placeholder="Select Tax Type"
              allowClear
            >
              <Option value={0}>GST Excluded</Option>
              <Option value={1}>
                GST {chart?.tax === 1 && chart?.gst_percentage > 0 ? chart?.gst_percentage : '10'}%
              </Option>
            </Select>
          </Form.Item>

          <Form.Item
            className="full-width"
            label="Name"
            name="name"
            validateTrigger={['onBlur']}
            rules={[
              {
                required: true,
                message: 'Please enter Name'
              },
              {
                pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                message: `Name can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphen(-), slash(/) & spaces.`
              }
            ]}
          >
            <Input placeholder="Name" name="name" />
          </Form.Item>

          <Form.Item
            className="full-width"
            label="Code"
            name="code"
            validateTrigger={['onBlur']}
            rules={[
              {
                required: true,
                message: 'Please enter Code'
              },
              {
                pattern: new RegExp(/^\d{4}$/),
                message: 'Must be 4 digit number'
              }
            ]}
          >
            <Input placeholder="Code" name="code" type="number" />
          </Form.Item>

          <Form.Item
            className="full-width"
            label="Sub Code"
            name="sub_code"
            validateTrigger={['onBlur']}
            rules={[
              {
                pattern: new RegExp(/^\d{4}$/),
                message: 'Must be 4 digit number'
              }
            ]}
          >
            <Input placeholder="Sub Code" name="sub_code" type="number" />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            className="full-width"
            validateTrigger={['onBlur']}
            rules={[
              {
                required: true,
                message: 'Please enter Description'
              },
              {
                pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                message:
                  'Description can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.'
              }
            ]}
          >
            <TextArea rows={4} name="description" />
          </Form.Item>
        </div>
        <div className="action-box">
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
};
export default AddEditChartAccount;
