import React, { useState } from 'react';
import { API, fileUpload } from '../../../config';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Spin } from 'antd';
export default function CurrentSubscription(props) {
  const navigate = useNavigate();
  const [cancelSubscriptionLoading, setCancelSubscriptionLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { isLoading, subscriptionDetail } = props;

  const handleonRedirect = () => {
    navigate('/settings?tab=subscription&childTab=more-packages', { replace: true });
  };

  const cancelSubscription = async () => {
    setCancelSubscriptionLoading(true);
    const formData = new FormData();
    formData.append(['subscription_id'], subscriptionDetail.subscription_id);

    await fileUpload(API.CANCEL_SUBSCRIPTION, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(() => {
        setCancelSubscriptionLoading(false);
        navigate('/');
      })
      .catch(() => {
        setCancelSubscriptionLoading(false);
        toast.error('Something went wrong');
      });
  };

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <div className="content-details">
          <div className="entry-card full-card">
            <div className="form-box">
              <div className="address-box firm-address current-subscription-box">
                {subscriptionDetail?.status === undefined ? (
                  <div className="no-sub-body">
                    <h5>You have no Subscription</h5>
                    <Button
                      size="small"
                      type="primary"
                      className="reset sub-now"
                      onClick={handleonRedirect}
                    >
                      Subscribe Now
                    </Button>
                  </div>
                ) : (
                  <>
                    <h5 className="sub-detail-body">
                      Subscription Details
                      <span className="sub-status">
                        Status:{' '}
                        <i className="sub-status-text">
                          {subscriptionDetail?.status === 'trialing'
                            ? 'Free Trial'
                            : subscriptionDetail?.status}
                        </i>
                      </span>
                    </h5>
                    <div className="row col-md-12">
                      {subscriptionDetail?.status === 'canceled' && (
                        <>
                          <div className="col-md-4">
                            <p>
                              <label>Last day to access:</label>{' '}
                              {subscriptionDetail?.next_payment_date}
                            </p>
                          </div>
                          <div className="col-md-4">
                            <Button type="primary" className="reset" onClick={handleonRedirect}>
                              Subscribe Now
                            </Button>
                          </div>
                        </>
                      )}
                      {subscriptionDetail?.status === 'trialing' && (
                        <>
                          <div className="col-md-4 ml-5">
                            <p>
                              <label>Plan: </label> {subscriptionDetail?.plan_type}
                            </p>
                            <p style={{ margin: '0' }}>
                              <label>Amount: </label> $
                              {subscriptionDetail?.current_plan_price ||
                                subscriptionDetail?.currency}{' '}
                              {subscriptionDetail?.plan_time != ''
                                ? 'per ' + subscriptionDetail?.plan_time
                                : ''}
                            </p>
                          </div>
                          <div className="col-md-4 ml-5">
                            <h6>Subscription Period</h6>
                            <p>
                              <label>Start Date: </label> {subscriptionDetail?.trial_start}
                            </p>
                            <p style={{ margin: '0' }}>
                              <label>End Date: </label> {subscriptionDetail?.trial_end}
                            </p>
                          </div>
                          <div className="col-md-4 subscription-btn">
                            <p>
                              <label>Subscription Renewal Date:</label>{' '}
                              {subscriptionDetail?.next_payment_date}
                            </p>
                            <Button
                              size="small"
                              type="primary"
                              className="reset change-plan-btn"
                              onClick={handleonRedirect}
                            >
                              Change Plan
                            </Button>
                            <Button
                              size="small"
                              type="primary"
                              className="cancel-subscription"
                              onClick={() => setShowModal(true)}
                            >
                              Cancel Plan
                            </Button>
                          </div>
                        </>
                      )}
                      {subscriptionDetail?.status === 'active' && (
                        <>
                          <div className="col-md-4 ml-5">
                            <p>
                              <label>Plan: </label> {subscriptionDetail?.plan_type}
                            </p>
                            <p style={{ margin: '0' }}>
                              <label>Amount: </label> $
                              {subscriptionDetail?.current_plan_price ||
                                subscriptionDetail?.currency}{' '}
                              {subscriptionDetail?.plan_time != ''
                                ? 'per ' + subscriptionDetail?.plan_time
                                : ''}
                            </p>
                          </div>
                          <div className="col-md-4 subscription-btn">
                            <p>
                              <label>Next Subscription Date:</label>{' '}
                              {subscriptionDetail?.next_payment_date}
                            </p>

                            <Button
                              size="small"
                              type="primary"
                              className="reset change-plan-btn"
                              onClick={handleonRedirect}
                            >
                              Change Plan
                            </Button>
                            <Button
                              size="small"
                              type="primary"
                              className="reset"
                              loading={cancelSubscriptionLoading}
                              onClick={() => setShowModal(true)}
                            >
                              Cancel Plan
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
              {showModal && (
                <>
                  <Modal
                    size="lg"
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    dialogClassName="modal-50w medium-popup review-popup cancel-sub-modal"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header closeButton className="cancel-sub-header">
                      Cancel Subscription
                    </Modal.Header>
                    <Modal.Body className="cancel-sub-body">
                      {cancelSubscriptionLoading && <Spin />}
                      <p>Are you sure you want to cancel subscription?</p>
                      <Button className="btn" onClick={() => setShowModal(false)}>
                        No
                      </Button>
                      <Button className="btn" onClick={cancelSubscription}>
                        Yes
                      </Button>
                    </Modal.Body>
                  </Modal>
                </>
              )}
              {subscriptionDetail?.support_plan && (
                <div className="address-box firm-address current-subscription-box">
                  <h5 className="sub-detail-body">
                    Support Plan
                    <span className="sub-status">
                      Status: <i className="sub-status-text">Active</i>
                    </span>
                  </h5>
                  <div className="row col-md-12">
                    <>
                      <div className="col-md-4 ml-5">
                        <div>
                          <label>Plan: </label> {subscriptionDetail?.support_plan}
                        </div>
                      </div>
                      <div className="col-md-4 ml-5">
                        <div style={{ margin: '0' }}>
                          <label>Amount: </label> ${subscriptionDetail?.support_price}
                        </div>
                      </div>
                    </>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
