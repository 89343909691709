import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import {
  Button,
  Input,
  Select,
  Row,
  Col,
  Checkbox
} from 'antd';
import Loginimg from '@src/assets/images/Loginimg.js';
import Sitelogo from '@component/Header/Logo.js';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Form from '@component/Form';
import Authentication from '@component/Authentication';
import Footer from '../../components/Footer/Index';

import { API, fileUpload, post } from '../../config.js';
import './firm.module.css';
import InputMask from 'react-input-mask';
import { useStateList, useSuburbList } from '../customer/CustomersHelper';
import { encrypt } from '../../helper/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';


const FirmSignup = () => {
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const tfa = localStorage.getItem('myna-auth-tfa');
  const hasToken = localStorage.getItem('myna-auth-token');
  const navigate = useNavigate();
  const [authentication] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const [message, setMessage] = useState({ error: '' });
  const [loading, setLoading] = useState(false);


  const [stateId] = useState();
  const { stateLoading, stateList = [] } = useStateList();
  const { suburbLoading, suburbList = [], handleStateSelect } = useSuburbList(stateId);

  const handleFinish = async (values) => {
    try {
      const formData = new FormData();
      values.abn = values?.abn ? `${values?.abn}`.replace(/ /g, '') : '';
      values.email = values['firm_email'] ? values['firm_email'] : '';
      Object.keys(values).forEach((key) => {
        formData.append([key], values[key]);
      });

      const { data } = await fileUpload(API.FIRM_SIGNUP, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      navigate(`/firm/subscription/${encodeURIComponent(encrypt(String(data.data.id)))}`);
      toast.success(data.message);
    } catch (e) {
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  function onSearch() {}

  const loadDynamicScript = (callback) => {
    const existingScript = document.getElementById('xero');
    const script = document.createElement('script');
    script.src = 'https://edge.xero.com/platform/sso/xero-sso.js'; // URL for the third-party library being loaded.
    script.id = 'xero'; // e.g., googleMaps or stripe
    script.defer = 'defer';
    script.async = 'async';
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
    if (existingScript && callback) callback();
    return () => {
      if (existingScript && callback) {
        existingScript.remove();
      }
    };
  };

  const redirectUrl = () => {
    var host = location.protocol.concat('//').concat(window.location.host);
    const attr = document.createAttribute('content');
    attr.value = host + '/firm/signup';
    const existingScript = document.getElementById('xero-url');
    existingScript.setAttributeNode(attr);
  };

  useEffect(() => {
    loadDynamicScript();
    redirectUrl();
  }, []);

  const redirectLogin = () => {
    window.location.href = location.protocol.concat('//').concat(window.location.host);
  };

  useEffect(async () => {
    if (searchParams.get('code')) {
      setLoading(true);
      let code = { code: searchParams.get('code') };
      const response = await post(API.XERO_SIGNUP, code);
      if (response?.data?.status === 200) {
        form.setFieldsValue({ first_name: response?.data?.data?.first_name });
        form.setFieldsValue({ last_name: response?.data?.data?.last_name });
        form.setFieldsValue({ email: response?.data?.data?.email });
        form.setFieldsValue({ business_name: response?.data?.data?.businessData?.business_name });
        form.setFieldsValue({ abn: response?.data?.data?.businessData?.abn });
        form.setFieldsValue({
          tax_agent_number: response?.data?.data?.businessData?.tax_agent_number
        });
        const phone =
          response?.data?.data?.businessData?.phone.length === 9
            ? '0' + response?.data?.data?.businessData?.phone
            : response?.data?.data?.businessData?.phone;
        form.setFieldsValue({ phone: phone });
        form.setFieldsValue({ street: response?.data?.data?.businessData?.street });
        form.setFieldsValue({ postal_code: response?.data?.data?.businessData?.postal_code });
        await form.setFieldsValue({ state_id: response?.data?.data?.businessData?.state_id });
        handleStateSelect(response?.data?.data?.businessData?.state_id);
        form.setFieldsValue({ suburb_id: response?.data?.data?.businessData?.suburb_id });
      }
      setMessage({ error: response.errors?.myna_error[0] });
      setLoading(false);
    }
  }, []);

  useEffect(async () => {
    if (
      searchParams.get('first_name') !== null &&
      searchParams.get('email') !== null &&
      searchParams.get('last_name') !== null
    ) {
      form.setFieldsValue({ first_name: searchParams.get('first_name') });
      form.setFieldsValue({ email: searchParams.get('email') });
      form.setFieldsValue({ last_name: searchParams.get('last_name') });
    }
  }, [searchParams]);

  // const validateTANNumber = async (value) => {
  //   try {
  //     const data = await axios.get(
  //       `https://www.tpb.gov.au/registration-api/list?registration_number=${value}`,
  //       {
  //         headers: {
  //           'Accept': 'application/json',
  //           'Access-Control-Allow-Origin': '*',
  //           'Access-Control-Allow-Methods': '*'
  //         }
  //       }
  //     );
  //     console.log(data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <div className="login-pages firm-login">
      {hasToken && tfa === '2' ? (
        <Navigate to={'/dashboard?field=name&type=asc&filter=weekly'} />
      ) : (
        <></>
      )}
      {!authentication ? (
        <Row>
          <Col className="img-block">
            <div className="login-img">
              <Loginimg />
            </div>
          </Col>
          <Col className="login-form-block" style={{ flex: '1 0', height: '100%' }}>
            <div className="login-form firm-form">
              <Sitelogo />
              <Form form={form} name="loginForm" onFinish={handleFinish} layout="vertical">
                <div className="entry-card full-card">
                  <div className="form-box">
                    <div className="input-boxes">
                      <div className="firm-form-box">
                        <h3>Personal Details</h3>
                        <Form.Item
                          label="First Name"
                          name="first_name"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter First name'
                            },
                            {
                              pattern: new RegExp(/^[a-zA-Z\s]*$/),
                              message: 'First name can contain letters & spaces.'
                            },
                            {
                              max: 32,
                              message: 'Must be less than 32 character'
                            }
                          ]}
                        >
                          <Input placeholder="First Name" />
                        </Form.Item>
                        <Form.Item
                          label="Last Name"
                          name="last_name"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter Last name'
                            },
                            {
                              pattern: new RegExp(/^[a-zA-Z\s]*$/),
                              message: 'Last name can contain letters & spaces.'
                            },
                            {
                              max: 32,
                              message: 'Must be less than 32 character'
                            }
                          ]}
                        >
                          <Input placeholder="Last Name" />
                        </Form.Item>
                        <Form.Item
                          label="Phone Number"
                          name="phone"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter Phone Number'
                            }
                          ]}
                        >
                          <InputMask
                            mask="99 9999 9999"
                            name="phone"
                            placeholder="12 1234 1234"
                            className="ant-input"
                            type="text"
                          />
                        </Form.Item>
                      </div>
                      <div className="firm-form-box">
                        <h3>Accounting Practice Details</h3>
                        <Form.Item
                          label="Practice Name"
                          name="business_name"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter Practice Name'
                            },
                            {
                              pattern: new RegExp(/^[^!@#^%$<>][a-zA-Z\s\d.,&-()]*$/),
                              message: `Practice name can contain letters, numbers, spaces and some special characters like (& ( ) . , - ')`
                            }
                          ]}
                        >
                          <Input placeholder="Practice Name" />
                        </Form.Item>
                        <Form.Item
                          label="Practice Email"
                          name="firm_email"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter Practice Email'
                            },
                            {
                              type: 'email',
                              message: 'The input is not valid E-mail!'
                            }
                          ]}
                        >
                          <Input placeholder="Practice Email" />
                        </Form.Item>
                        <Form.Item
                          label="Street"
                          name="street"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter Street'
                            },
                            {
                              pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                              message: `Street can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphen(-), slash(/) & spaces.`
                            }
                          ]}
                        >
                          <Input placeholder="Street" />
                        </Form.Item>
                        <Form.Item
                          label="Select State"
                          name="state_id"
                          rules={[
                            {
                              required: true,
                              message: 'Please select State'
                            }
                          ]}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            onSearch={onSearch}
                            onChange={handleStateSelect}
                            loading={stateLoading}
                            placeholder="Please select State"
                          >
                            {stateList.map((item, i) => (
                              <Select.Option key={i} value={item.id}>
                                {item.name}
                                {` (${item.short_name})`}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label="Select Suburb"
                          name="suburb_id"
                          rules={[
                            {
                              required: true,
                              message: 'Please select Suburb'
                            }
                          ]}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            onSearch={onSearch}
                            loading={suburbLoading}
                            placeholder="Please select Suburb"
                          >
                            {suburbList.map((item, i) => (
                              <Select.Option key={i} value={item.id}>
                                {item.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label="Post Code"
                          name="postal_code"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter Post Code'
                            },
                            {
                              pattern: new RegExp(/^\d{4}$/),
                              message: 'Must be 4 digit number'
                            }
                          ]}
                        >
                          <Input placeholder="Please enter Postal Code" />
                        </Form.Item>
                        <Form.Item
                          label="ABN"
                          name="abn"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter ABN'
                            }
                          ]}
                        >
                          <InputMask
                            mask="99 999 999 999"
                            name="abn"
                            placeholder="12 123 123 123"
                            className="ant-input"
                            type="text"
                          />
                        </Form.Item>
                        <Form.Item
                          label="Tax Agent Number"
                          name="tax_agent_number"
                          validateTrigger={['onBlur']}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter Tax Agent Number'
                            },
                            {
                              pattern: new RegExp(/^[0-9]+$/),
                              message: 'Tax Agent Number can contain numbers.'
                            },
                            {
                              max: 8,
                              message: 'Digit should be less than 8'
                            }
                          ]}
                        >
                          <Input
                            placeholder="Tax Agent Number"
                            name="tax_agent_number"
                            // onBlur={(e) => {
                            //   if (e.target.value.length <= 10) {
                            //     validateTANNumber(e.target.value);
                            //   }
                            // }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
                <Form.Item className="full-width" name="agree" valuePropName="checked">
                  <Checkbox onChange={(e) => setIsAgree(e.target.checked)}>
                    I agree to the{' '}
                    <a
                      target="_blank"
                      href="https://trusteasy.com.au/terms-conditions/"
                      rel="noreferrer"
                    >
                      {' '}
                      Terms & Condition
                    </a>
                  </Checkbox>
                </Form.Item>
                <Form.Item className="full-width">
                  <p className="back-to-login" style={{ color: '#FFF', fontSize: '14px' }}>
                    Already have an account?{' '}
                    <a style={{ borderBottom: '1px dotted #FFF' }} onClick={redirectLogin}>
                      {' '}
                      Sign In
                    </a>
                  </p>
                  {message.error && <p className="text-danger">{message.error}</p>}
                </Form.Item>

                <div
                  className="action-box"
                  style={{
                    justifyContent: 'center',
                    marginTop: '15px',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '10px',
                    paddingBottom: '20px'
                  }}
                >
                  <Button type="primary" htmlType="submit" disabled={!isAgree} loading={loading}>
                    Create <FontAwesomeIcon icon={faChevronRight} />
                  </Button>
                  <span
                    className="topgap btnarea justify-content-center or-txt"
                    style={{ color: 'white', marginTop: '0px' }}
                  >
                    OR
                  </span>
                  <span
                    data-xero-sso
                    data-label="Sign Up with Xero"
                    data-theme="light"
                    data-size="large"
                    data-border-radius="20"
                  ></span>
                </div>
                <div>
                  <p style={{ width: '100%', color: '#FFF', fontSize: '14px' }}>
                    If you face any difficulties in the signup process, please contact our{' '}
                    <a
                      href="https://trusteasy.com.au/contact-us/"
                      target="_blank"
                      rel="noreferrer"
                      style={{ borderBottom: '1px dotted #FFF' }}
                    >
                      support team
                    </a>
                  </p>
                </div>
              </Form>
              <Footer />
            </div>
          </Col>
        </Row>
      ) : (
        <Authentication />
      )}
    </div>
  );
};

export default FirmSignup;
