/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Table } from 'antd';
import { Modal as BSModal } from 'react-bootstrap';
import moment from 'moment';
import { API, fileUpload, get } from '../../config';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { trimString } from '../../helper/fullNameGetter';
import { formatNumber } from '../../APIConfig/Interceptor';

import RecordPayment from '../record-payment/RecordPayment';
import RecordReceipt from '../receipt/RecordReceipt';

const ATOSearchForList = ({ close, transaction_type, ItemId, searchParams, Item }) => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [searchKeyWord, setSearchKeyWord] = useState(searchParams ? searchParams : '');
  const [selectedItem, setSelectedItem] = useState('');
  const [data, setData] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [itemJson, setItemJson] = useState();

  useEffect(() => {
    if (searchParams) {
      transaction_type === 0
        ? getProcessRefundSearchData(searchParams)
        : getATOSearchData(searchParams);
    }
  }, [searchParams]);

  const ATOTableColumns = [
    {
      title: 'Transaction Date',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      render: (created_at) => {
        return moment(created_at).format('DD-MM-YYYY');
      },
      width: '10%'
    },
    {
      title: 'Client Name',
      sorter: (a, b) => a.client_name.localeCompare(b.client_name),
      key: 'name',
      render: (record) => <span>{trimString(record.client_name, 32)}</span>,
      width: '30%'
    },
    {
      title: 'Description',
      key: 'description',
      render: (record) => <div>{record?.description ? record?.description : ''}</div>,
      width: '20%'
    },
    {
      title: 'Lodgement Reference',
      sorter: (a, b) => a.lodgement_reference.localeCompare(b.lodgement_reference),
      key: 'lodgement_reference',
      dataIndex: 'lodgement_reference',
      width: '15%'
    },
    {
      title: 'Amount',
      dataIndex: 'refund',
      sorter: (a, b) => a.refund - b.refund,
      render: (refund) => <span>${refund}</span>,
      width: '25%',
      className: 'centeralign'
    }
  ];
  const ProcessTableColumns = [
    {
      title: 'Transaction Date',
      dataIndex: 'transaction_date',
      key: 'transaction_date',
      sorter: (a, b) => a.transaction_date.localeCompare(b.transaction_date),
      render: (transaction_date) => moment(transaction_date, 'YYYY-MM-DD').format('DD-MM-YYYY')
    },
    {
      title: 'Client Name',
      key: 'client_name',
      render: (record) => {
        if (record.payment) {
          return record.payment?.customer?.first_name
            ? record.payment?.customer?.first_name + ' ' + record.payment?.customer?.last_name
            : record.payment?.customer?.business_name
            ? record.payment?.customer?.business_name
            : record.payment?.customer?.last_name;
        } else {
          let client_name = [];
          for (let k = 0; k < record.aba_transaction?.length; k++) {
            let nameData = record.aba_transaction[k]?.reconciliation?.customer?.first_name
              ? record.aba_transaction[k]?.reconciliation?.customer?.first_name +
                ' ' +
                record.aba_transaction[k]?.reconciliation?.customer?.last_name
              : record.aba_transaction[k]?.reconciliation?.customer?.business_name
              ? record.aba_transaction[k]?.reconciliation?.customer?.business_name
              : record.aba_transaction[k]?.reconciliation?.customer?.last_name;

            client_name.push(nameData);
          }
          return client_name?.join(', ');
        }
      }
    },
    {
      title: 'Description',
      key: 'description',
      render: (record) => <div>{record?.description ? record?.description : ''}</div>
    },
    {
      title: 'Refund',
      key: 'refund',
      sorter: (a, b) => a.total_amount - b.total_amount,
      render: (record) => <span>${formatNumber(record.total_amount)}</span>
    },
    {
      title: 'Total Fees',
      key: 'fees',
      render: (record) => (
        <span>${formatNumber(Number.parseFloat(record.total_amount - record.amount))}</span>
      ),
      className: 'centeralign'
    },
    {
      title: 'Remittance',
      dataIndex: 'amount',
      sorter: (a, b) => a.amount - b.amount,
      render: (record, amount) => <span>${formatNumber(amount.amount)}</span>,
      className: 'centeralign'
    }
  ];

  const processUserList = (response) => {
    return response.map((user) => {
      return {
        ...user,
        key: user.record_type === 0 ? user.receipt_id : user.ato_id
      };
    });
  };

  const processRefundList = (response) => {
    return response.map((refund) => {
      return {
        ...refund,
        key: refund.id
      };
    });
  };

  const getATOSearchData = (searchKeyWord) => {
    try {
      setIsDataLoading(true);
      setSearchKeyWord(searchKeyWord);
      if (searchKeyWord != '') {
        get(API.SEARCH_ATO_RECEIPT + `?search=${searchKeyWord}`).then((response) => {
          setIsDataLoading(false);
          setData([]);
          const userData = processUserList(response.data.data.data ? response.data.data.data : []);
          setData(userData ? userData : []);
        });
      } else {
        setIsDataLoading(false);
        setData([]);
      }
    } catch (e) {
      setData([]);
      const errorMessage = e.response?.data?.errors?.myna_error[0];
      if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  const getProcessRefundSearchData = (searchKeyWord) => {
    try {
      setIsDataLoading(true);
      setSearchKeyWord(searchKeyWord);
      if (searchKeyWord != '') {
        get(API.SEARCH_ABA + `?search=${searchKeyWord}`).then((response) => {
          setIsDataLoading(false);
          setData([]);
          const processData = processRefundList(
            response?.data?.data?.data ? response?.data?.data?.data : []
          );
          setData(processData ? processData : null);
        });
      } else {
        setIsDataLoading(false);
        setData([]);
      }
    } catch (e) {
      setData([]);
      const errorMessage = e.response?.data?.errors?.myna_error[0];
      if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  const toggleAddModal = () => {
    if (addModal === false) {
      let dataJson = null;
      var dateParts = Item?.date.split('-');
      var dateObject = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]);

      dataJson = {
        transactionDate: dateObject,
        amount: Item.amount,
        description: Item.description
      };

      setItemJson(dataJson);
    } else {
      setItemJson();
    }
    setAddModal(!addModal);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedItem(selectedRows[0]);
    }
  };

  const handleSaveSearch = async (record) => {
    try {
      setIsDataLoading(true);
      let selectedData;
      if (record) {
        selectedData = record;
      } else {
        selectedData = selectedItem;
      }
      const formData = new FormData();
      const key = selectedData?.ato_id ? 'ato_id' : 'receipt_id';
      formData.append(key, selectedData?.ato_id ? selectedData?.ato_id : selectedData?.receipt_id);
      formData.append(
        'description',
        Item?.description ? Item?.description : Item?.bank_statement.description
      );
      const response = await fileUpload(`${API.UPDATE_BANK_RECONCILIATION}${ItemId}`, formData, {});
      if (response.status === 200) {
        toast.success(response.data.message);
        setTimeout(() => {
          close(true);
        }, 3000);
      }
    } catch (error) {
      const { data } = error.response;
      if (data) {
        toast.error(data?.message);
        toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  const handleSaveProcessRefundSearch = async (record) => {
    try {
      let selectedData;
      if (record) {
        selectedData = record;
      } else {
        selectedData = selectedItem;
      }
      const formData = new FormData();
      formData.append('aba_id', selectedData.id);
      formData.append(
        'description',
        Item?.description ? Item?.description : Item?.bank_statement.description
      );
      if (record?.customer_id) {
        formData.append('customer_id', record?.customer_id);
      }
      if (record?.supplier_id) {
        formData.append('supplier_id', record?.supplier_id);
      }
      const response = await fileUpload(`${API.UPDATE_BANK_RECONCILIATION}${ItemId}`, formData, {});
      if (response.status === 200) {
        toast.success(response.data.message);
        setTimeout(() => {
          close(true);
        }, 3000);
      }
    } catch (error) {
      const { data } = error.response;
      if (data) {
        toast.error(data?.message);
        toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  const isButtonDisabled = useMemo(() => {
    if (!data.length) return true;
    if (!selectedItem) return true;
    return false;
  }, [data.length, selectedItem]);

  return (
    <div>
      <Modal
        title=""
        centered
        open
        footer={null}
        width={1170}
        closable
        className="modal-body"
        onCancel={close}
      >
        <>
          <div className="modal-content-details reconcile-list">
            <div className="table-top-btn reconcile-list">
              <div className="searchbar">
                <input
                  type="text"
                  value={searchKeyWord}
                  placeholder="Search"
                  onChange={(e) => {
                    transaction_type === 0
                      ? getProcessRefundSearchData(e.target.value)
                      : getATOSearchData(e.target.value);
                  }}
                />
              </div>
              <div className="table-btn">
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() =>
                    {
                      toggleAddModal(transaction_type);
                    }
                  }
                >
                  {transaction_type == 0 ? 'Add Trust Payment' : 'Add Trust Receipt'}
                </Button>
                <Button variant="secondary" onClick={close}>
                  Cancel
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => {
                    transaction_type === 0 ? handleSaveProcessRefundSearch() : handleSaveSearch();
                  }}
                  size="sm"
                  className="ok-btn"
                  disabled={isButtonDisabled}
                >
                  OK
                </Button>
              </div>
            </div>
          </div>
          <div className="reconcile-list-table">
            <Table
              rowSelection={{
                type: 'radio',
                ...rowSelection
              }}
              columns={transaction_type === 0 ? ProcessTableColumns : ATOTableColumns}
              dataSource={data}
              loading={isDataLoading}
              onRow={(record) => {
                return {
                  onDoubleClick: () => {
                    setSelectedItem(record);
                    transaction_type === 0
                      ? handleSaveProcessRefundSearch(record)
                      : handleSaveSearch(record);
                  } // click row
                };
              }}
            />
          </div>
        </>
      </Modal>
      {addModal && (
        <BSModal
          className="addPaymentReceipt-modal"
          show={addModal}
          onHide={toggleAddModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <BSModal.Header closeButton>
            {transaction_type == 0 ? 'Add Trust Payment' : 'Add Trust Receipt'}
          </BSModal.Header>
          <BSModal.Body>
            {transaction_type === 0 ? (
              <RecordPayment
                bankReconcilation={true}
                reconcileFunction={() => {
                  getProcessRefundSearchData(searchKeyWord);
                }}
                toggleAddModal={toggleAddModal}
                itemJson={itemJson}
              />
            ) : (
              <RecordReceipt
                bankReconcilation={true}
                reconcileFunction={() => getATOSearchData(searchKeyWord)}
                toggleAddModal={toggleAddModal}
                itemJson={itemJson}
              />
            )}
          </BSModal.Body>
        </BSModal>
      )}
    </div>
  );
};

export default ATOSearchForList;
