import React, { useState, useMemo, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DatePicker, Select } from 'antd';
import Footer from '../../components/Footer/Index';
import useReconciledStatementList from './useReconciledStatementList';
import {
  getReconciledStatementTableColumns,
  getReconciledStatementInnerTableColumns
} from './FirmTrustHelper';
import { PaginationItemRenderer } from '../../shared/PaginationItemRenderer';
import '../../App.css';
import '../../assets/css/style.css';
import moment from 'moment';
import { API, defaultPaginationLimit, get } from '../../config';
import { toast } from 'react-toastify';
import TableComponent from '../ato/TableComponent';

const dateFormat = ['DD-MM-YYYY', 'DD/MM/YYYY'];
const disabledDate = (current) => {
  return current && current > moment().endOf('day');
};

const ReconciledStatement = ({ setBalanceInfo, setDateFilter, dateFilter }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { RangePicker } = DatePicker;
  const query = new URLSearchParams(location.search);
  const selectedTab = query.get('tab');
  const currentPage = query.get('page');
  const [limit, setLimit] = useState(query.get('limit') ?? defaultPaginationLimit);

  const [undoLoading, setUndoLoading] = useState();

  const handleDateChange = (dates) => {
    if (dates && dates.length) {
      const startDate = dates[0].format('DD-MM-YYYY');
      const endDate = dates[1].format('DD-MM-YYYY');
      setDateFilter({
        start_date: startDate,
        end_date: endDate
      });
      fetchReconciledStatement(1, limit, startDate, endDate);
    } else {
      setDateFilter({
        start_date: '',
        end_date: ''
      });
      fetchReconciledStatement(1, limit, '', '');
    }
    navigate(`/firm-bank-account?page=1&tab=reconciled-items&limit=${limit}`, {
      replace: true
    });
  };
  const {
    reconciledStatements,
    isDataLoading,
    fetchReconciledStatement,
    pagingData,
    onPageChange
  } = useReconciledStatementList(
    currentPage,
    dateFilter.start_date,
    dateFilter.end_date,
    setBalanceInfo,
    selectedTab
  );
  const [localStatements, setLocalStatements] = useState([]);

  useEffect(() => {
    setLocalStatements(reconciledStatements);
  }, [reconciledStatements]);

  const handleUndo = async (id) => {
    try {
      setUndoLoading(true);
      const { data } = await get(`${API.UNDO_BANK_RECONCILIATION}/${id}`);
      toast.success(data.message);
      fetchReconciledStatement(currentPage, limit);
      setUndoLoading(false);
    } catch (e) {
      setUndoLoading(false);
      const errorMessage = e.response?.data?.errors?.myna_error[0];
      if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  const innerTableColumns = useMemo(() => {
    return getReconciledStatementInnerTableColumns();
  }, []);

  const handleChange = async (value) => {
    setLimit(value);
    navigate(`/firm-bank-account?page=1&tab=reconciled-items&limit=${value}`, {
      replace: true
    });
    fetchReconciledStatement(1, value);
  };

  return (
    <>
      <div className="cms-page">
        <div className="table-top-btn">
          <div className="search-section" style={{ marginBottom: '15px' }}>
            <RangePicker
              defaultValue={[
                dateFilter.start_date ? moment(dateFilter.start_date, dateFormat[0]) : '',
                dateFilter.end_date ? moment(dateFilter.end_date, dateFormat[0]) : ''
              ]}
              style={{ borderRadius: '30px' }}
              onChange={handleDateChange}
              disabledDate={disabledDate}
              format={dateFormat}
            />
          </div>
        </div>
        <div style={{ width: '100%', height: '85%' }}>
          <TableComponent
            rowKey={(record) => record.key}
            columns={getReconciledStatementTableColumns(handleUndo, undoLoading)}
            nestedTableColumns={innerTableColumns}
            dataSource={localStatements}
            isNested={true}
            loading={isDataLoading}
            pagingData={pagingData}
            onPageChange={(page) => onPageChange(page, limit)}
            limit={query.get('limit') ?? limit}
            pagination={{
              hideOnSinglePage: true,
              defaultCurrent: 1,
              showQuickJumper: true,
              onChange: (page) => {
                onPageChange(page);
              },
              itemRender: PaginationItemRenderer
            }}
          />
          {localStatements?.length > 0 && (
            <div className="mt-2">
              <div className="col-md-12">
                Showing&nbsp;
                <Select
                  defaultValue={`${defaultPaginationLimit}`}
                  value={query.get('limit') ? query.get('limit') : `${defaultPaginationLimit}`}
                  onChange={handleChange}
                  options={[
                    { value: '10', label: '10' },
                    { value: '25', label: '25' },
                    { value: '50', label: '50' },
                    { value: '100', label: '100' }
                  ]}
                />
                &nbsp;Records per page I Showing {pagingData?.from} to&nbsp;
                {pagingData?.to} from {pagingData?.total} Records
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ReconciledStatement;
