import { useEffect, useState, useCallback } from 'react';
import { API, post } from '../../config.js';
import { sortData } from './DashboardHelper.js';

const useDashboardList = (currentPage, filter, sortValue) => {
  const splitValue = sortValue.split(',');
  const sortColumn = splitValue[0];
  const sortOrder = splitValue[1];
  const [returnLodged, setReturnLodged] = useState([]);
  const [returnLodgedHasMore, setReturnLodgedHasMore] = useState(true);
  const [ATOList, setATOList] = useState([]);
  const [ATOListHasMore, setATOListHasMore] = useState(true);
  const [returnAssessed, setReturnAssessed] = useState([]);
  const [returnAssessedHasMore, setReturnAssessedHasMore] = useState(true);
  const [underReview, setUnderReview] = useState([]);
  const [underReviewHasMore, setUnderReviewHasMore] = useState(true);
  const [completed, setCompleted] = useState([]);
  const [completedHasMore, setCompletedHasMore] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [amountData, setAmountData] = useState('');
  const [pagingData, setPagingData] = useState({ current: currentPage || 1 });

  const fetchDashboardData = useCallback(async () => {
    try {
      setIsDataLoading(true);
      const { data } = await post(
        `${API.DASHBOARD}?page=${pagingData.current}&sort_column=${sortColumn}&sort_order=${sortOrder}`,
        filter
      );
      const {
        data: { tax_return_lodged, orphanATOs, tax_return_assessed, under_review, processed }
      } = data;
      const { returnLodgedData, atoData, returnAssessedData, underReviewData, completedData } =
        sortData(
          sortValue,
          tax_return_lodged,
          orphanATOs,
          tax_return_assessed,
          under_review,
          processed
        );

      if (tax_return_lodged?.current_page == 1) {
        let isMoreDataExist =
          tax_return_lodged?.next_page_url !== '' && tax_return_lodged?.next_page_url !== null;
        setReturnLodgedHasMore(isMoreDataExist);
      }
      setReturnLodged({ ...tax_return_lodged, data: returnLodgedData });

      if (orphanATOs?.current_page == 1) {
        let isMoreDataExist =
          orphanATOs?.next_page_url !== '' && orphanATOs?.next_page_url !== null;
        setATOListHasMore(isMoreDataExist);
      }
      setATOList({ ...orphanATOs, data: atoData });

      if (tax_return_assessed?.current_page == 1) {
        let isMoreDataExist =
          tax_return_assessed?.next_page_url !== '' && tax_return_assessed?.next_page_url !== null;
        setReturnAssessedHasMore(isMoreDataExist);
      }
      setReturnAssessed({ ...tax_return_assessed, data: returnAssessedData });

      if (under_review?.current_page == 1) {
        let isMoreDataExist =
          under_review?.next_page_url !== '' && under_review?.next_page_url !== null;
        setUnderReviewHasMore(isMoreDataExist);
      }
      setUnderReview({ ...under_review, data: underReviewData });

      if (processed?.current_page == 1) {
        let isMoreDataExist = processed?.next_page_url !== '' && processed?.next_page_url !== null;
        setCompletedHasMore(isMoreDataExist);
      }
      setCompleted({ ...processed, data: completedData });

      setIsDataLoading(false);
      setAmountData({
        processedFeesAmount: data.data.processedFeesAmount,
        processedGrossAmount: data.data.processedGrossAmount,
        processedRemittanceAmount: data.data.processedRemittanceAmount
      });
    } catch (e) {
      setReturnLodged([]);
      setATOList([]);
      setReturnAssessed([]);
      setUnderReview([]);
      setCompleted([]);
      setIsDataLoading(false);
      setPagingData({});
      setAmountData('');
    }
  }, [
    pagingData.current,
    filter.filter,
    filter.start_date,
    filter.end_date,
    sortColumn,
    sortOrder
  ]);

  useEffect(() => {
    fetchDashboardData();
  }, [fetchDashboardData]);

  return {
    fetchDashboardData,
    returnLodged,
    ATOList,
    returnAssessed,
    underReview,
    completed,
    isDataLoading,
    pagingData,
    setCompleted,
    setUnderReview,
    setReturnAssessed,
    setReturnLodged,
    setATOList,
    amountData,
    returnAssessedHasMore,
    setReturnAssessedHasMore,
    ATOListHasMore,
    setATOListHasMore,
    underReviewHasMore,
    setUnderReviewHasMore,
    completedHasMore,
    setCompletedHasMore,
    returnLodgedHasMore,
    setReturnLodgedHasMore
  };
};

export default useDashboardList;
