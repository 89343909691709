import React, { useState } from 'react';
import { Select } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { Table, Input } from 'antd';
import Footer from '../../../components/Footer/Index';
import BackButton from '../../../components/Form/BackButton';
import useRoleList from './useRoleList';
import { getTableColumns } from './RoleListHelper';
import '../../../App.css';
import '../../../assets/css/style.css';
import './roles.module.css';
import { PaginationItemRenderer } from '../../../shared/PaginationItemRenderer';
import DialogModal from '../../../components/Header/DialogModal';
import { helpCenterArray } from '../../HelpCenter';
import { defaultPaginationLimit } from '../../../config';
import RoleAdd from './RoleAdd';
import { Button } from 'react-bootstrap';
import { SearchOutlined } from '@ant-design/icons';

const RoleList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const search = query.get('search');
  const currentPage = query.get('page');

  const [showAddRole, setShowAddRole] = useState(false);
  const toggleRoleModal = () => {
    setShowAddRole(!showAddRole);
  };
  const [limit, setLimit] = useState(
    query.get('limit') ? query.get('limit') : defaultPaginationLimit
  );
  const { roles, fetchRoles, onChange, searchParam, isDataLoading, pagingData, onPageChange } =
    useRoleList(currentPage, search);

  const handleDelete = () => {
    fetchRoles();
  };

  const handleChange = async (value) => {
    setLimit(value);
    navigate(`/admin/roles?page=1&tab=statement&limit=${value}`, {
      replace: true
    });
    fetchRoles(1, value);
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block admin-list-table">
          <h1 className="page-title">
            <BackButton />
            Role{' '}
            <DialogModal
              title="Dashboard"
              p1={helpCenterArray[36].name}
              p2={helpCenterArray[36].text}
            />
          </h1>
          <div className="content-details">
            <div className="table-top-btn">
              <div className="">
                <Input
                  style={{ width: '200%' }}
                  onChange={(e) => onChange(e)}
                  suffix={<SearchOutlined />}
                  value={searchParam}
                  placeholder="Search Role"
                  allowClear
                />
              </div>
              <div className="table-btn">
                <Button
                  onClick={() => {
                    toggleRoleModal();
                  }}
                  className="action button"
                >
                  New
                </Button>
              </div>
            </div>
            <div className="role-list" style={{ width: '100%', height: '85%' }}>
              <Table
                rowKey={(record) => record.id}
                columns={getTableColumns(handleDelete)}
                dataSource={roles}
                loading={isDataLoading}
                pagination={{
                  hideOnSinglePage: true,
                  defaultCurrent: 1,
                  showQuickJumper: true,
                  onChange: (page) => onPageChange(page, limit),
                  itemRender: PaginationItemRenderer,
                  ...pagingData,
                  pageSize: limit
                }}
              />
              {roles?.length > 0 && (
                <div className="col-md-12">
                  Showing&nbsp;
                  <Select
                    defaultValue={`${defaultPaginationLimit}`}
                    value={query.get('limit') ? query.get('limit') : `${defaultPaginationLimit}`}
                    onChange={handleChange}
                    options={[
                      { value: '10', label: '10' },
                      { value: '25', label: '25' },
                      { value: '50', label: '50' },
                      { value: '100', label: '100' }
                    ]}
                  />
                  &nbsp;Records per page I Showing {pagingData?.from} to&nbsp;
                  {pagingData?.to} from {pagingData?.total} Records
                </div>
              )}
            </div>
          </div>
          <Footer />
        </div>
      </div>
      {showAddRole && (
        <RoleAdd
          showAddRole={showAddRole}
          toggleRoleModal={toggleRoleModal}
          fetchRoles={fetchRoles}
        />
      )}
    </div>
  );
};

export default RoleList;
