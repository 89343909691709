import { useEffect, useCallback, useState } from 'react';

import { API, get } from '../../../config';

export const getSupplierDetail = async (supplierId) => {
  try {
    const { data } = await get(`${API.SUPPLIERS}/` + supplierId);
    return data.data;
  } catch (e) {
    return null;
  }
};

export const useStateList = () => {
  const [stateList, setStateList] = useState([]);
  const [stateLoading, setStateLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setStateLoading(true);
      const { data } = await get(API.GET_STATE_LIST);
      setStateLoading(false);
      setStateList(data.data);
    } catch (e) {
      setStateList([]);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    stateList,
    stateLoading
  };
};

export const useSuburbList = (stateId) => {
  const [suburbList, setSuburbList] = useState([]);
  const [selectedState, setSelectedState] = useState();
  const [suburbLoading, setSuburbLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      if (selectedState || stateId) {
        setSuburbLoading(true);
        const { data } = await get(`${API.GET_SUBURB_LIST}/${selectedState || stateId}`);
        setSuburbLoading(false);
        setSuburbList(data.data);
      }
    } catch (e) {
      setSuburbList([]);
    }
  }, [selectedState, stateId]);

  useEffect(() => {
    init();
  }, [init]);

  return {
    suburbList,
    handleStateSelect: (value) => {
      setSelectedState(value);
      setSuburbList([]);
    },
    suburbLoading,
    setSuburbList,
    selectedState
  };
};
