/* eslint-disable no-nested-ternary */
import { faPlus, faReply, faSync, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Spin, Upload } from 'antd';
import React, { useState } from 'react';
import { Modal as BSModal, Button } from 'react-bootstrap';
import { getStatusIcon } from './SupportTicketHelper';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import { API, fileUpload } from '../../../config';
import { toast } from 'react-toastify';
import axios from 'axios';
import LongText from './LongText';
import { checkRole, convertedDate } from '../../../helper/utils';
import logo from '../../../assets/images/favicon.png';
const SupportTicketView = ({
  showView,
  toggleShowModal,
  loading,
  supportDetail,
  id,
  fetchData,
  setReplay,
  reply
}) => {

  const roleType = localStorage.getItem('role_type');
  const [editorState, setEditorState] = useState(null);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [status, setStatus] = useState();

  const toggleStatusModal = (status) => {
    if (statusModal) {
      setStatus('');
    } else {
      setStatus(status);
    }
    setStatusModal(!statusModal);
  };
  const handleEditorChange = (state) => {
    setEditorState(state);
  };
  const toggleReplayInput = () => {
    setReplay(!reply);
  };

  const handleFileChange = (info) => {
    setFiles(info.fileList);
  };

  const handleUpload = async () => {
    setIsLoading(true);
    const formData = new FormData();
    const value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    formData.append('message', value);
    formData.append('_method', 'put');
    files.forEach((file, index) => {
      if (file !== undefined) {
        formData.append(`attachments[${index}]`, file.originFileObj);
      }
    });
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    fileUpload(
      checkRole(roleType) ? `${API.ADMIN_SUPPORT_TICKET}/${id}` : `${API.SUPPORT_TICKET}/${id}`,
      formData,
      config
    )
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          toast.success(response.data.message);
          setEditorState(null);
          setFiles([]);
          toggleReplayInput();
          fetchData();
        }
      })
      .catch((err) => {
        const { data } = err.response;
        setIsLoading(false);
        toast.error(data?.message);
        toast.error(data.errors && data.errors.customer_file && data.errors.customer_file[0]);
        toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
        var error = {};
        error['myna_error'] = 'There is some issue with request, please try after some time.';
      });
  };

  const handleStatus = async (status) => {
    setIsLoading(true);
    const formData = new FormData();
    if (status === 'isResolved') {
      formData.append('is_resolved', 1);
    } else {
      formData.append('status', status);
    }
    formData.append('_method', 'put');
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    fileUpload(
      checkRole(roleType) ? `${API.ADMIN_SUPPORT_TICKET}/${id}` : `${API.SUPPORT_TICKET}/${id}`,
      formData,
      config
    )
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          toast.success(response.data.message);
          setEditorState(null);
          setFiles([]);
          toggleReplayInput();
          fetchData();
          toggleStatusModal();
        }
      })
      .catch((err) => {
        const { data } = err.response;
        setIsLoading(false);
        toast.error(data?.message);
        toast.error(data.errors && data.errors.customer_file && data.errors.customer_file[0]);
        toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
        var error = {};
        error['myna_error'] = 'There is some issue with request, please try after some time.';
      });
  };

  const fileUploadProps = {
    name: 'file',
    multiple: true,
    headers: {
      authorization: 'authorization-text'
    },
    accept: '*',
    beforeUpload: () => {
      return false;
    },
    onChange(info) {
      handleFileChange(info);
    }
  };

  const downloadAttachments = async (files) => {
    for (let fileIndex = 0; fileIndex < files?.length; fileIndex++) {
      const response = await axios.get(files[fileIndex]?.file, { responseType: 'blob' });
      const fileData = response.data;
      const url = window.URL.createObjectURL(new Blob([fileData]));
      var a = document.createElement('a');
      a.href = url;
      var file = files[fileIndex]?.file.split('/');
      a.setAttribute('download', file[file.length - 1] || 'attachment');
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
    }
  };
  return (
    <>
      <BSModal
        show={showView}
        onHide={toggleShowModal}
        dialogClassName="fullWidthModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <BSModal.Header closeButton>View Support Ticket</BSModal.Header>
        <BSModal.Body>
          {loading ? (
            <div className="centeralign spin-padding support-view-spin">
              <Spin />
            </div>
          ) : (
            supportDetail && (
              <>
                <div className="support-ticket-view">
                  <Row>
                    <Col>
                      <div className="support-ticket-header">
                        <div className="ticket-number">#{supportDetail?.id}</div>
                        <div> | </div>
                        <div className="ticket-subject-body">
                          <span className="ticket-subject">{supportDetail?.subject}</span>
                        </div>
                        <FontAwesomeIcon
                          icon={faSync}
                          className="reply-sync-btn"
                          onClick={() => {
                            fetchData();
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={8} xs={24}>
                      <div className="support-ticket-details ticket-card">
                        <div className="status-body">
                          <span className="ticket-card-title">
                            {supportDetail?.status
                              ? getStatusIcon(
                                  supportDetail?.status?.toString(),
                                  supportDetail?.is_resolved
                                )
                              : ''}{' '}
                          </span>
                          {supportDetail?.status != 4 && supportDetail?.status != 1 && (
                            <div className="ticket-status-btn">
                              {supportDetail?.is_resolved ? (
                                <Button
                                  onClick={() => {
                                    toggleStatusModal('2');
                                  }}
                                  className="status-btn"
                                >
                                  Open
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => {
                                    toggleStatusModal('isResolved');
                                  }}
                                  className="status-btn"
                                >
                                  Resolve
                                </Button>
                              )}
                              <Button
                                onClick={() => {
                                  toggleStatusModal('4');
                                }}
                                className="status-btn"
                              >
                                Mark As Close
                              </Button>
                            </div>
                          )}
                        </div>
                        <hr />
                        <h3 className="ticket-card-title">Subject</h3>
                        <p className="ticket-card-message">{supportDetail?.subject}</p>
                        <hr />
                        <h3 className="ticket-card-title">Description</h3>
                        <div className="ticket-card-message">
                          {' '}
                          <LongText content={supportDetail?.details?.message} maxLength={350} />
                        </div>
                        {supportDetail?.details?.support_ticket_comment_files?.length !== 0 && (
                          <div className="attachment-section">
                            <span
                              onClick={() => {
                                downloadAttachments(
                                  supportDetail?.details?.support_ticket_comment_files
                                );
                              }}
                              className="attachment-text"
                            >
                              {supportDetail?.details?.support_ticket_comment_files?.length}{' '}
                              Attachment(s)
                            </span>
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xl={16} xs={24}>
                      <div className="support-ticket-thread">
                        {supportDetail?.status != 4 && (
                          <Button
                            className={reply ? 'reply-btn' : 'reply-btn outline'}
                            onClick={() => {
                              toggleReplayInput();
                            }}
                          >
                            <FontAwesomeIcon icon={faReply} /> Reply
                          </Button>
                        )}
                        {reply && (
                          <div className="reply-input-box ticket-card">
                            <div className="support-ticket support-ticket-framebox">
                              <div className="support-ticket-field">
                                <Editor
                                  editorState={editorState}
                                  toolbar={{
                                    inline: {
                                      options: ['bold', 'italic', 'underline']
                                    },
                                    options: [
                                      'inline',
                                      'fontSize',
                                      'list',
                                      'textAlign',
                                      'remove',
                                      'history'
                                    ]
                                  }}
                                  onEditorStateChange={handleEditorChange}
                                  wrapperClassName="wrapper-class"
                                  editorClassName="editor-class"
                                  toolbarClassName="toolbar-class"
                                />
                              </div>

                              <div className="support-ticket-reply-footer">
                                <div className="support-ticket-field">
                                  <Upload {...fileUploadProps}>
                                    <Button className="reply-btn attachment-btn">
                                      {' '}
                                      <FontAwesomeIcon icon={faPlus} /> Add Attachment
                                    </Button>
                                  </Upload>
                                </div>
                                <div className="upload-btn-div">
                                  <Button
                                    disabled={
                                      isLoading
                                    }
                                    variant="primary"
                                    onClick={() => handleUpload()}
                                    className="upload reply-btn"
                                  >
                                    {isLoading ? 'Loading...' : 'Submit'}
                                  </Button>
                                  <Button
                                    variant="secondary"
                                    onClick={() => toggleReplayInput()}
                                    className="cancel-btn reply-btn"
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* Map the List of Replay */}
                        {supportDetail?.support_ticket_comments?.length != 0 &&
                          supportDetail?.support_ticket_comments?.length !== 1 && (
                            <div className="reply-list ticket-card">
                              {supportDetail?.support_ticket_comments?.map((item, index) => (
                                <>
                                  {index != supportDetail?.support_ticket_comments?.length - 1 && (
                                    <div
                                      key={index}
                                      className={
                                        item?.replied_by?.role?.role_type !== 'Admin'
                                          ? 'ticket-card support-comment-card'
                                          : 'ticket-card primary-card'
                                      }
                                    >
                                      <div className="reply-header">
                                        <div className="font-bold">
                                          {item?.created_at ? convertedDate(item?.created_at) : ''}
                                        </div>
                                        <div className="icon-replay">
                                          {item?.replied_by?.role?.role_type !== 'Admin' ? (
                                            <FontAwesomeIcon icon={faUser} />
                                          ) : (
                                            <img className="small-favicon" src={logo} alt="" />
                                          )}
                                        </div>
                                      </div>

                                      <LongText content={item?.message} maxLength={350} />
                                      <div className="reply-footer">
                                        <div className="replied-by">
                                          <div>Thank You,</div>
                                          <span className="to-name-text">
                                            {item?.replied_by?.first_name
                                              ? item?.replied_by?.first_name +
                                                ' ' +
                                                item?.replied_by?.last_name
                                              : item?.replied_by?.last_name}
                                          </span>
                                          <div>TrustEasy Support</div>
                                        </div>
                                        {item?.support_ticket_comment_files?.length !== 0 && (
                                          <div className="attachment-section">
                                            <span
                                              onClick={() => {
                                                downloadAttachments(
                                                  item?.support_ticket_comment_files
                                                );
                                              }}
                                              className="attachment-text"
                                            >
                                              {item?.support_ticket_comment_files?.length}{' '}
                                              Attachment(s)
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </>
                              ))}
                            </div>
                          )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </>
            )
          )}
        </BSModal.Body>
      </BSModal>
      {statusModal && (
        <BSModal
          className="small-modal remove-popup"
          show={statusModal}
          onHide={toggleStatusModal}
          dialogClassName="modal-50w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <BSModal.Header closeButton={toggleStatusModal}></BSModal.Header>
          <BSModal.Body>
            <div className="remove-content spin-body-relative">
              <p>Do you want to change the status of ticket?</p>
              <div className="modal-action">
                <Button
                  className="cancel"
                  onClick={() => {
                    toggleStatusModal();
                  }}
                >
                  Cancel
                </Button>
                <Button onClick={() => handleStatus(status)}>OK</Button>
              </div>
              {isLoading && (
                <div className="top-modal-spin-center">
                  <Spin />
                </div>
              )}
            </div>
          </BSModal.Body>
        </BSModal>
      )}
    </>
  );
};

export default SupportTicketView;
