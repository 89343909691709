import React, { useState } from 'react';
import { Button, Modal, Tooltip } from 'antd';
import { API, deleteCall } from '../../../config';
import { toast } from 'react-toastify';
import '../../../App.css';
import '../../../assets/css/style.css';
import '../../users/users.module.css';
import XeroLogo from '../../../assets/images/xero-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

const XeroStatus = ({ id, xeroData, getRegisteredFirmData }) => {
  const [show, setShow] = useState(false);
  const toggleXeroDisconnectModal = () => {
    setShow(!show);
  };

  const disconnectXero = async (toggleXeroDisconnectModal) => {
    try {
      const { data } = await deleteCall(`${API.XERO_ADMIN_DISCONNECT}?accounting_firm_id=${id}`);
      toggleXeroDisconnectModal();
      return data;
    } catch (e) {
      return null;
    }
  };

  const handleXeroDisconnect = async () => {
    const xeroDisconnect = await disconnectXero(toggleXeroDisconnectModal);
    xeroDisconnect?.message && toast.success(xeroDisconnect?.message);
    getRegisteredFirmData();
  };

  return (
    <span className="sales-force-status-body">
      {xeroData ? (
        <Tooltip
          placement="bottomLeft"
          title={
            xeroData?.connection_status !== 0 || xeroData?.tenant ? 'Connected' : 'Disconnected'
          }
        >
          <button
            className="sales-force-status-xero-btn xero-btn"
            onClick={
              xeroData?.connection_status !== 0 || xeroData?.tenant
                ? () => toggleXeroDisconnectModal()
                : () => {}
            }
          >
            <img src={XeroLogo} alt="Xero logo" className="xero-logo" />
            {xeroData?.connection_status !== 0 || xeroData?.tenant ? (
              <FontAwesomeIcon icon={faCheck} style={{ color: '#0da500' }} />
            ) : (
              <FontAwesomeIcon icon={faXmark} style={{ color: '#f00' }} />
            )}
          </button>
        </Tooltip>
      ) : (
        <></>
      )}

      {show && (
        <Modal
          show={show}
          className="remove-popup"
          width={500}
          closable
          centered
          open
          footer={null}
          onCancel={toggleXeroDisconnectModal}
        >
          <div className="remove-content">
            <p>Do you want to disconnect Xero/XPM?</p>
            <div className="modal-action">
              <Button className="cancel" onClick={toggleXeroDisconnectModal}>
                Cancel
              </Button>
              <Button onClick={handleXeroDisconnect}>OK</Button>
            </div>
          </div>
        </Modal>
      )}
    </span>
  );
};

export default XeroStatus;
