/* eslint-disable no-nested-ternary */
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React from 'react';
import { toast } from 'react-toastify';

import { FullNameGetterWithoutChartAt } from '../../helper/fullNameGetter';
import { formatNumber } from '../../APIConfig/Interceptor';

const downloadLetter = async (path) => {
  try {
    if (path) {
      const response = await axios.get(`${path}`, {
        responseType: 'blob'
      });
      const fileData = response.data;
      const url = window.URL.createObjectURL(new Blob([fileData]));
      var a = document.createElement('a');
      a.href = url;
      var file = path.split('/');
      a.setAttribute('download', file[file.length - 1] || 'new_aba.aba');
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
    } else {
      toast.error('File is not exist');
    }
  } catch (e) {
    const errors = e.response?.data?.errors;
    Object.keys(errors).forEach((key) => {
      toast.error(errors[key][0]);
    });
  }
};

export const tableColumns = () => {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '8%'
    },
    {
      title: 'Transaction Date',
      dataIndex: 'transaction_date',
      key: '',
      className: 'date_field',
      render: (transaction_date) => transaction_date.split('-').reverse().join('-'),
      width: '10%'
    },
    {
      title: 'Client Name',
      dataIndex: '',
      render: (record) => (
        <>
          {record?.customer
            ? FullNameGetterWithoutChartAt(record?.customer)
            : record?.supplier
            ? record?.supplier?.name
            : 'Multiple Clients'}
        </>
      ),
      width: '25%'
    },
    {
      title: 'Total Amount',
      dataIndex: 'total_amount',
      render: (total_amount) =>
        total_amount ? <span>${formatNumber(total_amount)}</span> : '$0.00',
      key: 'total_amount',
      width: '25%'
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: '',
      align: 'left',
      width: '25%'
    },
    {
      title: '.ABA File',
      key: '',
      render: (record) => (
        <>
          {record?.aba_file?.file ? (
            <FontAwesomeIcon
              icon={faDownload}
              size="1x"
              onClick={() => {
                downloadLetter(record?.aba_file?.file);
              }}
            />
          ) : (
            <></>
          )}
        </>
      ),
      className: 'ceneteralign',
      width: '10%'
    }
  ];
};

export const PaymentTableInnerColumns = () => {
  return [
    {
      title: 'Name',
      dataIndex: '',
      render: (record) => (
        <>
          {record?.beneficiary_id && record?.beneficiary?.account_name}
          {record?.withdrawal_account_id && FullNameGetterWithoutChartAt(record.withdrawal_account)}
          {!record?.withdrawal_account_id && !record?.beneficiary_id
            ? record?.payment?.customer
              ? FullNameGetterWithoutChartAt(record?.payment?.customer)
              : record?.payment?.supplier
              ? record?.payment?.supplier?.name
              : record?.customer
              ? FullNameGetterWithoutChartAt(record?.customer)
              : record?.supplier
              ? record?.supplier?.name
              : '-'
            : ` (${
                record?.payment?.customer
                  ? FullNameGetterWithoutChartAt(record?.payment?.customer)
                  : record?.payment?.supplier
                  ? record?.payment?.supplier?.name
                  : record?.customer
                  ? FullNameGetterWithoutChartAt(record?.customer)
                  : record?.supplier
                  ? record?.supplier?.name
                  : ''
              })`}
        </>
      ),
      width: '20%'
    },
    {
      title: 'BSB',
      dataIndex: 'bsb',
      width: '15%',
      render: (bsb) => (bsb ? <span> {`${bsb?.toString()}`}</span> : '')
    },
    {
      title: 'Account Number',
      dataIndex: 'account_number',
      width: '25%',
      render: (account_number) =>
        account_number ? <span> {account_number?.toString()}</span> : ''
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      width: '15%',
      render: (amount) => (amount ? <span>${formatNumber(amount)}</span> : '$0.00'),
      key: ''
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: '30%',
      align: 'left'
    }
  ];
};
