import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomerRemove from './CustomerRemove';
import CustomerDetailPopup from './CustomerDetailPopup';
import { API } from '../../config';

const CustomerActionButtons = ({
  data,
  handleDelete,
  is_delete = false,
  duplicateDelete = false
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showView, setShowView] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const toggleShowModal = () => {
    setShowView(!showView);
  };
  const toggleDeleteModal = () => {
    setShowDelete(!showDelete);
  };
  return (
    <span>
      <FontAwesomeIcon onClick={toggleShowModal} icon={faEye} size="1x" />
      <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
      <FontAwesomeIcon
        icon={faEdit}
        size="1x"
        onClick={() => {
          navigate(`${API.CUSTOMER_EDIT}?id=${data.id.id}`, { state: { from: location } });
        }}
      />
      {is_delete === false ? (
        <>
          <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
          <FontAwesomeIcon onClick={toggleDeleteModal} icon={faTrash} size="1x" />
        </>
      ) : (
        <></>
      )}
      {showDelete && (
        <CustomerRemove
          toggleDeleteModal={toggleDeleteModal}
          customerId={data.id.id}
          handleDelete={handleDelete}
          duplicateDelete={duplicateDelete}
        />
      )}
      {showView && (
        <CustomerDetailPopup
          customerData={data}
          customerId={data.id.id}
          toggleShowModal={toggleShowModal}
        />
      )}
    </span>
  );
};

export default CustomerActionButtons;
