/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Input, Button, Select, Spin } from 'antd';
import InputMask from 'react-input-mask';
import Form from '@component/Form';
import { useStateList, useSuburbList } from './SupplierHelper';
import { API, fileUpload, get } from '../../../config.js';
import UserSelectWithNewUser from '../../../components/User/UserSelectWithNewUser.js';
import { Modal } from 'react-bootstrap';
import { maskPostalCode } from '../../../helper/fullNameGetter.js';

const AddEditSupplier = ({ onClose, supplier, clientID }) => {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const { stateLoading, stateList = [] } = useStateList();
  const {
    suburbLoading,
    suburbList = [],
    handleStateSelect,
    selectedState
  } = useSuburbList(supplier?.state_id);
  const isEdit = supplier?.id;
  const [clientId, setClientId] = useState(clientID);
  const [newClientModal, setNewClientModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bankOptions, setBankOptions] = useState([]);
  const [bankSearch, setBankSearch] = useState('');
  const [bankLoading, setBankLoading] = useState(false);

  const getBankList = async (searchValue) => {
    try {
      setBankLoading(true);
      const { data } = await get(`${API.BANK_ABBREVIATION}?search=${searchValue}`);
      setBankLoading(false);
      setBankOptions(data.data);
    } catch (error) {
      setBankLoading(false);
      const errors = error.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  const handleBankSearch = (value) => {
    setBankSearch(value);
    if (value) {
      getBankList(value);
    }
  };

  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      const url = isEdit ? `${API.SUPPLIERS}/${supplier.id}` : API.SUPPLIERS;
      const reqObj = isEdit
        ? {
            ...values,
            _method: 'PUT',
            customer_id: clientId ? clientId : supplier?.customer_id ? supplier?.customer_id : ''
          }
        : {
            ...values,
            customer_id: clientId ? clientId : supplier?.customer_id ? supplier?.customer_id : ''
          };
      reqObj.abn = reqObj?.abn ? `${reqObj?.abn}`.replace(/ /g, '') : '';
      reqObj.account_number = reqObj?.account_number
        ? padWithLeadingZeros(Number(reqObj?.account_number), 9)
        : '';
      Object.keys(reqObj).forEach((key) => {
        if (reqObj[key]) {
          formData.append([key], reqObj[key]);
        }
      });
      const { data } = await fileUpload(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      toast.success(data.message);
      onClose();
    } catch (e) {
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  useEffect(() => {
    if (suburbList.length && selectedState) {
      form.setFieldsValue({ suburb_id: suburbList[0].id });
    }
    getBankList(bankSearch);
  }, [form, selectedState, suburbList]);

  function onSearch() {}

  const onSelect = (value) => {
    setClientId(value);
  };

  const handleAddNewClient = () => {
    setNewClientModal(!newClientModal);
  };

  const handleAddNewClientSubmit = async (values) => {
    try {
      setLoading(true);
      const formData = new FormData();
      const url = API.CREATE_CUSTOMER;
      const reqObj = values;
      Object.keys(reqObj).forEach((key) => {
        if (reqObj[key]) {
          formData.append([key], reqObj[key]);
        }
      });
      const { data } = await fileUpload(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      toast.success(data.message);
      if (data?.status === 200) {
        handleAddNewClient();
        onSelect(data?.data?.customer?.id);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  return (
    <div className="content-details">
      <Form
        form={form}
        initialValues={{
          ...supplier,
          postal_code: supplier?.postal_code ? maskPostalCode(Number(supplier?.postal_code)) : ''
        }}
        name="supplierForm"
        onFinish={handleSubmit}
        layout="vertical"
      >
        <div className="input-boxes">
          {isEdit ? (
            <>
              <div className="supplier-client half-width">
                {
                  <>
                    <div className="ant-col ant-form-item-label" style={{ width: '100%' }}>
                      <label
                        htmlFor="supplierForm_name"
                        className="ant-form-item-required"
                        title="Client"
                        style={{ display: 'block' }}
                      >
                        Client
                      </label>
                    </div>
                    <UserSelectWithNewUser
                      customerId={
                        isEdit
                          ? clientId
                            ? Number(clientId)
                            : Number(supplier?.customer_id)
                          : Number(clientId)
                      }
                      placeholder={'Select Client'}
                      formStyle={{ width: '100%' }}
                      onCustomerSelectionChange={onSelect}
                      handleAddNewClient={handleAddNewClient}
                      onClear={() => {
                        setClientId();
                      }}
                    />
                  </>
                }
              </div>
            </>
          ) : (
            <>
              <div className="supplier-client half-width">
                {
                  <>
                    <div className="ant-col ant-form-item-label" style={{ width: '100%' }}>
                      <label
                        htmlFor="supplierForm_name"
                        className="ant-form-item-required"
                        title="Client"
                        style={{ display: 'block' }}
                      >
                        Client
                      </label>
                    </div>
                    <UserSelectWithNewUser
                      customerId={Number(clientId)}
                      formStyle={{ width: '100%' }}
                      placeholder={'Select Client'}
                      onCustomerSelectionChange={onSelect}
                      handleAddNewClient={handleAddNewClient}
                      onClear={() => {
                        setClientId();
                      }}
                    />
                  </>
                }
              </div>
            </>
          )}
          <Form.Item
            className="half-width"
            label="Supplier Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter Supplier Name'
              },
              {
                pattern: new RegExp(/^[^!@#^%$<>][a-zA-Z\s\d./,&-()]*$/),
                message: `Name can contain letters, numbers, spaces and some special characters like (& ( ) . , - ')`
              }
            ]}
          >
            <Input placeholder="Name" name="name" />
          </Form.Item>
          <Form.Item
            className="half-width"
            label="Email"
            name="email"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!'
              }
            ]}
          >
            <Input placeholder="Email" name="email" />
          </Form.Item>
          <Form.Item className="half-width" label="Phone" name="phone">
            <InputMask
              mask="99 9999 9999"
              name="phone"
              placeholder="12 1234 1234"
              className="ant-input"
            />
          </Form.Item>
          <Form.Item
            className="half-width"
            label="Business Name"
            name="business_name"
            rules={[
              {
                max: 150,
                message: 'Must be less than 150 character'
              },
              {
                pattern: new RegExp(/^[^!@#^%$<>][a-zA-Z\s\d.,&-()]*$/),
                message: `Business name can contain letters, numbers, spaces and some special characters like (& ( ) . , - ')`
              }
            ]}
          >
            <Input placeholder="Business Name" name="business_name" />
          </Form.Item>
          <Form.Item className="half-width" label="ABN" name="abn">
            <InputMask
              mask="99 999 999 999"
              name="abn"
              placeholder="12 123 123 123"
              className="ant-input"
            />
          </Form.Item>
          <Form.Item
            className="half-width"
            label="Street"
            name="street"
            rules={[
              {
                pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                message: `Street can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphen(-), slash(/) & spaces.`
              }
            ]}
          >
            <Input placeholder="Street" name="street" />
          </Form.Item>
          <Form.Item className="half-width" label="Select State" name="state_id">
            <Select
              showSearch
              optionFilterProp="children"
              onSearch={onSearch}
              onChange={handleStateSelect}
              loading={stateLoading}
              placeholder="Please select State"
            >
              {stateList.map((item, i) => (
                <Select.Option key={i} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item className="half-width" label="Select Suburb" name="suburb_id">
            <Select
              showSearch
              optionFilterProp="children"
              onSearch={onSearch}
              loading={suburbLoading}
              placeholder="Please select Suburb"
            >
              {suburbList.map((item, i) => (
                <Select.Option key={i} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className="half-width"
            label="Post Code"
            name="postal_code"
            rules={[
              {
                pattern: new RegExp(/^\d{4}$/),
                message: 'Must be 4 digit number'
              }
            ]}
          >
            <Input placeholder="Post Code" name="postal_code" type="number" />
          </Form.Item>
          <Form.Item
            label="Bank Name"
            name="bank_name"
            className="half-width"
            validateTrigger={['onBlur']}
          >
            <Select
              showSearch
              optionFilterProp="children"
              onSearch={handleBankSearch}
              loading={bankLoading}
              placeholder="Please select Bank"
            >
              {bankOptions.map((item, i) => (
                <Select.Option key={i} value={item.abbreviation}>
                  {item.abbreviation} - {item.bank_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className="half-width"
            label="Account Name"
            name="account_name"
            rules={[
              {
                pattern: new RegExp(/^[^!@#^%$<>][a-zA-Z\s\d.,&-()]*$/),
                message: `Account name can contain letters, numbers, spaces and some special characters like (& ( ) . , - ')`
              }
            ]}
          >
            <Input placeholder="Account Name" name="account_name" />
          </Form.Item>
          <Form.Item className="half-width" label="BSB" name="bsb">
            <InputMask mask="999-999" name="bsb" placeholder="123-456" className="ant-input" />
          </Form.Item>
          <Form.Item
            className="half-width"
            label="Account Number"
            name="account_number"
            validateTrigger={['onBlur']}
            rules={[
              {
                pattern: new RegExp(/^\d{0,9}$/),
                message: 'Must be less than 10 digit number'
              },
              {
                validator: (_, value) => {
                  if (
                    (/^\d{9}?$/.test(value) && /(\d)\1{8}/.test(value)) ||
                    (/^\d{8}?$/.test(value) && /(\d)\1{7}/.test(value)) ||
                    (/^\d{7}?$/.test(value) && /(\d)\1{6}/.test(value)) ||
                    (/^\d{6}?$/.test(value) && /(\d)\1{5}/.test(value)) ||
                    (/^\d{5}?$/.test(value) && /(\d)\1{4}/.test(value)) ||
                    (/^\d{4}?$/.test(value) && /(\d)\1{3}/.test(value)) ||
                    (/^\d{3}?$/.test(value) && /(\d)\1{2}/.test(value)) ||
                    (/^\d{2}?$/.test(value) && /(\d)\1{1}/.test(value))
                  ) {
                    return Promise.reject(
                      'Please enter valid Account Number. All digits of same number are not allowed.'
                    );
                  } else {
                    return Promise.resolve();
                  }
                }
              }
            ]}
          >
            <Input placeholder="Account Number" name="account_number" />
          </Form.Item>

          <Form.Item
            className="full-width"
            label="Description"
            name="description"
            validateTrigger={['onBlur']}
            rules={[
              {
                pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                message:
                  'Description can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.'
              }
            ]}
          >
            <TextArea
              rows={4}
              placeholder="Description"
              name="description"
              style={{ borderRadius: '10px' }}
            />
          </Form.Item>
        </div>
        <div className="action-box">
          <Button type="primary" htmlType="submit">
            Save
          </Button>
          <Button
            className="reset"
            variant="secondary"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
        </div>
      </Form>
      <Modal
        className="small-modal"
        show={newClientModal}
        onHide={handleAddNewClient}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>Add New Contact</Modal.Header>
        <Modal.Body>
          <div className="content-details">
            {loading && <Spin />}
            <Form
              name="officeForm"
              initialValues={{ first_name: '', last_name: '', tfn: '', email: '' }}
              layout="vertical"
              onFinish={handleAddNewClientSubmit}
            >
              <div className="input-boxes">
                <>
                  <Form.Item
                    className="full-width"
                    label="First Name"
                    name="first_name"
                    rules={[
                      {
                        max: 32,
                        message: 'Must be less than 32 character'
                      },
                      {
                        pattern: new RegExp(/^[a-zA-Z\s]*$/),
                        message: 'First name can contain letters & spaces.'
                      }
                    ]}
                  >
                    <Input placeholder="First Name" />
                  </Form.Item>
                  <Form.Item
                    className="full-width"
                    label="Last Name Or Trading Name"
                    name="last_name"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Last name'
                      },
                      {
                        max: 150,
                        message: 'Must be less than 150 character'
                      },
                      {
                        pattern: new RegExp(/^[^!@#^%$<>][a-zA-Z\s\d.,&()]*$/),
                        message: `Last name can contain letters, numbers, spaces and some special characters like (& ( ) . , - ')`
                      }
                    ]}
                  >
                    <Input /*onChange={handleLastName}*/ placeholder="Last Name" />
                  </Form.Item>
                  <Form.Item
                    className="full-width"
                    label="TFN"
                    name="tfn"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter TFN'
                      },
                      {
                        pattern: new RegExp(/^([0-9]+$)([ .-]?)/),
                        message: 'Please enter valid TFN'
                      },
                      {
                        min: 8,
                        message: 'Minimum 8 digits are required in TFN'
                      },
                      {
                        max: 9,
                        message: 'Maximum 9 digits are allowed in TFN'
                      },
                      {
                        validator: (_, value) => {
                          if (/^\d{8}?$/.test(value) && /(\d)\1{7}/.test(value)) {
                            return Promise.reject(
                              'Please enter valid TFN Number. All digits of same number are not allowed.'
                            );
                          } else {
                            if (/^\d{9}?$/.test(value) && /(\d)\1{8}/.test(value)) {
                              return Promise.reject(
                                'Please enter valid TFN Number. All digits of same number are not allowed.'
                              );
                            }
                            return Promise.resolve();
                          }
                        }
                      }
                    ]}
                  >
                    <Input placeholder="123412345" value="" />
                  </Form.Item>
                  <Form.Item
                    className="full-width"
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Email'
                      },
                      {
                        type: 'email',
                        message: 'Please enter valid Email'
                      }
                    ]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                </>
              </div>
              <div className="action-box">
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </div>
            </Form>
          </div>
          <Button
            variant="secondary"
            className="reset"
            onClick={handleAddNewClient}
            style={{ borderRadius: '30px' }}
          >
            Cancel
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default AddEditSupplier;
