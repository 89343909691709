import React, { useState } from 'react';
import { debounce } from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../../App.css';
import '../../../assets/css/style.css';
import { Tabs } from 'antd';
import { Button, Form, FormControl } from 'react-bootstrap';

import ChartAccountList from './ChartAccountList';

const tabsKey = {
  expenses: 1,
  revenue: 2,
  'current-assets': 3,
  'non-current-assets': 4,
  'current-liabilities': 5,
  'non-current-liabilities': 6,
  equity: 7
};

const ChartAccount = () => {
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const selectedTab = query.get('chartAccounts');
  const defaultActiveKey = tabsKey[selectedTab] || 1;
  const activeTab = defaultActiveKey.toString();
  const onTabClick = (activeKey) => {
    const tabName = Object.keys(tabsKey).find((key) => tabsKey[key] === parseInt(activeKey));
    if (tabName) {
      navigate(`/settings?tab=chart-of-accounts&chartAccounts=${tabName}`, {
        replace: true
      });
    }
  };
  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const chartOfAccountsItems = [
    {
      key: '1',
      label: 'Expenses',
      children: (
        <ChartAccountList
          accountId={1}
          isAddingNew={isAddingNew}
          search={search}
          setIsAddingNew={setIsAddingNew}
        />
      )
    },
    {
      key: '2',
      label: 'Revenue',
      children: (
        <ChartAccountList
          accountId={2}
          isAddingNew={isAddingNew}
          search={search}
          setIsAddingNew={setIsAddingNew}
        />
      )
    },
    {
      key: '3',
      label: 'Current Assets',
      children: (
        <ChartAccountList
          accountId={3}
          isAddingNew={isAddingNew}
          search={search}
          setIsAddingNew={setIsAddingNew}
        />
      )
    },
    {
      key: '4',
      label: 'Non-Current Assets',
      children: (
        <ChartAccountList
          accountId={4}
          isAddingNew={isAddingNew}
          search={search}
          setIsAddingNew={setIsAddingNew}
        />
      )
    },
    {
      key: '5',
      label: 'Current Liabilities',
      children: (
        <ChartAccountList
          accountId={5}
          isAddingNew={isAddingNew}
          search={search}
          setIsAddingNew={setIsAddingNew}
        />
      )
    },
    {
      key: '6',
      label: 'Non Current Liabilities',
      children: (
        <ChartAccountList
          accountId={6}
          isAddingNew={isAddingNew}
          search={search}
          setIsAddingNew={setIsAddingNew}
        />
      )
    },
    {
      key: '7',
      label: 'Equity',
      children: (
        <ChartAccountList
          accountId={7}
          isAddingNew={isAddingNew}
          search={search}
          setIsAddingNew={setIsAddingNew}
        />
      )
    }
  ];

  return (
    <div className="cms-page chart-of-account-page">
      <div className="content-details">
        <div className="table-top-btn" style={{ alignItems: 'flex-start' }}>
          <div className="search-section">
            <Form>
              <FormControl
                onChange={(e) => debounce(handleChange(e), 300)}
                type="text"
                value={search}
                placeholder="Search Account"
              />
              <Button className="fa fa-search">Search</Button>
            </Form>
          </div>
          <div className="table-btn chart-of-account-new-btn">
            <Button variant="primary" onClick={() => setIsAddingNew(true)}>
              Add New
            </Button>
          </div>
        </div>
        <Tabs
          defaultActiveKey={activeTab}
          size="large"
          style={{ marginBottom: 32 }}
          onTabClick={onTabClick}
          className="setting-tabs"
          items={chartOfAccountsItems}
        ></Tabs>
      </div>
    </div>
  );
};

export default ChartAccount;
