import axios from "axios";

export const download = async (path, defaultName) => {
    const response = await axios.get(
        path,
        { responseType: "blob" }
    );
    const fileData = response.data;
    const url = window.URL.createObjectURL(new Blob([ fileData ]));
    var a = document.createElement("a");
    a.href = url;
    var file = path.split("/");
    a.setAttribute(
        "download",
        file[ file.length - 1 ] || defaultName
    );
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove();
}