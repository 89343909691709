import { Spin } from 'antd';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { disconnectProviderBank } from '../IntegrationHelper';

const DisconnectBank = ({
  toggleDisconnectModal,
  handleDisconnect,
  fetchSalesForceConnectionData,
  disconnectModal
}) => {
  const [loading, setLoading] = useState(false);

  const handleDisconnectBank = async () => {
    try {
      setLoading(true);
      const disconnect = await disconnectProviderBank();
      setLoading(false);
      if (disconnect?.status === 200) {
        toast.success(disconnect?.message);
        fetchSalesForceConnectionData();
        handleDisconnect();
      }
    } catch (e) {
      setLoading(false);
      return null;
    }
  };
  return (
    <Modal
      size="lg"
      show={disconnectModal}
      onHide={toggleDisconnectModal}
      dialogClassName="modal-50w medium-popup review-popup small-review"
      aria-labelledby="contained-modal-title-vcenter"
      className="remove-popup"
      centered
    >
      <Modal.Header className="mb-0" closeButton>
        Disconnect Bank Feeds
      </Modal.Header>
      <Modal.Body>
        <div className="remove-content spin-body-relative disconnect-bank-modal">
          <p>Are your sure you want to disconnect this bank feeds?</p>

          <div className="modal-action ">
            <Button
              type="button"
              className="cancel"
              data-dismiss="modal"
              onClick={() => toggleDisconnectModal()}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="btn"
              data-dismiss="modal"
              onClick={() => {
                handleDisconnectBank();
              }}
            >
              OK
            </Button>
          </div>
        </div>
        {loading && (
          <div className="modal-spin-center ">
            <Spin />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default DisconnectBank;
