import { Table, Select } from 'antd';
import { debounce } from 'lodash';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Form, FormControl } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import BackButton from '../../components/Form/BackButton';
import SidebarNav from '../../components/Header/SidebarNav';
import { PaginationItemRenderer } from '../../shared/PaginationItemRenderer';
import { getReceiptsColumn } from './ReceiptsHelper';
import useReceiptsList from './useReceiptsList';
import { defaultPaginationLimit } from '../../config';

const Receipt = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const [limit, setLimit] = useState(
    query.get('limit') ? query.get('limit') : defaultPaginationLimit
  );
  const search = query.get('search');
  const currentPage = query.get('page') ? parseInt(query.get('page')) : 1;

  const {
    receiptsList,
    pagingData,
    searchParam,
    onSearchChange,
    onPageChange,
    isDataLoading,
    fetchreceiptsList
  } = useReceiptsList(currentPage, search);

  const [receiptsData, setReceiptsData] = useState([]);

  useEffect(() => {
    setReceiptsData(receiptsList);
  }, [receiptsList]);

  const handleChange = async (value) => {
    setLimit(value);
    navigate(`/receipts?page=1&limit=${value}`, {
      replace: true
    });
    fetchreceiptsList(value, 1);
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <SidebarNav />
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            Trust Receipts
          </h1>
          <div className="content-details">
            <div className="table-top-btn">
              <div className="search-section">
                <Form>
                  <FormControl
                    onChange={(e) => debounce(onSearchChange(e), 300)}
                    type="text"
                    value={searchParam}
                    placeholder="Search"
                  />
                  <Button className="fa fa-search">Search</Button>
                </Form>
              </div>
              <div className="table-btn">
                <Button
                  variant="primary"
                  onClick={() => {
                    navigate('/record-receipt');
                  }}
                >
                  New Receipt
                </Button>
              </div>
            </div>
            <div className="ato-refund" style={{ width: '100%', height: '88%' }}>
              <Table
                rowKey={(record) => record.key}
                columns={getReceiptsColumn(fetchreceiptsList)}
                dataSource={receiptsData}
                loading={isDataLoading}
                pagination={{
                  hideOnSinglePage: true,
                  defaultCurrent: 1,
                  showQuickJumper: true,
                  onChange: (page) => {
                    onPageChange(page, limit);
                  },
                  itemRender: PaginationItemRenderer,
                  pageSize: limit ? limit : defaultPaginationLimit,
                  ...pagingData
                }}
              />
              <div>
                {receiptsData?.length > 0 && (
                  <div className="col-md-12">
                    Showing&nbsp;
                    <Select
                      defaultValue={`${defaultPaginationLimit}`}
                      value={query.get('limit') ? query.get('limit') : `${defaultPaginationLimit}`}
                      onChange={handleChange}
                      options={[
                        { value: '10', label: '10' },
                        { value: '25', label: '25' },
                        { value: '50', label: '50' },
                        { value: '100', label: '100' }
                      ]}
                    />
                    &nbsp;Records per page I Showing {pagingData?.from} to&nbsp;
                    {pagingData?.to} from {pagingData?.total} Records
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Receipt;
