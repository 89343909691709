import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Input, Select, Spin, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { toast } from 'react-toastify';
import Form from '@component/Form';
import Footer from '../../../components/Footer/Index.js';
import BackButton from '../../../components/Form/BackButton.js';
import { API, imageDefaultPrefixPath, fileUpload } from '../../../config.js';
import { getAdminUserDetail, useRoleList } from './AdminUserConsoleHelper.js';
import InputMask from 'react-input-mask';
import faqicon from '../../../assets/images/bulb-icon.jpg';
import { Button } from 'react-bootstrap';

const getSrcFromFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.originFileObj);
    reader.onload = () => resolve(reader.result);
  });
};

const AdminUserEdit = () => {
  const [form] = Form.useForm();
  const existingAdminUserEmail = localStorage.getItem('myna-auth-email');
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const adminUserId = query.get('id');
  const [adminUserData, setAdminUserData] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [loading, setIsLoading] = useState();

  const { roleDataLoading, roleList } = useRoleList();

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAdminUserDetail(adminUserId);
        setAdminUserData(data);
        if (data.profile_pic) {
          setFileList([
            {
              url: `${imageDefaultPrefixPath}${data.profile_pic}`,
              status: 'done'
            }
          ]);
        }
      } catch (e) {
        setAdminUserData(null);
      }
    }
    fetchData();
  }, [adminUserId]);

  const getInitialValues = useMemo(() => {
    if (!adminUserData) return {};
    return {
      ...adminUserData,
      phone: adminUserData?.phone ? adminUserData?.phone : '',
      role_id: adminUserData?.role_id
    };
  }, [adminUserData]);

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };

  const dummyRequest = ({ file, onSuccess }) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setFileList([
        {
          uid: new Date().getMilliseconds(),
          name: file.name,
          status: 'done',
          url: reader.result,
          file: file
        }
      ]);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const handleFinish = async (values) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      if (fileList && fileList.length && fileList[0]?.file) {
        formData.append('profile_pic', fileList[0]?.file);
      } else if (fileList && fileList.length && fileList[0].url) {
        formData.append('profile_pic', fileList[0].url);
      }
      Object.keys(values).forEach((key) => {
        if (key === 'phone') {
          formData.append([key], values[key].replaceAll('_', ''));
        } else {
          formData.append([key], values[key]);
        }
      });
      formData.append('_method', 'PUT');
      const { data } = await fileUpload(`${API.UPDATE_ADMIN_USER}/${adminUserId}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setIsLoading(false);
      toast.success(data.message);
      navigate(-1);
    } catch (e) {
      setIsLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const props = {
    name: 'file',
    multiple: false,
    fileList,
    listType: 'picture'
  };

  function onSearch() {}

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block admin-list-table">
          {!adminUserData ? (
            <Spin />
          ) : (
            <>
              <h1 className="page-title">
                <BackButton />
                {adminUserData?.first_name} {adminUserData?.last_name}{' '}
                <Link to={{ pathname: '/help' }}>
                  <img src={faqicon} />
                </Link>
              </h1>
              <div className="content-details spin-body-relative">
                {loading && (
                  <div className="modal-spin-center">
                    <Spin />
                  </div>
                )}
                <div className="entry-section">
                  <Form
                    form={form}
                    name="editAdminUserForm"
                    initialValues={getInitialValues}
                    onFinish={handleFinish}
                    layout="vertical"
                  >
                    <div className="entry-card full-card">
                      <h3>Personal Details</h3>
                      <div className="form-box">
                        <div className="image-box">
                          <ImgCrop grid>
                            <Upload
                              {...props}
                              customRequest={(f) => dummyRequest(f)}
                              onChange={onChange}
                              onPreview={onPreview}
                            >
                              Edit
                            </Upload>
                          </ImgCrop>
                        </div>
                        <div className="input-boxes">
                          <Form.Item
                            label="First Name"
                            name="first_name"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter First name'
                              },
                              {
                                max: 32,
                                message: 'Must be less than 32 character'
                              },
                              {
                                pattern: new RegExp(/^[a-zA-Z\s]*$/),
                                message: 'First name can contain letters & spaces.'
                              }
                            ]}
                          >
                            <Input placeholder="First Name" />
                          </Form.Item>
                          <Form.Item
                            label="Last Name"
                            name="last_name"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter Last name'
                              },
                              {
                                max: 32,
                                message: 'Must be less than 32 character'
                              },
                              {
                                pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,&']*$/),
                                message:
                                  "Last name can contain letters, numbers, ampersand(&), dot(.), comma(,), Apostrophe(') & spaces."
                              }
                            ]}
                          >
                            <Input placeholder="Last Name" />
                          </Form.Item>
                          <Form.Item label="Phone Number" name="phone">
                            <InputMask
                              mask="99 9999 9999"
                              name="phone"
                              placeholder="12 1234 1234"
                              className="ant-input"
                            />
                          </Form.Item>
                          <Form.Item
                            label="Email"
                            name="email"
                            d
                            rules={[
                              {
                                required: true,
                                message: 'Please enter Email'
                              },
                              {
                                type: 'email',
                                message: 'The input is not valid E-mail!'
                              }
                            ]}
                          >
                            <Input
                              disabled={existingAdminUserEmail === adminUserData?.email}
                              placeholder="Email"
                            />
                          </Form.Item>
                          <Form.Item
                            label="Role"
                            name="role_id"
                            rules={[
                              {
                                required: true,
                                message: 'Please select Role'
                              }
                            ]}
                          >
                            <Select
                              showSearch
                              optionFilterProp="children"
                              disabled={existingAdminUserEmail === adminUserData?.email}
                              onSearch={onSearch}
                              loading={roleDataLoading}
                            >
                              {roleList.map((item, i) => (
                                <Select.Option key={i} value={item.id}>
                                  {item.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div
                      className="action-box"
                      style={{
                        paddingTop: '20px'
                      }}
                    >
                      <Button type="primary" htmlType="submit" disabled={loading}>
                        {loading ? 'Loading...' : 'Save'}
                      </Button>
                      <Button className="reset" onClick={() => navigate(-1)} disabled={loading}>
                        Cancel
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AdminUserEdit;
