import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { PaginationItemRenderer } from "../../shared/PaginationItemRenderer";
import { defaultPaginationLimit } from "../../config";

const NestedTable = ({
  expandedRowColumns,
  mainTableColumns,
  mainTableData,
  pagination,
  loading,
  getExpandedRowData,
  initialExpandedRows,
  initialExpandedData,
  expandedCurrentPage,
  expandedTotalData,
  expandedPageSize,
}) => {
  const [expandedData, setExpandedData] = useState(initialExpandedData || []);
  const [rowLoading, setRowLoading] = useState(false);
  const [expandedRows, setExpandedRows] = useState(initialExpandedRows || []);
  const [paginatedData, setPaginatedData] = useState({
    current: expandedCurrentPage || 1,
    total: expandedTotalData || 0,
    pageSize: expandedPageSize || defaultPaginationLimit,
  });

  const onPageChange = (page) => {
    setPaginatedData({ ...paginatedData, current: page });
  };

  useEffect(() => {
    if (expandedTotalData && expandedCurrentPage && expandedPageSize) {
      setPaginatedData({
        total: expandedTotalData,
        current: expandedCurrentPage,
        pageSize: expandedPageSize,
      });
    }
  }, [expandedTotalData, expandedCurrentPage, expandedPageSize]);
  const expandedRowRender = () => {
    return (
      <Table
        columns={expandedRowColumns}
        loading={rowLoading}
        dataSource={expandedData}
        pagination={{
          defaultPageSize: paginatedData.pageSize,
          defaultCurrent: 1,
          showQuickJumper: true,
          itemRender: PaginationItemRenderer,
          onChange: (page) => onPageChange(page),
          ...paginatedData,
        }}
      />
    );
  };

  const onExpand = async (expanded, record) => {
    const expandedRows = [];
    if (expanded) {
      setExpandedData([]);
      expandedRows.push(record.id);
      setExpandedRows(expandedRows);
      setRowLoading(true);
      const data = await getExpandedRowData(record.id);
      setExpandedData(data);
      setPaginatedData({
        total: expandedTotalData,
        current: expandedCurrentPage,
        pageSize: expandedPageSize,
      });
      setRowLoading(false);
    } else {
      setExpandedRows([]);
      setRowLoading(false);
    }
  };

  const columns = mainTableColumns;
  return (
    <Table
      className="components-table-demo-nested"
      loading={loading}
      pagination={pagination}
      columns={columns}
      expandable={{ expandedRowRender, onExpand }}
      dataSource={mainTableData}
      expandedRowKeys={expandedRows}
    />
  );
};

export default NestedTable;
