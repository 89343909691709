import { useEffect, useCallback, useState } from "react";

import { API, get } from "../../../config";
import { maskPostalCode } from "../../../helper/fullNameGetter";

export const mainTableColumns = () => {
  return [
    {
      title: "ID",
      dataIndex: "id",
      width: "10%",
      className: "centeralign",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Suburb",
      dataIndex: "office_suburb",
      sorter: (a, b) => a.office_suburb.localeCompare(b.office_suburb),
    },
    {
      title: "State",
      dataIndex: "office_state",
      sorter: (a, b) => a.office_state.localeCompare(b.office_state),
    },
    {
      title: "Street",
      dataIndex: "street",
      sorter: (a, b) => a.street.localeCompare(b.street),
    },
    {
      title: "Post Code",
      dataIndex: "postal_code",
      key: "postal_code",
      sorter: (a, b) => a.postal_code - b.postal_code,
      render: (record) => {record ? maskPostalCode(Number(record)) : '-'}
    },
    {
      title: "Action",
      width: "10%",
      className: "centeralign",
    },
  ];
};

export const useStateList = () => {
  const [stateList, setStateList] = useState([]);
  const [stateLoading, setStateLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setStateLoading(true);
      const { data } = await get(API.GET_STATE_LIST);
      setStateLoading(false);
      setStateList(data.data);
    } catch (e) {
      setStateList([]);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    stateList,
    stateLoading,
  };
};

export const useSuburbList = (stateId) => {
  const [suburbList, setSuburbList] = useState([]);
  const [selectedState, setSelectedState] = useState();
  const [suburbLoading, setSuburbLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      if (selectedState || stateId) {
        setSuburbLoading(true);
        const { data } = await get(
          `${API.GET_SUBURB_LIST}/${selectedState || stateId}`
        );
        setSuburbLoading(false);
        setSuburbList(data.data);
      }
    } catch (e) {
      setSuburbList([]);
    }
  }, [selectedState, stateId]);

  useEffect(() => {
    init();
  }, [init]);

  return {
    suburbList,
    handleStateSelect: (value) => {
      setSelectedState(value);
      setSuburbList([]);
    },
    suburbLoading,
    setSuburbList,
    selectedState,
  };
};
