/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Empty, Image, Modal, Spin } from 'antd';
import { getAdminUserDetail } from './AdminUserConsoleHelper';
import { imageDefaultPrefixPath } from '../../../config';
import moment from 'moment';

const AdminUserDetailPopup = ({ adminUserId, toggleShowModal }) => {
  const [adminUserDetail, setAdminUserDetail] = useState(null);
  const [adminUserActivity, setAdminUserActivity] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchData() {
      const adminUserDetailData = await getAdminUserDetail(adminUserId, true);
      setAdminUserDetail(adminUserDetailData.data);
      setAdminUserActivity(adminUserDetailData.activity);
      setLoading(false);
    }
    fetchData();
  }, [adminUserId]);

  return (
    <div>
      <Modal
        title=""
        centered
        open
        footer={null}
        width={1000}
        closable
        className="modal-body"
        onCancel={toggleShowModal}
      >
        {loading ? (
          <div className="centeralign spin-padding">
            <Spin />
          </div>
        ) : adminUserDetail ? (
          <>
            <div className="modal-content-details justify-center">
              <div className="profile full">
                {adminUserDetail.profile_pic == null || adminUserDetail.profile_pic == '' ? (
                  <span>{adminUserDetail.first_name.charAt(0)}</span>
                ) : (
                  <Image src={`${imageDefaultPrefixPath}${adminUserDetail.profile_pic}`} />
                )}
                <h5>{adminUserDetail.first_name + ' ' + adminUserDetail.last_name}</h5>
              </div>
            </div>
            <div className="modal-content">
              <h3>Personal Details</h3>
              <div className="modal-content-details">
                <div className="modal-content-left">
                  <p>
                    <strong>First Name:</strong>{' '}
                    {adminUserDetail.first_name ? adminUserDetail.first_name : ''}
                  </p>
                  <p>
                    <strong>Phone Number:</strong>{' '}
                    {adminUserDetail.phone ? adminUserDetail.phone : ''}
                  </p>
                </div>
                <div className="modal-content-right">
                  <p>
                    <strong>Last Name:</strong>{' '}
                    {adminUserDetail.last_name ? adminUserDetail.last_name : ''}
                  </p>
                  <p>
                    <strong>Email Address:</strong>{' '}
                    {adminUserDetail.email ? adminUserDetail.email : ''}
                  </p>
                  <p>
                    <strong>Role:</strong>{' '}
                    {adminUserDetail?.role?.name ? adminUserDetail?.role?.name : ''}
                  </p>
                </div>
              </div>

              {adminUserActivity && adminUserActivity.length > 0 && (
                <>
                  <h3>Recent Activity</h3>
                  <div className="modal-content-details">
                    <ul>
                      {adminUserActivity.slice(0, 5).map((activity) => (
                        <li key={activity.id}>
                          <span className="date">
                            {moment(activity?.created_at, 'DD-MM-YYYY HH:mm:ss').format(
                              'DD-MM-YYYY HH:mm:ss'
                            )}
                          </span>
                          <span
                            style={{ marginLeft: '10px' }}
                            dangerouslySetInnerHTML={{ __html: activity.activity }}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Modal>
    </div>
  );
};

export default AdminUserDetailPopup;
