import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from 'antd';
import '../../App.css';
import '../../assets/css/style.css';
const Setting = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {}, []);

  const redirectLogin = () => {
    if (location?.state?.islogin) {
      navigate('/settings?tab=subscription&childTab=current-subscription', { replace: true });
    } else {
      navigate('/');
    }
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="congrats-block">
          <h3>Congratulations</h3>
          <p>{location?.state?.message ? location?.state?.message : ''}</p>
          <Button className="btn-primary btn" onClick={redirectLogin}>
            {location?.state?.islogin ? 'Check your plan' : 'Login'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Setting;
