import React from 'react';
import logo from '../../assets/images/trusteasy-logo.svg';
import { checkRole } from '../../helper/utils';
import { useNavigate } from 'react-router-dom';

const Sitelogo = () => {
  const navigate = useNavigate();
  const roleType = localStorage.getItem('role_type');
  return (
    <div className="sitelogo">
      <img
        src={logo}
        alt="TrustEasy"
        style={{ maxWidth: '300px', cursor: 'pointer' }}
        onClick={() =>
          checkRole(roleType) ? navigate('/admin/dashboard') : navigate('/dashboard')
        }
      />
    </div>
  );
};

export default Sitelogo;
