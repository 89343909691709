import React, { useCallback, useState } from 'react';
import { Modal, Select, Table, Tooltip } from 'antd';
import { API, defaultPaginationLimit, get } from '../../../config';
import '../../../App.css';
import '../../../assets/css/style.css';
import '../../users/users.module.css';
import { PaginationItemRenderer } from '../../../shared/PaginationItemRenderer';
import { useEffect } from 'react';
import { faDownload, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { formatNumber } from '../../../APIConfig/Interceptor';

const ExpandRegisteredActionButton = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [showInvoice, setShowInvoice] = useState(false);
  const [invoiceData, setInvoiceData] = useState([]);
  let [limit, setLimit] = useState();
  let [currpage, setCurrPage] = useState();

  const [pagingData, setPagingData] = useState({ current: 1 });

  const toggleInvoiceModal = () => {
    fetchInvoice();
    setShowInvoice(!showInvoice);
  };

  const downloadLetter = async (path) => {
    try {
      if (path) {
        var a = document.createElement('a');
        a.href = path;
        var file = path.split('/');
        a.setAttribute('download', file[file.length - 1] || 'new_invoice.pdf');
        document.body.appendChild(a);
        a.click();
        a.remove();
      } else {
        toast.error('File is not exist');
      }
    } catch (e) {
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const getTableColumns = () => {
    return [
      {
        key: 'invoice_date',
        title: 'Invoice Date',
        render: (record) => <span>{record?.effectiveAt ? record?.effectiveAt : '-'}</span>
      },
      {
        title: 'Amount',
        key: 'subtotal',
        render: (record) => (
          <span>{record?.subtotal ? `$${formatNumber(record?.subtotal)}` : '$0.00'}</span>
        )
      },
      {
        title: 'GST',
        key: 'totalTaxAmount',
        render: (record) => (
          <span>
            {record?.totalTaxAmount ? `$${formatNumber(record?.totalTaxAmount)}` : '$0.00'}
          </span>
        )
      },
      {
        title: 'Discount',
        key: 'totalDiscountAmount',
        render: (record) => (
          <span>
            {record?.totalDiscountAmount
              ? `$${formatNumber(record?.totalDiscountAmount)}`
              : '$0.00'}
          </span>
        )
      },
      {
        title: 'Total Amount',
        render: (record) => (
          <span>
            {record?.subtotal
              ? `$${formatNumber(
                  Number(
                    Number(Number(record?.subtotal) + Number(record?.totalTaxAmount)) -
                      Number(record?.totalDiscountAmount)
                  )
                )}`
              : '$0.00'}
          </span>
        )
      },
      {
        title: 'Action',
        width: '5%',
        key: 'id',
        dataIndex: 'id',
        align: 'center',
        render: (id, item) => (
          <div className="action-column">
            <Tooltip placement="bottomLeft" title={'Download'}>
              <FontAwesomeIcon
                icon={faDownload}
                size="1x"
                onClick={() => {
                  downloadLetter(item.invoicePdf);
                }}
              />
            </Tooltip>
            <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
            <Tooltip placement="bottomLeft" title={'View Invoice'}>
              <FontAwesomeIcon
                icon={faEye}
                size="1x"
                onClick={() => {
                  window.open(
                    item?.hostedInvoiceUrl,
                    '_blank' // <- This is what makes it open in a new window.
                  );
                }}
              />
            </Tooltip>
          </div>
        )
      }
    ];
  };

  const fetchInvoice = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await get(
        `${API.EXPAND_REGISTERD_FIRM_INVOICE_LIST}${id}?page=${currpage ?? 1}&limit=${
          limit ?? defaultPaginationLimit
        }`
      );
      setInvoiceData(data?.data?.data);
      setLoading(false);
      setPagingData({
        total: data?.data?.total,
        current: data?.data?.current_page,
        defaultPageSize: data?.data?.per_page,
        from: data?.data?.from,
        to: data?.data?.to
      });
    } catch (e) {
      setInvoiceData([]);
      setLoading(false);
    }
  }, [limit, currpage]);

  const handlePageChange = async (pageSize, limit) => {
    setCurrPage(pageSize);
    setLimit(limit);
  };

  const handleChange = async (value) => {
    setCurrPage(1);
    setLimit(Number(value));
  };

  useEffect(() => {
    // fetchActivity();
  }, [currpage, limit]);

  return (
    <span className="">
      <FontAwesomeIcon
        title="View Invoices"
        onClick={toggleInvoiceModal}
        icon={faEye}
        size="1x"
        className='admin-registered-invoice-action'
      />

      {showInvoice && (
        <Modal
          className="remove-popup"
          width={1000}
          closable
          centered
          open
          footer={null}
          onCancel={toggleInvoiceModal}
        >
          <div
            className="activity-content content-details admin-registered-invoice-modal"
          >
            <Table
              rowKey={(record) => record.id}
              columns={getTableColumns()}
              dataSource={invoiceData}
              loading={loading}
              pagination={{
                hideOnSinglePage: true,
                defaultCurrent: 1,
                showQuickJumper: true,
                onChange: (page, pageSize) => {
                  handlePageChange(page, pageSize);
                },
                itemRender: PaginationItemRenderer,
                ...pagingData
              }}
            />
            <div>
              {invoiceData?.length > 0 && (
                <div className="col-md-12">
                  Showing&nbsp;
                  <Select
                    defaultValue={`${defaultPaginationLimit}`}
                    onChange={handleChange}
                    options={[
                      { value: '10', label: '10' },
                      { value: '25', label: '25' },
                      { value: '50', label: '50' },
                      { value: '100', label: '100' }
                    ]}
                  />
                  &nbsp;Records per page I Showing {pagingData?.from} to&nbsp;
                  {pagingData?.to} from {pagingData?.total} Records
                </div>
              )}
            </div>
          </div>
        </Modal>
      )}
    </span>
  );
};

export default ExpandRegisteredActionButton;
