import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Form from '@component/Form';
import { API, fileUpload, get, imageDefaultPrefixPath } from '../../config.js';
import { Input, Button, Upload } from 'antd';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import ImgCrop from 'antd-img-crop';
import '../users/users.module.css';

const getSrcFromFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.originFileObj);
    reader.onload = () => resolve(reader.result);
  });
};
const MyProfile = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [fileList, setFileList] = useState([]);
  const [profileData, setProfileData] = useState(null);

  const getProfileData = useCallback(async () => {
    try {
      const { data } = await get(API.GET_USER_PROFILE);
      const profileObj = data;
      if (profileObj) {
        const initialObj = {
          ...profileObj
        };
        if (profileObj.profile_pic) {
          setFileList([
            {
              url: `${imageDefaultPrefixPath}${profileObj.profile_pic}`,
              status: 'done'
            }
          ]);
        }
        setProfileData(initialObj);
      }
    } catch (e) {
      setProfileData({});
    }
  }, []);

  useEffect(() => {
    getProfileData();
  }, [getProfileData]);

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };

  const dummyRequest = ({ file, onSuccess }) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setFileList([
        {
          uid: new Date().getMilliseconds(),
          name: file.name,
          status: 'done',
          url: reader.result,
          file: file
        }
      ]);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const changeEmail = () => {
    navigate('/2fa/change?item=email');
  };

  const handleAuthentication = () => {
      navigate('/2fa/register?page=back');

  };

  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      if (fileList[0]?.file) formData.append('profile_pic', fileList[0]?.file);
      Object.keys(values).forEach((key) => {
        formData.append([key], values[key]);
      });
      const { data } = await fileUpload(API.UPDATE_PROFILE, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      const { data: profileData } = await get(API.GET_USER_PROFILE);
      const { first_name, profile_pic } = profileData;
      localStorage.setItem('Name', first_name);
      localStorage.setItem('ProfilePhoto', profile_pic);
      toast.success(data.message);
      navigate('/user-settings?tab=myprofile', { replace: true });
    } catch (e) {
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };
  const props = {
    name: 'file',
    multiple: false,
    fileList,
    listType: 'picture',
    showUploadList: {
      showRemoveIcon: false
    }
  };
  if (!profileData) return null;
  return (
    <div className="content-details">
      <div className="entry-card full-card">
        <Form
          form={form}
          name="profileform"
          onFinish={handleSubmit}
          initialValues={profileData}
          layout="vertical"
        >
          <div className="form-box profile-form">
            <div className="image-box">
              <label>Profile Photo</label>
              <ImgCrop grid>
                <Upload
                  {...props}
                  customRequest={(f) => dummyRequest(f)}
                  onChange={onChange}
                  onPreview={onPreview}
                >
                  Edit
                </Upload>
              </ImgCrop>
            </div>
            <div className="input-boxes">
              <Form.Item
                label="First Name"
                name="first_name"
                validateTrigger={['onBlur']}
                rules={[
                  {
                    required: true,
                    message: 'Please enter First Name'
                  },
                  {
                    max: 32,
                    message: 'Must be less than 32 character'
                  },
                  {
                    pattern: new RegExp(/^[a-zA-Z\s]*$/),
                    message: 'First name can contain letters & spaces.'
                  }
                ]}
              >
                <Input placeholder="First Name" name="first_name" />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="last_name"
                validateTrigger={['onBlur']}
                rules={[
                  {
                    required: true,
                    message: 'Please enter Last Name'
                  },
                  {
                    max: 150,
                    message: 'Must be less than 150 character'
                  },
                  {
                    pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,&']*$/),
                    message:
                      "Last name can contain letters, numbers, ampersand(&), dot(.), comma(,), Apostrophe(') & spaces."
                  }
                ]}
              >
                <Input placeholder="First Name" name="last_name" />
              </Form.Item>
              <Form.Item
                label="Phone Number"
                name="phone"
                validateTrigger={['onBlur']}
              >
                <InputMask
                  mask="99 9999 9999"
                  name="phone"
                  placeholder="12 1234 1234"
                  className="ant-input"
                />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                validateTrigger={['onBlur']}
                rules={[
                  {
                    required: true,
                    message: 'Please enter Email Address'
                  },
                  { whitespace: false, message: 'required' }
                ]}
              >
                <Input placeholder="Email" name="email" disabled />
              </Form.Item>
              <div className="auth-form">
                <div className="auth-title">
                  <h2>Two-Factor Authentication</h2>
                  <Button className="btn" onClick={handleAuthentication}>
                    {profileData.google2fa_enable == 1 ? 'Reset' : 'Set up'}
                  </Button>
                </div>
                {profileData.google2fa_enable == 1 ? (
                  <>
                    <Form.Item
                      label="Backup Email Address"
                      name="backup_email"
                      validateTrigger={['onBlur']}
                    >
                      <Input autoFocus placeholder="Backup Email Address" name="backup_email" disabled />
                    </Form.Item>
                    <Form.Item
                      label=""
                      name="change_email"
                      validateTrigger={['onBlur']}
                      className="change-email"
                    >
                      <Button name="changeEmail" className="btn" onClick={changeEmail}>
                        change
                      </Button>
                    </Form.Item>
                    <Form.Item label="Authentication Method">
                      <h4>Google Authenticator App</h4>
                    </Form.Item>
                  </>
                ) : (
                  <p>
                    Multi-factor authentication adds a second layer of security to your account to
                    help prevent unauthorised access.
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="action-box">
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};
export default MyProfile;
