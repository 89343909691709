/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Spin, Tabs, Modal, DatePicker, Space, Tag } from 'antd';
import { Accordion } from 'react-bootstrap';
import moment from 'moment';
import { Select } from 'antd';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Footer from '../../components/Footer/Index';
import useDashboardList from './useDashboardList';
import SidebarNav from '../../components/Header/SidebarNav';
import '../../assets/css/style.css';
import ReturnAssessed from './ReturnAssessed';
import UnderReview from './UnderReview';
import Processed from './Processed';
import { sortData } from './DashboardHelper';
import { API, get } from '../../config';
import DialogModal from '../../components/Header/DialogModal';
import { helpCenterArray } from '../HelpCenter';
import { formatNumber } from '../../APIConfig/Interceptor';
import OrphanATO from './OrphanATO';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const filterOptions = {
  Today: 'today',
  ['This Week']: 'weekly',
  ['This Month']: 'monthly',
  Custom: 'custom'
};

const dateFormat = 'YYYY-MM-DD';
const disabledDate = (current) => {
  return current && current > moment().endOf('day');
};

const sortOptions = [
  { title: 'Name (A - Z)', value: 'name,asc' },
  { title: 'Name (Z - A)', value: 'name,desc' },
  { title: 'Date (Oldest)', value: 'date,asc' },
  { title: 'Date (Latest)', value: 'date,desc' }
];

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [filterDate, setFilterDate] = useState({
    start_date: localStorage.getItem('startDate') ? localStorage.getItem('startDate') : '',
    end_date: localStorage.getItem('endDate') ? localStorage.getItem('endDate') : ''
  });
  const query = new URLSearchParams(location.search);
  const sortField = query.get('field') || 'date';
  const sortType = query.get('type') || 'desc';
  const infoValue = query.get('info') || false;
  const filterType = query.get('filter');
  const weekStart = moment().startOf('week').format('YYYY-MM-DD');
  const weekEnd = moment().endOf('week').format('YYYY-MM-DD');
  const [filter, setFilter] = useState({
    filter: filterType || 'weekly',
    start_date: filterDate.start_date ? filterDate.start_date : weekStart,
    end_date: filterDate.end_date ? filterDate.end_date : weekEnd
  });
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [sortValue, setSortValue] = useState(
    sortField && sortType ? `${sortField},${sortType}` : 'date,desc'
  );
  const {
    fetchDashboardData,
    returnLodged,
    ATOList,
    returnAssessed,
    underReview,
    completed,
    isDataLoading,
    setReturnLodged,
    setATOList,
    setCompleted,
    setUnderReview,
    setReturnAssessed,
    amountData,
    returnAssessedHasMore,
    ATOListHasMore,
    underReviewHasMore,
    completedHasMore
  } = useDashboardList(1, filter, sortValue);

  useEffect(async () => {
    try {
      if (!infoValue) {
        const { data } = await get(API.GET_FIRM_INFO);
        const firmBankInfo = data?.data?.firm_bank;
        const firmAccountName = firmBankInfo?.account_name;
        const firmAccountNumber = firmBankInfo?.account_number;
        const firmBankName = firmBankInfo?.bank_name;
        const firmBSB = firmBankInfo?.bsb;
        if (
          firmBankInfo === null ||
          firmAccountName === '' ||
          firmAccountName === null ||
          firmAccountNumber === '' ||
          firmAccountNumber === null ||
          firmBankName === '' ||
          firmBankName === null ||
          firmBSB === '' ||
          firmBSB === null
        ) {
          navigate(`/settings?tab=firm-settings`, {
            replace: true
          });
        }
      }
    } catch (e) {
      return null;
    }
  }, []);

  useEffect(() => {
    if (filterType === 'weekly') {
      // Week start and end
      const weekStart = moment().startOf('week').format('YYYY-MM-DD');
      const weekEnd = moment().endOf('week').format('YYYY-MM-DD');

      setFilterDate({
        start_date: weekStart,
        end_date: weekEnd
      });
      localStorage.setItem('startDate', weekStart);
      localStorage.setItem('endDate', weekEnd);
      setFilter({
        filter: 'weekly',
        start_date: weekStart,
        end_date: weekEnd
      });
    }
  }, [filterType]);

  const updateState = (value, type, ATOList, returnAssessed, underReview, completed) => {
    const { returnLodgedData, atoData, returnAssessedData, underReviewData, completedData } = value;

    setReturnLodged({ ...returnLodged, data: returnLodgedData });
    if (type === 'ATO') {
      setATOList({ ...ATOList, data: atoData });
    }
    if (type === 'ReturnAssessed') {
      setReturnAssessed({ ...returnAssessed, data: returnAssessedData });
    }
    if (type === 'UnderReview') {
      setUnderReview({ ...underReview, data: underReviewData });
    }
    if (type === 'Completed') {
      setCompleted({ ...completed, data: completedData });
    }
    if (type === 'Sort') {
      setATOList({ ...ATOList, data: atoData });
      setReturnAssessed({ ...returnAssessed, data: returnAssessedData });
      setUnderReview({ ...underReview, data: underReviewData });
      setCompleted({ ...completed, data: completedData });
    }
  };

  const onSelectChange = (value) => {
    if (value === 'custom') {
      setDatePickerVisible(true);
    } else if (value === 'today') {
      setFilterDate({
        start_date: moment(new Date()).format('YYYY-MM-DD'),
        end_date: moment(new Date()).format('YYYY-MM-DD')
      });
      localStorage.setItem('startDate', moment(new Date()).format('YYYY-MM-DD'));
      localStorage.setItem('endDate', moment(new Date()).format('YYYY-MM-DD'));
      setFilter({
        filter: value,
        start_date: moment(new Date()).format('YYYY-MM-DD'),
        end_date: moment(new Date()).format('YYYY-MM-DD')
      });
    } else if (value === 'weekly') {
      // Week start and end
      const weekStart = moment().startOf('week').format('YYYY-MM-DD');
      const weekEnd = moment().endOf('week').format('YYYY-MM-DD');

      setFilterDate({
        start_date: weekStart,
        end_date: weekEnd
      });
      localStorage.setItem('startDate', weekStart);
      localStorage.setItem('endDate', weekEnd);
      setFilter({
        filter: value,
        start_date: weekStart,
        end_date: weekEnd
      });
    } else if (value === 'monthly') {
      // Month start and end
      const monthStart = moment().startOf('month').format('YYYY-MM-DD');
      const monthEnd = moment().endOf('month').format('YYYY-MM-DD');
      setFilterDate({
        start_date: monthStart,
        end_date: monthEnd
      });
      localStorage.setItem('startDate', monthStart);
      localStorage.setItem('endDate', monthEnd);
      setFilter({
        filter: value,
        start_date: monthStart,
        end_date: monthEnd
      });
    } else {
      setFilterDate({
        start_date: '',
        end_date: ''
      });
      localStorage.removeItem('startDate');
      localStorage.removeItem('endDate');
      setFilter({ filter: value });
    }
    navigate(`/dashboard?field=${sortField}&type=${sortType}&filter=${value}`, { replace: true });
  };

  const onSortChange = (value) => {
    setSortValue(value);
    const data = sortData(value, returnLodged, ATOList, returnAssessed, underReview, completed);
    updateState(data, 'Sort', ATOList, returnAssessed, underReview, completed);
    const splitValue = value.split(',');
    navigate(`/dashboard?field=${splitValue[0]}&type=${splitValue[1]}&filter=${filter.filter}`, {
      replace: true
    });
  };

  const handleDateChange = (dates) => {
    const start_Date = dates && dates[0].format('YYYY-MM-DD');
    const end_Date = dates && dates[1].format('YYYY-MM-DD');
    setFilterDate({
      start_date: start_Date,
      end_date: end_Date
    });
    localStorage.setItem('startDate', start_Date);
    localStorage.setItem('endDate', end_Date);
  };

  const onFilterOk = () => {
    if (filterDate.start_date && filterDate.end_date) {
      setFilter({
        start_date: filterDate.start_date,
        end_date: filterDate.end_date,
        filter: 'custom'
      });
      setDatePickerVisible(false);
    }
  };

  const onAssign = () => {
    fetchDashboardData(filter, sortValue);
  };
  return (
    <div className="cms-page">
      <div className="page-content-block">
        <SidebarNav />
        <div className="full-content-block">
          <h1 className="page-title">
            Dashboard
            <DialogModal
              title="Dashboard"
              p2={helpCenterArray[31].text}
            />
          </h1>
          <div className="dashboard">
            <div className="dashboard-header-wrapper">
              <div className="process-refund-btn">
                <Link
                  to={{ pathname: '/process-refund-individual' }}
                  className="action solid-process-refund-btn"
                >
                  {/* Process refund one by one */}
                  Process Refund
                </Link>
              </div>
              <h2 className="heading">Fee From Refund Summary</h2>
              <div className="dashboard-pager">
                <div className="sort-by">
                  Sort By:
                  <Select defaultValue="name,asc" onSelect={onSortChange} value={sortValue}>
                    {sortOptions.map((ele) => (
                      <Select.Option key={ele.title} value={ele.value}>
                        {ele.title}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div className="filter-by">
                  select time:
                  <Select
                    defaultValue="weekly"
                    value={filter ? filter.filter : ''}
                    onSelect={onSelectChange}
                  >
                    {Object.keys(filterOptions).map((key) => (
                      <Select.Option key={filterOptions[key]} value={filterOptions[key]}>
                        {key}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div className="dashboard-grid-list-view">
              <Space size={[0, 8]} wrap className="dashboard-tags">
                <Tag color="blue">
                  Processed Gross:
                  <span className="amount"> ${formatNumber(amountData.processedGrossAmount)}</span>
                </Tag>
                <Tag color="blue">
                  Processed Fees:
                  <span className="amount"> ${formatNumber(amountData.processedFeesAmount)}</span>
                </Tag>
                <Tag color="blue" className="last-tag">
                  Processed Remittance:
                  <span className="amount">
                    {' '}
                    ${formatNumber(amountData.processedRemittanceAmount)}
                  </span>
                </Tag>
              </Space>

              {isDataLoading ? (
                <Spin />
              ) : (
                <Tabs
                  size="large"
                  style={{ marginBottom: 32 }}
                  activeKey={activeTab}
                  onTabClick={(key) => setActiveTab(key)}
                >
                  <TabPane tab="Kanban View" className="grid-icon" key="1">
                    <div className="grid-view">
                      {/* <div
                      className={
                        returnLodged.total == 0 ? 'dashboard-grid nodata' : 'dashboard-grid'
                      }
                    >
                      <h3>Tax Return Lodged ({returnLodged?.total || 0})</h3>
                      <ul>
                        <ReturnLodged
                          returnLodged={returnLodged}
                          ATOList={ATOList}
                          returnAssessed={returnAssessed}
                          underReview={underReview}
                          processed={completed}
                          sortValue={sortValue}
                          updateState={updateState}
                          onAssign={onAssign}
                          filter={filter}
                        />
                      </ul>
                    </div> */}
                      <div
                        className={ATOList?.total == 0 ? 'dashboard-grid nodata' : 'dashboard-grid'}
                      >
                        <h3>Unallocated Refunds ({ATOList?.total || 0})</h3>
                        <ul>
                          <OrphanATO
                            returnLodged={returnLodged}
                            ATOList={ATOList}
                            returnAssessed={returnAssessed}
                            underReview={underReview}
                            processed={completed}
                            sortValue={sortValue}
                            updateState={updateState}
                            onAssign={onAssign}
                            filter={filter}
                            ATOListHasMore={ATOListHasMore}
                          />
                        </ul>
                      </div>
                      <div
                        className={
                          returnAssessed.total == 0 ? 'dashboard-grid nodata' : 'dashboard-grid'
                        }
                      >
                        <h3>Unprocessed Refunds ({returnAssessed?.total || 0})</h3>
                        <ul>
                          <ReturnAssessed
                            returnLodged={returnLodged}
                            ATOList={ATOList}
                            returnAssessed={returnAssessed}
                            underReview={underReview}
                            processed={completed}
                            sortValue={sortValue}
                            updateState={updateState}
                            onAssign={onAssign}
                            filter={filter}
                            returnAssessedHasMore={returnAssessedHasMore}
                          />
                        </ul>
                      </div>
                      <div
                        className={
                          underReview.total == 0 ? 'dashboard-grid nodata' : 'dashboard-grid'
                        }
                      >
                        <h3>Under Review ({underReview?.total || 0})</h3>
                        <ul>
                          <UnderReview
                            returnLodged={returnLodged}
                            ATOList={ATOList}
                            returnAssessed={returnAssessed}
                            underReview={underReview}
                            processed={completed}
                            sortValue={sortValue}
                            updateState={updateState}
                            onAssign={onAssign}
                            filter={filter}
                            underReviewHasMore={underReviewHasMore}
                          />
                        </ul>
                      </div>
                      <div
                        className={
                          completed.total == 0 ? 'dashboard-grid nodata' : 'dashboard-grid'
                        }
                      >
                        <h3>Processed/Completed ({completed?.total || 0})</h3>
                        <ul>
                          <Processed
                            returnLodged={returnLodged}
                            ATOList={ATOList}
                            returnAssessed={returnAssessed}
                            underReview={underReview}
                            processed={completed}
                            sortValue={sortValue}
                            updateState={updateState}
                            onAssign={onAssign}
                            filter={filter}
                            completedHasMore={completedHasMore}
                          />
                        </ul>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tab="Classic View" className="list-icon" key="2">
                    <div className="list-view">
                      <Accordion defaultActiveKey="0">
                        {/* <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          Tax Return Lodged ({returnLodged?.total || 0})
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            <ReturnLodged
                              returnLodged={returnLodged}
                              ATOList={ATOList}
                              returnAssessed={returnAssessed}
                              underReview={underReview}
                              processed={completed}
                              sortValue={sortValue}
                              updateState={updateState}
                              onAssign={onAssign}
                              filter={filter}
                            />
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item> */}
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            Unallocated Refunds ({ATOList?.total || 0})
                          </Accordion.Header>
                          <Accordion.Body>
                            <ul className="ato-list-view">
                              <OrphanATO
                                returnLodged={returnLodged}
                                ATOList={ATOList}
                                returnAssessed={returnAssessed}
                                underReview={underReview}
                                processed={completed}
                                sortValue={sortValue}
                                updateState={updateState}
                                onAssign={onAssign}
                                filter={filter}
                                ATOListHasMore={ATOListHasMore}
                                grid={false}
                              />
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            Unprocessed Refunds ({returnAssessed?.total || 0})
                          </Accordion.Header>
                          <Accordion.Body>
                            <ul>
                              <ReturnAssessed
                                returnLodged={returnLodged}
                                ATOList={ATOList}
                                returnAssessed={returnAssessed}
                                underReview={underReview}
                                processed={completed}
                                sortValue={sortValue}
                                updateState={updateState}
                                onAssign={onAssign}
                                filter={filter}
                                returnAssessedHasMore={returnAssessedHasMore}
                                grid={false}
                              />
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>
                            Under Review ({underReview?.total || 0})
                          </Accordion.Header>
                          <Accordion.Body>
                            <ul>
                              <UnderReview
                                returnLodged={returnLodged}
                                ATOList={ATOList}
                                returnAssessed={returnAssessed}
                                underReview={underReview}
                                processed={completed}
                                sortValue={sortValue}
                                updateState={updateState}
                                onAssign={onAssign}
                                filter={filter}
                                underReviewHasMore={underReviewHasMore}
                                grid={false}
                              />
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>
                            Processed/Completed ({completed?.total || 0})
                          </Accordion.Header>
                          <Accordion.Body>
                            <ul>
                              <Processed
                                returnLodged={returnLodged}
                                ATOList={ATOList}
                                returnAssessed={returnAssessed}
                                underReview={underReview}
                                processed={completed}
                                sortValue={sortValue}
                                updateState={updateState}
                                onAssign={onAssign}
                                filter={filter}
                                completedHasMore={completedHasMore}
                                grid={false}
                              />
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </TabPane>
                </Tabs>
              )}
            </div>
          </div>
          {datePickerVisible && (
            <Modal
              width={500}
              className="popup-without-header remove-popup"
              closable
              centered
              open
              onOk={onFilterOk}
              onCancel={() => setDatePickerVisible(false)}
            >
              <h4>Select Start and End Date</h4>
              <RangePicker
                onChange={handleDateChange}
                disabledDate={disabledDate}
                defaultValue={[
                  filterDate.start_date ? moment(filterDate.start_date, dateFormat) : '',
                  filterDate.end_date ? moment(filterDate.end_date, dateFormat) : ''
                ]}
                format={['DD-MM-YYYY', 'DD/MM/YYYY']}
              />
            </Modal>
          )}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
