import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Input, Button, Select } from 'antd';
import Form from '@component/Form';
import { useStateList, useSuburbList } from './OfficeHelper';
import { post } from '../../../config.js';
import { maskPostalCode } from '../../../helper/fullNameGetter.js';

const AddEditOffice = ({ onClose, office }) => {
  const [form] = Form.useForm();
  const { stateLoading, stateList = [] } = useStateList();
  const {
    suburbLoading,
    suburbList = [],
    handleStateSelect,
    selectedState
  } = useSuburbList(office?.state_id);
  const isEdit = office?.id;

  const handleSubmit = async (values) => {
    try {
      const url = isEdit ? `/office/${office.id}` : `/office/store`;
      const reqObj = isEdit ? { ...values, _method: 'PUT' } : values;
      const { data } = await post(url, reqObj);
      onClose();
      toast.success(data.message);
    } catch (e) {
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  useEffect(() => {
    if (suburbList.length && selectedState) {
      form.setFieldsValue({ suburb_id: suburbList[0].id });
    }
  }, [form, selectedState, suburbList]);

  function onSearch() {}

  return (
    <div className="content-details">
      <Form
        form={form}
        initialValues={{
          ...office,
          postal_code: office?.postal_code ? maskPostalCode(Number(office?.postal_code)) : ''
        }}
        name="officeForm"
        onFinish={handleSubmit}
        layout="vertical"
      >
        <div className="input-boxes">
          <Form.Item
            className="full-width"
            label="Street"
            name="street"
            validateTrigger={['onBlur']}
            rules={[
              {
                required: true,
                message: 'Please enter Street Address'
              },
              {
                pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                message: `Street can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphen(-), slash(/) & spaces.`
              }
            ]}
          >
            <Input placeholder="Street" name="street" />
          </Form.Item>
          <Form.Item
            className="full-width"
            label="Select State"
            name="state_id"
            rules={[
              {
                required: true,
                message: 'Please select State'
              }
            ]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              onSearch={onSearch}
              onChange={handleStateSelect}
              loading={stateLoading}
              placeholder="Please select State"
            >
              {stateList.map((item, i) => (
                <Select.Option key={i} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className="full-width"
            label="Select Suburb"
            name="suburb_id"
            rules={[
              {
                required: true,
                message: 'Please select Suburb'
              }
            ]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              onSearch={onSearch}
              loading={suburbLoading}
              placeholder="Please select Suburb"
            >
              {suburbList.map((item, i) => (
                <Select.Option key={i} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className="full-width"
            label="Post Code"
            name="postal_code"
            validateTrigger={['onBlur']}
            rules={[
              {
                required: true,
                message: 'Please enter Post Code'
              },
              {
                pattern: new RegExp(/^\d{4}$/),
                message: 'Must be 4 digit number'
              }
            ]}
          >
            <Input placeholder="Post Code" name="postal_code" type="number" />
          </Form.Item>
        </div>
        <div className="action-box">
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
};
export default AddEditOffice;
