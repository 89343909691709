import React, { useEffect, useState } from 'react';
import { Modal, Spin } from 'antd';
import { getSupplierDetail } from './SupplierHelper';

const SupplierDetailPopup = ({ toggleShowModal, supplierId }) => {
  const [loading, setLoading] = useState(true);
  const [supplierDetail, setSupplierDetail] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const supplierDetailRes = await getSupplierDetail(supplierId);
      setSupplierDetail(supplierDetailRes);
      setLoading(false);
    }
    fetchData();
  }, [supplierId]);
  return (
    <div>
      <Modal
        title=""
        centered
        open
        footer={null}
        width={1000}
        closable
        className="modal-body"
        onCancel={toggleShowModal}
      >
        {loading ? (
          <div className="centeralign spin-padding">
            <Spin />
          </div>
        ) : (
          Object.keys(supplierDetail).length > 0 && (
            <>
              <div className="modal-content">
                <br /><br /><br />
                <h3>Supplier Details</h3>
                <div className="modal-content-details">
                  <div className="modal-content-left">
                    <p>
                      <strong>Name:</strong> {supplierDetail.name}
                    </p>
                    <p>
                      <strong>Phone Number:</strong>{' '}
                      {supplierDetail.phone == null || supplierDetail.phone == ''
                        ? 'N/A'
                        : supplierDetail.phone}
                    </p>
                    <p>
                      <strong>Mobile Number:</strong>
                      {supplierDetail.mobile == null || supplierDetail.mobile == ''
                        ? 'N/A'
                        : supplierDetail.mobile}
                    </p>
                    <p className="address-box">
                      <strong>Postal Address:</strong> {supplierDetail.name}
                    </p>
                  </div>
                  <div className="modal-content-right">
                    <p>
                      <strong>Business:</strong>{' '}
                      {supplierDetail.bussiness_name == null || supplierDetail.bussiness_name == ''
                        ? 'N/A'
                        : supplierDetail.bussiness_name}
                      <i>
                        <strong>ABN:</strong>{' '}
                        {supplierDetail.abn == null || supplierDetail.abn == ''
                          ? 'N/A'
                          : supplierDetail.abn}
                      </i>
                    </p>
                    <p>
                      <strong>Email Address:</strong> {supplierDetail.email}
                    </p>
                    <p>
                      <strong>TFN:</strong>{' '}
                      {supplierDetail.tfn == null || supplierDetail.tfn == ''
                        ? 'N/A'
                        : supplierDetail.tfn}
                    </p>
                  </div>
                </div>
              </div>
              <div className="modal-content">
                <h3>Bank Details</h3>
                <div className="modal-content-details">
                  <div className="modal-content-left">
                    <p>
                      <strong>Account Name:</strong>{' '}
                      {supplierDetail.account_name == null || supplierDetail.account_name == ''
                        ? 'N/A'
                        : supplierDetail.account_name}
                    </p>
                    <p>
                      <strong>Account Number:</strong>{' '}
                      {supplierDetail.account_number == null || supplierDetail.account_number == ''
                        ? 'N/A'
                        : supplierDetail.account_number?.toString()}
                    </p>
                  </div>
                  <div className="modal-content-right">
                    <p>
                      <strong>Bank Name:</strong>{' '}
                      {supplierDetail.bank_name == null || supplierDetail.bank_name == ''
                        ? 'N/A'
                        : supplierDetail.bank_name}
                    </p>
                    <p>
                      <strong>BSB:</strong>{' '}
                      {supplierDetail.bsb == null || supplierDetail.bsb == ''
                        ? 'N/A'
                        : supplierDetail.bsb}
                    </p>
                  </div>
                </div>
              </div>
              <div className="modal-content">
                <h3>Description</h3>
                <div className="modal-content-details">
                  <div className="modal-content-left">
                    <p>
                      <strong>Description:</strong>{' '}
                      {supplierDetail.description == null || supplierDetail.description == ''
                        ? 'N/A'
                        : supplierDetail.description}
                    </p>
                  </div>
                </div>
              </div>
            </>
          )
        )}
      </Modal>
    </div>
  );
};

export default SupplierDetailPopup;
