import React, { forwardRef } from 'react';
import { getAssignedTo, getFirstChar } from './DashboardHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { formatNumber } from '../../APIConfig/Interceptor';

function ProcessedElement(props, ref) {
  const { item } = props;
  var process_time = item.updated_at.split(' ');
  var process_date = process_time[0];
  return (
    <li className="card-box" ref={ref}>
      <p className="crn">
        <span style={{ marginRight: '5px' }}>
          CRN:{' '}
          {item?.tax_software?.crn == null || item?.tax_software?.crn == ''
            ? 'ID' + item?.customer?.id
            : item?.tax_software?.crn}{' '}
        </span>
        <span style={{ marginLeft: 'auto' }}>Processed on: {process_date}</span>
      </p>
      <p className="username">
        <FontAwesomeIcon icon={faUser} size="1x" /> {item?.tax_software?.first_name}{' '}
        {item?.tax_software?.last_name}
      </p>
      <p>
        {item?.tax_software?.account_number?.toString()}
        {item.year !== undefined && <span style={{ marginLeft: 'auto' }}>ITR {item.year}</span>}
      </p>
      <p className="refund-p">
        Remittance Amount <span>${formatNumber(item?.remittance) || 0}</span>
      </p>
      <div className="card-footer">
        <FontAwesomeIcon icon={faChevronCircleRight} /> Assigned to {getAssignedTo(item) || 'N/A'}{' '}
        {getFirstChar(item) ? <span className="firstchar">{getFirstChar(item)}</span> : null}
      </div>
    </li>
  );
}

export default forwardRef(ProcessedElement);
