/* eslint-disable no-nested-ternary */
import { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { API, defaultPaginationLimit, get } from '../../config';

const useOnlyCustomerList = (currentPage, search, id, recursion = true) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [customersList, setCustomersList] = useState([]);
  const [searchParam, setSearchParam] = useState(search || '');
  const [isCustomerLoading, setIsCustomerLoading] = useState(false);
  const [pagingData, setPagingData] = useState({ current: currentPage || 1 });
  const query = new URLSearchParams(location.search);

  const fetchCustomers = useCallback(
    async (pageNo, newId, limit, sortValue) => {
      try {
        setIsCustomerLoading(true);
        const { data } = await get(
          `${API.GET_CUSTOMERLIST}?search=${newId || id || searchParam}&limit=${
            limit ?? (query.get('limit') ? query.get('limit') : defaultPaginationLimit)
          }&page=${pageNo || pagingData.current}&field=${
            sortValue ? sortValue[0] : query.get('field') ? query.get('field') : ''
          }&type=${sortValue ? sortValue[1] : query.get('type') ? query.get('type') : ''}`
        );
        setIsCustomerLoading(false);
        const { total, current_page, per_page, from, to } = data.data;
        const customersList = data.data.data;
        setCustomersList(customersList);
        setPagingData({ total, current: current_page, pageSize: per_page, from: from, to: to });
        return data.data;
      } catch (e) {
        const { data } = e;
        setIsCustomerLoading(false);
        setPagingData({ current: 0 });
        setCustomersList([]);
        toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      }
    },
    [pagingData.current, searchParam]
  );

  useEffect(() => {
    if (recursion) {
      fetchCustomers();
    }
  }, [fetchCustomers]);

  return {
    fetchCustomers,
    customersList,
    searchParam,
    isCustomerLoading,
    pagingData,
    setIsCustomerLoading,
    setPagingData,
    onSearchChange: (e) => {
      const value = e.target.value;
      setSearchParam(value);
      setPagingData({ ...pagingData, current: 1 });
      navigate(`${API.GET_CUSTOMERLIST}?search=${value}&page=1`, {
        replace: true
      });
    },
    onPageChange: (page, limit, sortValue) => {
      setPagingData({ ...pagingData, current: page });
      let sortData = sortValue.split(',');
      navigate(
        `${API.GET_CUSTOMERLIST}?search=${searchParam}&page=${page}&limit=${limit}&field=${sortData[0]}&type=${sortData[1]}`,
        {
          replace: true
        }
      );
    },
    setSearchParam
  };
};

export default useOnlyCustomerList;
