import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { API, fileUpload } from '../../../../config';

export const YodleeConnectModal = ({
  fastLinkData,
  handleYodleeModal,
  handleProviderBank,
  setOpenYodleeLoading,
  fetchSalesForceConnectionData
}) => {
  const handleSaveAccounts = async (providersData) => {
    try {
      setOpenYodleeLoading(true);
      const { data } = await fileUpload(`${API.SAVE_PROVIDERS_ACCOUNTS}`, {
        yodlee_providers: providersData
      });
      setOpenYodleeLoading(false);
      if (data) {
        toast.success(data?.message);
        handleYodleeModal();
        handleProviderBank();
        fetchSalesForceConnectionData();
      }
      return data?.data;
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
      setOpenYodleeLoading(false);
    }
  };

  useEffect(() => {
    window.fastlink.open(
      {
        fastLinkURL: fastLinkData?.fastLinkURL,
        accessToken: fastLinkData?.accessToken,
        params: fastLinkData?.params,
        onSuccess: function () {
          // will be called on success. For list of possible message, refer to onSuccess(data) Method.
        },
        onError: function (data) {
          // will be called on error. For list of possible message, refer to onError(data) Method.
          data?.code && data?.message && toast.error(data?.message);
          data?.code && !data?.message && data?.description && toast.error(data?.description);
        },
        onClose: function (providersData) {
          // will be called called to close FastLink. For list of possible message, refer to onClose(data) Method.

          providersData?.sites &&
            providersData?.sites?.length > 0 &&
            handleSaveAccounts(providersData?.sites);

          handleYodleeModal();
        },
        onEvent: function () {
          // will be called on intermittent status update.
        }
      },
      'container-fastlink'
    );
  });

  return <div className="pt-0 pb-2 px-4" id="container-fastlink"></div>;
};
